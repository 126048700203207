@import "../../../../assets/theme/_var.scss";
@import "../../../../assets/theme/_mixins.scss";

.UserLedger {
    &.cardStyle {
        padding: 0;
    }

    .UserLedger_V2 {
        &.disabled{
            pointer-events: none;
            user-select: none;
        }
        .UserLedger_Tabs_Nav {
            border-bottom: 1px solid $borderColor3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 18px; 

            @media (max-width:767px) {
                display: block;
                border-bottom: 0;
                padding: 0 10px; 
            }

            .Bottom_Border_Tabs {
                border-bottom: none;
                margin-bottom: 0;

                @media (max-width:767px) {
                    display: flex;
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    white-space: nowrap;
                    border-bottom: 1px solid #1F2333;
                    padding-bottom: 0;
                    margin-bottom: 20px;
                }

                .nav-item {
                    margin-right: 26px;

                    @media (max-width:1365px) {
                        margin-right: 18px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    .nav-link {
                        padding: 18px 0px;
                        min-height: 58px;
                    }
                }

                .List_No {
                    background: rgba(9, 53, 220, 0.12);
                    border: 1px solid $blueclr;
                    border-radius: 2px;
                    min-width: 24px;
                    height: 22px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    color: $white;
                    margin-left: 8px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 22px;
                }
            }

            .grey-border-btn {
                border-radius: 8px;
                padding-left: 20px;
                padding-right: 20px;
                font-weight: 500;
                font-size: 12px;
                line-height: 24px;
                height: 38px;

                @media (max-width:1365px) {
                    padding-left: 10px;
                    padding-right: 10px;
                }

                @media (max-width:1199px) {
                    min-width: 130px;
                }

            }
        }

        .UserLedger_Tabs_Content {
            padding: 28px 0;

            // @media (max-width:1599px) {
            //     padding: 28px 0px;
            // }

            .form-check.Radio_btn {
                .form-check-input {
                    border: 1px solid $radioborderclr;
                    border-radius: 100px;
                    width: 16px;
                    height: 16px;
                    position: relative;

                    &:checked {
                        &::after {
                            width: 8px;
                            height: 8px;
                            background-color: $greenclr;
                        }
                    }
                }
            }
        }
    }
}