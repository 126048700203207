@import "../../../../../../assets/theme/_var.scss";

.add_profit_Modal.modal {
  user-select: none;
  .modal-dialog {
    .modal-content {
      // .modal-header {}

      .modal-body {
        .Input_Group {
          .input-type2 {
            background-color: $bgclr4;
            border-color: $bgclr4;
          }
        }

        .quantity_box {
          background-color: $bgclr4;
          border-radius: 4px;
        }

        .Range_Slider {
          margin-top: 0px;

          .rangeslider {
            margin-top: 20px;
            margin-bottom: 25px;

            .rangeslider__labels {
              display: none;
            }
          }
        }

        button.btn-primary {
          height: 60px;
          border-radius: 8px;

          @media (max-width: 767px) {
            height: 45px;
          }
        }

        h6 {
          color: $greybg3;
          margin-bottom: 0;
        }

        .profit_Price {
          h5 {
            font-size: 18px;
            font-weight: 500;
            line-height: 23px;
            margin-bottom: 0;

            @media (max-width: 767px) {
              font-size: 14px;
              line-height: 19px;
            }

            strong {
              display: block;
              font-weight: 700;
              margin-top: 10px;
            }
          }
        }

        .agree_checkBox {
          p {
            margin-left: 8px;
          }
          a {
            color: $white;
          }
        }
      }
    }
  }
}

.profit_dropdown {
  &.dropdown {
    .dropdown-toggle {
      display: flex;
      align-items: center;
      background-color: $bgclr4;
      width: 100%;
      padding: 10px 22px;
      border-radius: 4px;
      height: 60px;
      position: relative;

      &::after {
        width: 10px;
        height: 6px;
        top: 50%;
        transform: translateY(-50%);
        left: auto;
        right: 22px;
        position: absolute;
      }

      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 100px;
      }

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        color: $white;
        margin-bottom: 0;
        padding-left: 12px;
      }
    }

    .dropdown-menu {
      width: 100%;

      a {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        padding: 10px 22px;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          background-color: $borderColor;
        }

        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          border-radius: 100px;
        }

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 19px;
          color: $white;
          margin-bottom: 0;
          padding-left: 12px;
        }
      }
    }
  }
}

.common_select.Profit_select {
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: flex-start;

    & > div {
      width: 100%;
    }
  }

  .react-select__control {
    height: 24px;
    line-height: normal;
    min-height: auto;
    // width: 170px;

    @media (min-width: 767px) {
      width: 170px;
    }

    .react-select__value-container {
      height: auto;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
    }

    .react-select__input-container {
      height: 25px;

      @media (min-width: 767px) {
        height: 30px;
      }
    }
  }
}

.error_msg {
  background-color: $redbgclr;
  padding: 24px 32px;
}

.turnOff_switch {
  padding-right: 10px;
  margin-bottom: 0px;
}
