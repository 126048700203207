@import "../../../../../assets/theme/_var.scss";

.Dashboard {
    &_Overview {
        position: relative;
        padding: 30px 40px 0;
        z-index: 1;
        overflow: hidden;

        @media (max-width:1199px) {
            padding: 30px 10px 0;
        }

        &_Heading {
            max-width: 800px;

            h2 {
                color: $white;
                font-size: 48px;
                font-weight: 700;
                line-height: 120%;

                @media (max-width:1199px) {
                    font-size: 35px;
                }

                @media (max-width:767px) {
                    font-size: 25px;
                }

                span {
                    display: block;
                    background: #BEE0FF;
                    background: linear-gradient(to right, #ffffff 0%, #BEE0FF 30%, #0D2893 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                }
            }

            @media (max-width:767px) {
                .font24 {
                    font-size: 18px !important;
                }
            }
        }

        &_counter {
            text-align: center;
            max-width: 220px;
            margin: 0 auto 20px;

            h3 {
                font-size: 32px;
                font-weight: 700;
                line-height: 120%;
                background: #BEE0FF;
                background: linear-gradient(to right, #ffffff 0%, #BEE0FF 0%, #0D2893 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media (max-width:767px) {
                    font-size: 25px;
                }
            }

            p {
                font-size: 20px;
                line-height: 120%;
                color: $white;

                @media (max-width:767px) {
                    font-size: 16px;
                }
            }
        }

        .coming_Exh {
            padding-bottom: 40px;

            h2 {
                color: $white;
                font-size: 42px;
                font-weight: 700;
                line-height: 120%;

                @media (max-width:1199px) {
                    font-size: 35px;
                }

                @media (max-width:767px) {
                    font-size: 25px;
                }
            }

            h3 {
                font-size: 38px;
                font-weight: 500;
                line-height: 120%;
                background: linear-gradient(to right, #ffffff 0%, #BEE0FF 0%, #0D2893 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media (max-width:767px) {
                    font-size: 25px;
                }
            }

            p {
                color: $white;
                font-size: 18px;
                line-height: 148%;

                @media (max-width:767px) {
                    font-size: 16px;
                }
            }

            .subscribe_input {
                padding-bottom: 40px;

                h5 {
                    color: $white;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 120%;
                    text-transform: uppercase;
                    margin-bottom: 20px;
                }

                .emial_Input {
                    position: relative;
                    background-color: $darkblackBg;
                    padding: 15px 28px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    input {
                        border: 0;
                        padding: 0;
                        border-radius: 0;
                        height: auto;
                        font-size: 18px;
                        font-weight: 500;

                        &::placeholder {
                            color: #63677A;
                        }
                    }

                    svg {
                        height: 18px;
                        width: 12px;

                        path {
                            stroke: $blueclr1;
                        }
                    }
                }
            }

            .apple_android {
                text-align: center;
                max-width: 150px;
                width: 100%;
            }

            .btn-primary {
                border-radius: 100px;
            }

        }

        &::after {
            content: "";
            background-image: url(../../../../../assets/images/admin/pattnr_bg.svg);
            background-size: contain;
            background-position: bottom left;
            position: absolute;
            bottom: 0;
            height: 70%;
            width: 100%;
            left: 0;
            background-repeat: no-repeat;
            z-index: -1;
            opacity: 1;
        }

        figure {
            position: relative;
            top: 80px;

            @media (max-width:767px) {
                top: 30px;
                text-align: center;
            }
        }
    }
}

.Refer_Code {
    @media (max-width:991px) {
        padding-top: 40px;
    }

    &_Box {
        background: $gradientblueclr;
        border-radius: 4px 4px 0px 0px;
        padding: 25px 25px 32px;
        text-align: center;

        h3 {
            font-weight: 500;
            font-size: 28px;
            line-height: 120%;
            color: $white;
            @media (max-width:1365px) {
                font-size: 22px;
            }

            em {
                font-family: $baseFont1;
            }
        }
    }

    &_Join {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $bgclr;
        padding: 28px;
        border-bottom: 2px solid $blueclr1;

        @media (max-width:1599px) {
            padding: 25px 15px;
        }

        img {
            width: 34px;
        }

        h4 {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            margin: 0 20px;
            text-transform: uppercase;

            @media (max-width:1599px) {
                font-size: 20px;
                line-height: 25px;
                margin: 0 15px;
            }

            @media (max-width:1365px) {
                font-size: 18px;
                line-height: 23px;
            }
        }

        svg {
            width: 9px;
            height: 16px;
        }
    }

    &_Newscontent {
        padding-top: 52px;

        .Refer_News {
            display: flex;
            align-items: center;
            padding: 0 30px;

            img {
                width: 28px;
            }

            h4 {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                margin: 0 14px;
                text-transform: uppercase;

                @media (max-width:1365px) {
                    font-size: 16px;
                    line-height: 20px;
                }
            }

            svg {
                width: 7px;
                height: 12px;
            }
        }

        .News_List {
            li {
                border-bottom: 1px solid $borderColor;
                padding: 24px 30px;

                // &:last-child {
                //     padding-bottom: 0;
                //     border-bottom: 0;
                // }

                p {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 26px;
                    color: $white;
                }
            }
        }
    }
    .text_link {
        color: $white;
        display: inline-block;
    
        svg {
            margin-left: 15px;
        }
    }
}
