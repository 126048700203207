@import "../../assets/theme/_var.scss";

.select_lang_box.depsite_wdr {
  .modal-dialog {
    max-width: 341px;

    @media (max-width:767px) {
      max-width: 100%;
    }

    .modal-content {
      .modal-header {
        @media (max-width:767px) {
          padding: 12px 15px;

          .btn-close {
            top: 20px;
          }
        }
      }

      .modal-body {
        .deposite_withdrw {
          h4 {
            font-weight: 400;
            font-size: 20px;

            @media (max-width:767px) {
              font-size: 17px;
            }
          }

          .wrap_input {
            margin-bottom: 15px;
            margin-top: 0;

            .label {
              color: $lightwhitetext;
              margin-bottom: 5px;
              display: block;
              font-weight: 700;
              font-size: 11px;
              line-height: 21px;
            }

            .deposit_select.common_select {
              .react-select__control {
                background-color: $darkblackBg5 !important;
                padding: 0 10px;
                height: 40px;
                border: 0.5px solid $borderColor2;
                border-radius: 3px;

                .react-select__indicator {
                  svg {
                    opacity: 0.3;
                  }
                }
              }

              .seleted-label span.small-label {
                margin-left: 10px;
              }

              .seleted-label .thumb img {
                height: 20px;
              }

              .react-select__menu {
                width: 100%;
                background-color: $dark_blue;
                border: 1px solid $dark_blue;
                top: 30px;
                color: $white;

                .react-select__option {
                  color: $white;
                  font-weight: 700;
                }

                .react-select__option {
                  &.react-select__option--is-focused {
                    background-color: transparent;
                    color: $white;
                  }

                  &.react-select__option--is-selected {
                    background-color: transparent;
                    color: $blueclr;
                  }
                }
              }
            }
          }

          .input_inner {
            position: relative;
            margin-top: 0px;
            margin-bottom: 15px;
          }

          .wrap_input {
            position: relative;
            // margin-top: 0px;

            .input_label {
              margin-bottom: 6px;
              color: $lightwhitetext;
              font-weight: 700;
              font-size: 11px;
              line-height: 21px;
            }

            .Max_Value_Input {
              position: relative;

              .input-group {
                .form-control {
                  padding-right: 50px;
                }
              }
            }

            .maximum_btn {
              display: flex;
              align-items: center;
              font-weight: 700;
              font-size: 12px;
              line-height: 21px;
              cursor: pointer;
              position: absolute;
              z-index: 99;
              right: 15px;
              top: 13px;
              background-color: transparent;
              padding: 0;
              border: 0;
              color: $textBlue;
            }

          }

          .input-group {
            align-items: center;

            .input-group-text,
            .form-control {
              background: $lineBorder;
              border: 0;
              border-radius: 4px;
              font-size: 14px;
              font-weight: 500;

              &:focus {
                box-shadow: unset;
              }
            }

            .input-group-text {
              color: $white;
              font-weight: 400;
              padding: 11px 16px;

              @media (max-width: 1600px) {
                padding: 11px 8px;
              }
            }

            .form-control {
              padding: 11px 13px;
              color: $white;
              font-weight: 700;
              background-color: $darkblackBg5;
              height: 40px;
              border: 0.5px solid $borderColor2;
              border-radius: 3px;
              font-size: 12px;

              &::placeholder {
                color: $inputlabelclr;
                font-weight: 300;
                font-size: 12px;
                line-height: 21px;
              }
            }
          }

          .form-text {
            color: $textGreynew;
            font-weight: 300;
            font-size: 11px;
            line-height: 14px;
            margin-top: 20px;
            margin-bottom: 15px;
            display: block;
          }

          .Before_deposit {
            border-top: 0.5px solid $borderColor6;
            padding-top: 10px;

            h6 {
              margin-bottom: 10px;
            }

            h6, label {
              font-weight: 400;
              font-size: 11px;
              line-height: 14px;
              color: $lightwhitetext;

              a {
                text-decoration: underline;
                font-weight: 700;
                color: $lightwhitetext;
              }
            }
          }
        }

        .Deposit_Move_funds {
          .loader {
            margin-top: 0;
          }

          h5 {
            font-weight: 700;
            font-size: 13px;
            line-height: 14px;
            color: $white;
            margin-bottom: 0;
          }

          .Funds_Status {
            font-weight: 700;
            font-size: 11px;
            line-height: 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $white;
            border-top: 1px solid $borderColor1;
            padding-top: 23px;
            padding-bottom: 15px;

            span {
              font-weight: 400;
              font-size: 11px;
              line-height: 14px;
              color: $textGreynew;
            }
          }
        }

      }
    }
  }

  .form-control:disabled {
    padding: 11px 13px;
    color: $white;
    font-weight: 700;
    background-color: $darkblackBg5;
    height: 40px;
    border: 0.5px solid $borderColor2;
    border-radius: 3px;
    font-weight: 300;
    font-size: 12px;
    line-height: 21px;

    &::placeholder {
      color: $inputlabelclr;
    }
  }
}


.alertText {
  display: flex;
  align-items: flex-start;
  padding: 14px;
  border: 0.5px solid #D2394E;
  border-radius: 3px;
}

.altImg{
  margin-right: 9px;
    margin-top: 3px;
    width: 12px;
    height: 12px;
    margin-left: 0;
}

.altP{
  font-size: 11px;
    line-height: 16px;
    color: #D2394E;
}