@import "../../../../../assets/theme/_var.scss";

.Trade_Chart {
  height: 670px;

  @media (max-width: 767px) {
    height: 350px;
  }
}

.cardStyle.TradeChart.card {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    height: 650px;
  }
  @media (max-width: 767.98px) {
    height: 600px;
  }
}

.tv_chart_container_spot {
  min-height: 100%;
}
