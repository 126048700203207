@import "../../../../assets/theme/_var.scss";

.BuySell {

    &.cardStyle {
        padding: 0;
        overflow: hidden;
        overflow-y: auto;
        background-color: transparent;

        .BuySell_V2 {
            .accordion {
                background-color: transparent;

                .accordion-item {
                    background-color: transparent;
                    border: 0;
                    border-radius: 0;

                    .accordion-header {
                        .accordion-button {
                            background-color: transparent;
                            border: 0;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 19px;
                            color: $white;
                            text-transform: uppercase;
                            border-bottom: 1px solid $borderColor;
                            box-shadow: none;
                            padding: 18px 24px;

                            @media (max-width:1199px) {
                                padding: 18px 10px;
                                font-size: 14px;
                            }

                            &::after {
                                background-image: url("../../../../assets/images/down_arr_icon.svg");
                                background-position: center;
                                width: 12px;
                                height: 7px;
                                background-size: 12px;
                            }

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }

                    .accordion-body {
                        border-bottom: 1px solid $borderColor;
                        padding: 14px 25px;

                        @media (max-width:1023px) {
                            padding: 14px 10px;
                        }

                        &.Order_Tabs {
                            padding: 0;

                            .nav-pills {
                                border-bottom: 1px solid $borderColor3;

                                .nav-item {
                                    width: 33.33%;
                                    border-right: 1px solid $borderColor3;

                                    &:last-child {
                                        border-right: 0;
                                    }

                                    a {
                                        font-weight: 400;
                                        font-size: 12px;
                                        line-height: 22px;
                                        color: $white;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        background-color: transparent;
                                        border: 0;
                                        border-radius: 0;
                                        height: 42px;

                                        &.active {
                                            color: $white;
                                            background-color: $blueclr;
                                        }
                                    }
                                }
                            }

                            .tab-content {
                                padding: 26px 18px;
                                color: $greyclr;

                                @media (max-width:1199px) {
                                    padding: 20px 10px;
                                }
                            }
                        }
                    }
                }
            }

            .BuySell_Assets {
                justify-content: space-between;
                display: flex;
                align-items: center;

                h5 {
                    margin-bottom: 0;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 28px;
                    color: $white;

                    svg {
                        margin-left: 7px;
                        position: relative;
                        top: -1px;
                    }
                }

                .grey-border-btn {
                    border-radius: 6px;
                    height: 28px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 12px;
                    padding: 5px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .TechnicalAnalysis {
            padding-top: 15px;
        }
    }
}