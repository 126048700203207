@import "../../../../../assets/theme/_var.scss";

.Trade_Detail {
    h2 {
        font-weight: 500;
        font-size: 23px;
        line-height: 28px;
        color: $white;
        margin-bottom: 32px;
    }

    &_bitCoin {
        &_Rating {
            img {
                width: 50px;
            }

            h5 {
                font-weight: 500;
                font-size: 23px;
                line-height: 28px;
                color: $white;
                margin-bottom: 0;
                padding: 0 16px;
            }

            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: $blueclr2;
                padding-right: 29px;
            }
        }

        &_Rank {
            padding: 25px 0;

            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: $textGrey6;
                padding-right: 20px;

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        &_Links {
            a {
                color: $blueclr1;
                padding-right: 18px;
                display: flex;
                align-items: center;

                &:last-child {
                    padding-right: 0;
                }

                img {
                    margin-right: 10px;
                }
            }
        }

        &_Tags {
            margin-top: 20px;

            h5 {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 12px;
            }

            .Tags_List {
                span {
                    background-color: $darkblackBg;
                    padding: 8px;
                    border-radius: 8px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: $textGrey6;
                    margin-right: 20px;
                    cursor: pointer;
                    transition: all ease-in-out 0.3s;

                    @media (max-width:1499px) {
                        display: inline-block;
                        margin-bottom: 5px;
                    }

                    &:hover {
                        background-color: $lightblueclr;
                        color: $blueclr1;
                    }
                }
            }
        }
    }
}

.Trade_bitCoin {
    &_Price {
        &_Linevalue {
            margin-bottom: 35px;

            @media (max-width:1023px) {
                margin-bottom: 15px;
            }

            h5 {
                font-weight: 500;
                font-size: 33px;
                line-height: 40px;
                letter-spacing: 0.02em;
                margin-bottom: 0;
                display: flex;
                align-items: center;

                @media (max-width:1499px) {
                    font-size: 23px;
                    line-height: 30px;
                }

                span {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    margin-left: 5px;

                    img {
                        width: 15px;
                    }
                }
            }

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: $greyclr;
            }
        }

        &_highLow {
            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: $greyclr;

                img {
                    margin-right: 5px;
                }
            }

            .progress {
                height: 5px;
                background: $greybg2;

                .progress-bar {
                    background: $blueclr1;
                }
            }

            .common_select {
                .react-select__control {

                    .react-select__placeholder,
                    .react-select__single-value {
                        font-weight: 400;
                        font-size: 14px;
                        color: $greyclr;
                    }
                }
            }
        }
    }
}

.DetailPrice_Card {
    position: relative;

    .Price_Card_Col {
        // width: 25%;
        margin: 15px 0;

        .Price_CardBox {
            background-color: $darkblackBg;
            border-radius: 5px;
            padding: 12px;

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: $greyclr;

                img {
                    margin-right: 10px;
                }
            }

            h5 {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 0px;
                margin-top: 25px;

                @media (max-width:1499px) {
                    font-size: 14px;
                    line-height: 22px;
                }

                span {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    margin-top: 4px;
                    display: block;

                    img {
                        width: 15px;
                    }
                }
            }
        }
    }
}