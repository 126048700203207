@import "../../assets/theme/_var.scss";

.select_lang_box.depsite_wdr {
  .modal-dialog {
    max-width: 450px;

    .modal-content {

      .deposite_withdrw {
        h4 {
          font-weight: 400;
          font-size: 20px;

          @media (max-width:767px) {
            font-size: 17px;
          }
        }

        .wrap_input {
          margin-top: 15px;

          .label {
            color: $textGrey;
            margin-bottom: 5px;
            display: block;
          }

          .deposit_select.common_select {
            .react-select__control {
              background-color: $dark_blue;
              padding: 0 10px;
              height: 45px;
            }

            .react-select__menu {
              width: 100%;
              background-color: $dark_blue;
              border: 1px solid $dark_blue;
              top: 36px;
              border-radius: 4px;
              color: $white;

              .react-select__option {
                color: $white;
                font-weight: 700;
              }

              .react-select__option {
                &.react-select__option--is-focused {
                  background-color: transparent;
                  color: $blueclr;
                }

                &.react-select__option--is-selected {
                  background-color: transparent;
                  color: $blueclr;
                }
              }
            }
          }
        }

        .input_inner {
          position: relative;
          margin-top: 15px;

          .input_label {
            margin-bottom: 8px;
          }

          .input-group {
            align-items: center;

            .input-group-text,
            .form-control {
              background: $inputBg;
              border: 0;
              border-radius: 4px;
              font-size: 12px;
              font-weight: 700;

              &:focus {
                box-shadow: unset;
              }
            }

            .input-group-text {
              color: $white;
              font-weight: 400;
              padding: 11px 16px;

              @media (max-width: 1600px) {
                padding: 11px 8px;
              }
            }

            .form-control {
              padding: 11px 6px;
              color: $white;
              font-weight: 700;
              font-size: 12px;

              &::placeholder {
                color: $inputlabelclr;
              }
            }
          }

          .maximum_btn {
            display: flex;
            align-items: center;
            font-size: 14px;
            cursor: pointer;
            position: absolute;
            z-index: 99;
            right: 15px;
            bottom: 11px;
            background-color: transparent;
            padding: 0;
            border: 0;
            color: $blueclr;
          }
        }
      }
    }
  }
}