@import "../../../../../assets/theme/_var.scss";

.Trade_Chart {
  height: 670px;

  @media (max-width: 767px) {
    height: 350px;
  }
}
.saveString-XVd1Kfjg {
  display: none !important;
}
.cardStyle.TradeChart.card {
  @media (max-width: 767px) {
    height: 600px;
  }
}

.tv_chart_container2 {
  min-height: 100%;
}
