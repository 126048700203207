@import "../../assets/theme/_var.scss";

.site-footer {
  background-color: $darkblackBg3;
}

.Inner-footer {
  padding-top: 52px;
  padding-bottom: 46px;

  @media (max-width: 767px) {
    padding-top: 45px;
    padding-bottom: 30px;
  }

  .Footer_logo {
    margin-bottom: 50px;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  &__list {
    color: $inputlabelclr;
    padding-right: 50px;

    h6 {
      font-weight: 700;
      font-size: 16px;
      line-height: 160%;
      color: $white;
      padding-bottom: 20px;
      margin-bottom: 0;
    }

    @media (max-width: 575px) {
      padding-right: 0;
      flex: 50% 0;
      text-align: left;
    }

    a {
      line-height: 20px;
      transition: all linear 0.25s;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $greybg3;
      display: block;

      &:hover {
        color: $blueclr;
      }
    }

    .Get_started_btn {
      height: 28px;
      font-size: 12px;
      padding: 5px 17px;

      span {
        font-weight: 300;
      }
    }

    .Footer_Lang_btn {
      height: 30px;
      font-size: 12px;
      padding: 5px 17px;
      font-size: 12px;
      font-weight: 700;
      color: $white;
      background: $dropdwonBg;
      margin-top: 10px;
      border: 0;

      &:hover {
        background: $blueclr;

        span {
          &+svg {
            path {
              stroke: $white;
            }
          }
        }
      }

      span {
        margin: 0 13px;

        &+svg {
          path {
            stroke: #5D5F63;
          }
        }
      }
    }

    &.Inner-footer__list--social {
      margin-top: 20px;

      li {
        margin-bottom: 0 !important;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 575px) {
          justify-content: center;
          margin-top: 20px;
        }

        .Social_Icons {
          transition: all ease-in-out 0.3s;
          text-align: center;
          margin-right: 20px;
          position: relative;

          &:last-child {
            margin-right: 0;
          }

          @media (max-width: 1199px) {
            margin: 3px 10px 3px 0;
          }

          a {
            background: $dropdwonBg;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            margin: 0;
            position: relative;
            transition: all ease-in-out 0.3s;

            &:last-child {
              margin-right: 0;
            }

            svg {
              width: 15px;

              path {
                fill: $white;
              }
            }
          }

          span {
            font-weight: 700;
            font-size: 10px;
            line-height: 14px;
            color: $inputlabelclr;
            display: block;
            opacity: 0;
            margin-top: 5px;
            position: absolute;
            transition: all ease-in-out 0.3s;

            @media (max-width: 1199px) {
              display: none;
            }
          }

          &:hover {
            a {
              transform: translateY(-6px);
              transition: all ease-in-out 0.3s;
            }

            span {
              display: block;
              opacity: 1;
            }
          }
        }
      }
    }

    // &.footer_list_logo {
    //   p {
    //     font-weight: 400;
    //     font-size: 11px;
    //     color: $inputlabelclr;
    //     margin: 29px 0;
    //     line-height: 19px;
    //   }

    //   @media (max-width: 575px) {
    //     text-align: left;
    //     margin-bottom: 30px;

    //     p {
    //       max-width: 300px;
    //       margin: 21px 0;
    //     }
    //   }
    // }

    li {
      font-weight: 400;
      font-size: 14px;
      color: $inputlabelclr;

      @media (max-width: 575px) {
        font-weight: 500;
      }
    }

    &--logo {
      color: $white;
      font-size: 12px;
      line-height: 18px;
      margin-top: -15px;

      @media (max-width: 991px) {
        padding-right: 0;
        margin-top: 0;
      }

      li {
        line-height: 18px;

        @media (max-width: 575px) {
          font-weight: 400;
          color: $lightwhitetext;
        }

        strong {
          padding-top: 35px;

          @media (max-width: 575px) {
            padding-top: 25px;
            font-weight: 400;
          }
        }
      }
    }

    &--location {
      li {
        &:nth-child(2) {
          padding-left: 25px;
          position: relative;
          line-height: 22px;

          @media (max-width: 575px) {
            padding-left: 0;
          }
        }
      }

      .location-icon {
        position: absolute;
        left: 0;
        top: -2px;

        @media (max-width: 575px) {
          position: static;
          padding-right: 3px;
        }
      }
    }

    &--social {
      li {
        &:nth-child(2) {
          display: flex;
          flex-wrap: wrap;
          gap: 15px 20px;
        }
      }

      a {
        svg {
          path {
            transition: all linear 0.2s;
          }
        }

        &:hover {
          svg {
            path {
              transition: all linear 0.2s;
              fill: $blueclr;
            }
          }
        }
      }
    }
  }

  .accordion {
    @media (max-width: 991px) {
      margin-top: 5px;
    }

    .accordion-item {
      background-color: transparent;
      border: 0;
      padding: 0;
      border-radius: 0;

      .accordion-header {
        .accordion-button {
          background-color: transparent;
          color: $white;
          font-weight: 500;
          font-size: 16px;
          line-height: 160%;
          border-radius: 0;
          border: 0;
          padding: 0;
          box-shadow: none;
          padding-bottom: 20px;

          @media (max-width: 991px) {
            font-size: 14px;

            &[aria-expanded="true"] {
              &::after {
                filter: brightness(0) invert(0.3);
                opacity: 1;
              }
            }
          }

          &::after {
            filter: brightness(1) invert(0.3);
            opacity: 1;
          }

          @media (min-width:992px) {
            &::after {
              display: none;
            }
          }
        }
      }

      .accordion-collapse {
        @media (min-width:992px) {
          display: block;
        }

        .accordion-body {
          padding: 0;

          @media (max-width:991px) {
            padding-bottom: 20px;
            padding-left: 25px;
          }

          ul {
            li {
              a {
                line-height: 260%;

                @media (max-width:991px) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }

  .newsletter_footer {
    .custom_input {
      @media (min-width: 576px) {
        min-width: 100%;
      }
    }

    .btn-primary {
      height: auto;
    }
  }
}

.footer_Copyright {
  padding: 25px 0;
  border-top: 0.5px solid $borderColor5;

  @media (max-width: 575px) {
    padding: 20px 0;
  }

  p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $footerlinkclr;

    @media (max-width: 767px) {
      text-align: center;
      margin-bottom: 10px;
    }
  }

  &_Links {
    padding-left: 30px;

    @media (max-width: 991px) {
      padding-left: 10px;
    }

    @media (max-width: 767px) {
      justify-content: center;
    }

    a {
      padding: 0 25px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $footerlinkclr;
      border-left: 1px solid $borderColor1;

      @media (max-width: 991px) {
        padding: 0 10px;
      }

      @media (max-width: 767px) {
        &:first-child {
          border-left: 0;
        }
      }
    }
  }
}