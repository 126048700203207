@import "../../../assets/theme/_var.scss";

.custom-input {
  &.input-group {
    background: #1B1B1D;
    border: 0.5px solid #36383F;
    border-radius: 3px;

    .input-group-text,
    .form-control {
      background-color: inherit;
      border: 0;
      color: $white;
      font-size: 12px;
      padding: 5px 0;
    }

    .input-group-text { 
      font-weight: 700;
      padding: 8px 10px;
    }

    .form-control {
      &:focus {
        box-shadow: unset;
      }
    }
  }

  &__icon {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  &__text {
    span {
      margin-left: 10px;
      font-weight: 300;
      color: #e8e8e9;
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
}
