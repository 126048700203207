@import "../../../../../assets/theme/var";
.connectWalletOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: $overlayBg;
    // background: transparent;
    padding-top: 350px;
    @media screen and (max-width: 999px) {
        padding: 0;
        align-items: center;
    }
    .connect_wlt {
        padding: 24px;
        font-size: 16px;
        @media screen and (min-width: 1200px) {
            font-size: 18px;
        }
        img {
            @media screen and (min-width: 1200px) {
                width: 18px;
            }
        }
    }
}
