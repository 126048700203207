@import "../../assets/theme/_var.scss";

.Calculator_modal.modal {
    max-width: 280px;
    max-width: 278px;
    left: 50%;
    transform: translateX(-50%);
    .modal-dialog {
        width: 100%;
        .modal-content {
            height: auto;
            .modal-header {
                padding: 15px 15px;

                .btn-close {
                    position: relative;
                    top: 8px;
                    right: 8px;
                    background-size: 15px;
                }
            }

            .modal-body {
                padding: 15px 10px;
                height: 100%;

                .Input_Group {
                    .input-type2 {
                        height: 60px;
                        padding: 8px 25px;
                    }

                    label {
                        span {
                            font-weight: 700;
                            color: #777ea1;
                        }
                    }
                }

                button.btn-primary {
                    height: 60px;
                    border-radius: 8px;

                    @media (max-width: 767px) {
                        height: 45px;
                    }
                }

                h6 {
                    color: $greybg3;
                    margin-bottom: 0;
                    line-height: 24px;
                }

                .error_msg {
                    background-color: $redbgclr;
                    padding: 12px 32px;
                }
            }
        }
    }
    .css-h2okw {
        height: auto;
        .css-hayew9,
        .css-1iypx3a,
        .css-166hzw5,
        .css-1txu9g8 {
            background-color: #131623;
            border-radius: 10px;
        }
        .css-hayew9 {
            min-height: 90px;
            .css-1lqzgzw {
                font-size: 40px;
            }
        }
        .css-1txu9g8,
        .css-9iwzto,
        .css-9iwzto,
        .css-9iwzto,
        .css-9iwzto,
        .css-9iwzto,
        .css-1iypx3a,
        .css-166hzw5,
        .css-1iypx3a,
        .css-9iwzto,
        .css-9iwzto,
        .css-9iwzto,
        .css-9iwzto,
        .css-9iwzto,
        .css-1uzr0i{
            margin: 5px;
            width: 52px;
            height: 52px;
            padding: 0;
            text-align: center;
            border-radius: 10px;
            font-size: 20px;
        }
        .css-1txu9g8,
        .css-9iwzto,
        .css-9iwzto,
        .css-9iwzto,
        .css-9iwzto,
        .css-9iwzto {
            background-color: #2b3144 !important;
            border: 1px solid #131623;
        }
        .css-1iypx3a,
        .css-166hzw5,
        .css-1iypx3a {
            border: 1px solid #3f4250;
            background: #07080f;
        }
        .css-1uzr0i{
            background: #141e49;
            border: 1px solid #1639c1;
        }
        .css-166hzw5{
            width: 114px;
        }
        .css-h2okw{
            height: auto;
        }
        button[value='=']{
            &:focus,&:active{
                background-color: #1a4440!important;
                border: 1px solid #34d399!important;
                opacity: 1;
            }
        }
        button[value='AC'], button[value= '+/-'], button[value='%'], button[value= '÷']{
            &:focus,&:active{
                background-color: #141e49!important;
                border: 1px solid #1639c1!important;
                opacity: 1;
            }
        }
    }
}

.btn-primary,
button.btn-primary {
    font-family: "Inter", sans-serif;
    padding-left: 14px;
    padding-right: 14px;
    height: 34px;
    border: 0;
    border-radius: 3px;
    background: #0935dc;
    font-size: 14px;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    text-align: center;
    transition: all 0.25s;
    color: #ffffff;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
}
