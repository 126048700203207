@import '../../assets/theme/_var.scss';

.btn-primary,
button.btn-primary {
    font-family: $baseFont;
    padding-left: 14px;
    padding-right: 14px;
    height: 45px;
    border: 0;
    border-radius: 3px;
    background: $blueclr;
    font-size: 14px;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    text-align: center;
    transition: all .25s;
    color: $white;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    &.hideIcon{
        img{
            display: none;
        }
    }
    &:focus,
    &:hover {
        box-shadow: none;
        background: $btnbghover;
    }

    &.grey-btn {
        background: $darkblackBg;

        &:focus,
        &:hover {
            background: $blueclr;
            color: $white;
        }
    }

    &.grey-btn1 {
        background: $greybtnbg1;

        &:focus,
        &:hover {
            background: $blueclr;
            color: $white;
        }
    }

    &.grey-btn2, &.disabled , &:disabled{
        background: $borderColor3;
        opacity: 1;

        &:focus, 
        &:hover {
            background: $blueclr;
            color: $white;
        }
    }

    &.grey-border-btn {
        background: $darkblackBg;
        border: 1px solid $borderColor1;

        &:focus,
        &:hover {
            background: $darkblackBg;
            color: $blueclr;
            border-color: $blueclr;
        }
    }

    &.black-btn {
        background: $blackclr;

        &:focus,
        &:hover {
            background: $btnbghover;
        }
    }

    &.buy-btn {
        background: $greenclr;

        &:focus,
        &:hover {
            opacity: 0.9;
        }
    }

    &.sell-btn {
        background: $redclr;

        &:focus,
        &:hover {
            opacity: 0.9;
        }
    }

    &.red-btn {
        background: $reddarkclr;

        &:focus,
        &:hover {
            box-shadow: unset;
            opacity: 0.9;
        }
    }

    &.blue-light-btn {
        background: $bluelightBg;

        &:focus,
        &:hover {
            box-shadow: unset;
            background: $btnbghover;
        }
    }

    &.border-btn {
        background: transparent;
        border: 1px solid $blueclr;

        &:focus,
        &:hover {
            background: $blueclr;
        }
    }

    .btn_icon {
        display: none;
        margin-right: 10px;

        &.icon_block {
            display: inline-block;
            vertical-align: unset;
        }
    }

    .light_text {
        display: inline-block;
        font-weight: 300;
    }

    &.logn-btn {
        border-radius: 8px;
        flex-direction: row-reverse;
        font-weight: 600;
        border: 1px solid $borderColor1;
        background-color: $darkblackBg;
        color: $white;
        &.active{
            background: linear-gradient(180deg, rgba(51, 169, 150, 0.24) 0%, rgba(14, 16, 26, 0) 100%);
            border: 1px solid $greenclr1;
            color: $greenclr1;
        }
        .btn_icon {
            transition: all ease-in-out 0.3s;
        }

        &:focus,
        &:hover {
            background: linear-gradient(180deg, rgba(8, 182, 153, 0.4) 0%, rgba(14, 16, 26, 0) 100%);
            border: 1px solid $greenclr1;
            color: $greenclr1;

            .btn_icon {
                transform: translateY(-2px);
            }
        }
    }

    &.short-btn {
        border-radius: 8px;
        flex-direction: row-reverse;
        font-weight: 600;
        border: 1px solid $borderColor1;
        background-color: $darkblackBg;
        color: $white;        
        &.active{
            background: linear-gradient(180deg, rgba(210, 57, 78, 0.24) 0%, rgba(14, 16, 26, 0) 100%);
            border: 1px solid $redclr;
            color: $redclr;
        }
        .btn_icon {
            transition: all ease-in-out 0.3s;
        }

        &:focus,
        &:hover {
            background: linear-gradient(180deg, rgba(226, 20, 48, 0.4) 0%, rgba(14, 16, 26, 0) 100%);
            border: 1px solid $redclr;

            .btn_icon {
                transform: translateY(2px);
            }
        }
    }

    &.blue-gradient-btn {
        background: linear-gradient(180deg, #141A30 0%, #0F1220 49.48%, #080A12 100%);
        border: 1px solid #1F2333;
        border-radius: 6px;

        &:focus,
        &:hover {
            background: linear-gradient(180deg, #080A12 0%, #0F1220 49.48%, #141A30 100%);
            border: 1px solid #1F2333;
        }
    }

    // &:disabled {
    //     opacity: 0.48;
    // }

    &.btn-radius {
        border-radius: 100px;
    }
}