@import "../../../../assets/theme/_var.scss";

.Common_Breadcrumbs {
    background-color: $greybg;
    padding: 24px 28px; 

    @media (max-width:767px) {
        padding: 18px 15px;
    }

    .breadcrumb {
        margin-bottom: 0;

        &-item {
            font-size: 18px;
            font-weight: 500;
            color: $white;
            padding-left: 24px !important;

            @media (max-width:767px) {
                padding-left: 15px !important;
                font-size: 15px;
            }

            &:first-child {
                padding-left: 0px !important;
            }

            &.displaynone {
                display: none;
            }

            a {
                display: inline-flex;
                align-items: center;
                color: $greybg3;
                font-weight: 700;

                img {
                    margin-right: 18px;
                }
            }

            &.active {
                color: $white;
            }

            &:first-child::before {
                display: none;
            }

            &::before {
                content: "" !important;
                background-image: url("../../../../assets/images/admin/angles-right-small.svg");
                background-repeat: no-repeat;
                width: 12px;
                height: 10px;
                background-size: 12px;
                background-position: center;
                float: none !important;
                margin-right: 24px;
                padding: 0 !important;
                display: inline-block;
            }
        }
    }
}