@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
$baseFont: "Inter", sans-serif;
$baseFont1: "Playfair Display", sans-serif;
$baseFont2: "Playfair Display", serif;
$baseColor: #080a12;
$blueclr: #0935dc;
$blueclr1: #1745f5;
$blueclr2: #003eff;
$lightblueclr: rgba(0, 102, 255, 0.18);
$dark_blue: #122674;
$textGrey: #e7ebfe;
$textGrey1: #62646b;
$textGrey2: #8b8ca7;
$textGrey3: #c3c3c3;
$textGrey4: #8f9195;
$textGrey5: #bbbbbb;
$textGrey6: #aeb1bf;
$textGray: #808286;
$dropdwonBg1: #1f2127;
$greyclr: #6c7080;
$white: #ffffff;
$whitelightclr: #e9eaf0;
$whitelightclr1: rgba(255, 255, 255, 0.6);
$blackclr: #000000;
$borderColor: #262a39;
$borderColor1: #333540;
$borderColor2: #1c1e2b;
$borderColor3: #1f2333;
$borderColor4: rgba(233, 234, 240, 0.15);
$borderColor5: rgba(255, 255, 255, 0.24);
$borderColor6: #a5a6f6;
$borderColor7: #6f70ff;
$borderColor8: #3c415c;
$scrollColor: #374462;
$specialColor: #68174a;
$inputBg: #21242b;
$dropdwonBg: #1f2127;
$btnbg: #3a3d4a;
$btnbghover: #031249;
$ctaHover: #1745f5;
$greybtnbg: #272933;
$greybtnbg1: #0e111e;
$greybg: #2b3144;
$greybg1: #545766;
$greybg2: #232530;
$greybg3: #9295a6;
$greybg4: #1d2230;
$lightwhitetext: #e8e8e9;
$baseColor2: #025cda;
$redclr: #dc2626;
$redclr1: #f87171;
$reddarkclr: #5b2b2f;
$inputlabelclr: #60636d;
$placeholderclr: #6b96d4;
$greenclr: #03a66d;
$greenclr1: #34d399;
$greenbtnclr: #10b981;
$bluelightBg: #468ced;
$whitelightBg: rgba(255, 255, 255, 0.03);
$darkblackBg: #131623;
$darkblackBg1: #1b1b1d;
$darkblackBg2: #121318;
$darkblackBg3: #080808;
$darkblackBg4: #1b1c23;
$lightwhitetext2: rgba(255, 255, 255, 0.5);
$lightwhitetext3: rgba(211, 211, 213, 0.3);
$lightwhitetext4: rgba(255, 255, 255, 0.6);
$lightwhitetext5: rgba(255, 255, 255, 0.24);
$lightwhitetext6: #e7e7e9;
$headerBg: #04050b;
$modalBgclr: #0d0f1a;
$overlayBg: rgb(0 0 0 / 60%);
$btnGradienthover: linear-gradient(0deg,
        rgba(255, 255, 255, 0.05),
        rgba(255, 255, 255, 0.05)),
    #131624;
$BorderGradient: linear-gradient(90deg,
        rgba(23, 69, 245, 0.84) 0%,
        rgba(103, 182, 255, 0.84) 14.06%,
        rgba(13, 40, 147, 0.84) 28.65%,
        rgba(81, 143, 237, 0.84) 42.19%,
        rgba(0, 21, 137, 0.84) 54.69%,
        rgba(23, 69, 245, 0.84) 66.67%,
        rgba(103, 182, 255, 0.84) 78.12%,
        rgba(13, 40, 147, 0.84) 90.1%,
        rgba(81, 143, 237, 0.84) 100%);
$gradient1: linear-gradient(to bottom, #15161d 0%, #161f43 100%);
$gradient2: linear-gradient(to right,
        #0b0b14 45%,
        #162358 64%,
        #162358 75%,
        #0b0b14 100%);
$gradientgreen: linear-gradient(270deg,
        rgba(51, 169, 150, 0) 57.61%,
        rgba(51, 169, 150, 0.2) 100%),
    #0c0f1b;
$gradientred: linear-gradient(270deg,
        rgba(12, 15, 27, 0.5) 50%,
        rgba(210, 57, 78, 0.24) 100%),
    #0c0f1b;
$gradientblueclr: linear-gradient(142.79deg, #0729ab 3.3%, #267dff 88.77%);
$gradientgreyclr: linear-gradient(111.8deg, #2a3048 12.44%, #1f2333 38.23%);
$gradientgreylefttoright: linear-gradient(-45deg,
        #04050b 23.44%,
        #131623 58.23%);
$gradientCard: linear-gradient(270deg, #0935dcb5 18.44%, #031249ba 70.23%);
$checkboxbgclr: rgba(255, 255, 255, 0.3);
$bannerbg: #000032;
$cardbg: #0e111f;
$hidebtnbg: #171a2b;
$hoverclr: #252c49;
$bgclr: #06070b;
$bgclr1: #b6c4fc;
$bgclr2: #273054;
$bgclr3: #222534;
$bgclr4: #07080f;
$cardbgclr: #050505;
$dashbg: #0a0c17;
$footerlinkclr: #ccced9;
$radioborderclr: #323338;
$chatbgclr: #020204;
$darkblackBg5: #1B1B1D;
$lineBorder: #26283c;
$greenbg: rgba(16, 185, 129, 0.22);
$redbgclr: rgba(255, 0, 0, 0.08);
$bluelightbg: rgba(23, 69, 245, 0.18);
$yellowlightbg: rgba(255, 199, 0, 0.18);
$lightwhitetext2: rgba(255, 255, 255, 0.5);
$yellowclr: #ffc700;
$coralred: #ef4444;
$clr1: #63677a;
$navy_blue: #122674;
$navy_blue1: #0f2f5e;
$textGreynew: #8f9195;
$textBlue: #1745f5;
$blackTransparent: rgba(0, 0, 0, 0.6);
$blackBg: #000000;