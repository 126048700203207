@import "../../../../../assets/theme/_var.scss";

.Trending_Pair {
    margin-top: 18px;
    background-color: $cardbg;
    padding: 34px 18px 18px 18px;
    border-radius: 4px;

    &_Box {
        display: flex;
        flex-wrap: nowrap;
        margin-top: 15px;
        overflow-x: auto;
        padding-bottom: 7px;

        &::-webkit-scrollbar {
            height: 5px !important;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $borderColor3;
            border-radius: 10px;
        }

        li {
            display: flex;
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }

            .Trending_Box_Card {
                background-color: $cardbgclr;
                border-radius: 10px;
                padding: 20px 12px;
                width: 130px;
                text-align: center;

                p {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;

                    span {
                        color: $greyclr;
                    }
                }

                h6 {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    margin: 12px 0;
                    margin-bottom: 0;

                    small {
                        font-weight: 600;
                        font-size: 10px;
                        line-height: 12px;
                        display: block;
                        margin-top: 4px;
                    }
                }

                span {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: $white;
                    &:hover {
                        img {
                            margin-left: 12px;
                        }
                    }
                    img {
                        transition: all ease 0.3s;
                        margin-left: 8px;
                    }
                }
            }
        }
    }
}
