@import "../../../../../assets/theme/_var.scss";

.Positions_Table {
    .table {
        margin-top: 0px;
        border: 1px solid $borderColor3;
        @media (max-width: 767px) {
            margin-top: 15px;
        }

        thead {
            tr {
                border-bottom: 1px solid $borderColor3;
                th {
                    background-color: $darkblackBg;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 15px;
                    color: $greybg3;
                    padding: 10px 18px;
                    border: 0;
                    min-width: 100px;
                    background: transparent;
                    border-left: 1px solid $borderColor3;
                    vertical-align: middle;
                    @media (max-width: 767px) {
                        padding: 10px 10px;
                        white-space: nowrap;

                        &:first-child {
                            min-width: 110px;
                        }
                    }
                    .block {
                        display: block;
                    }
                }
            }
        }

        tbody {
            tr {
                transition: all ease 0.3s;
                border-bottom: 1px solid $borderColor3;
                &:nth-of-type(odd) {
                    background: $darkblackBg;
                    &:hover {
                        background: $borderColor3;
                    }
                }
                &:hover {
                    background-color: $darkblackBg;
                }
                td {
                    vertical-align: middle;
                    padding: 16px 18px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 15px;
                    border-color: $borderColor3;
                    color: $white;
                    border: 0;
                    min-width: 100px;
                    background: transparent;
                    @media screen and (max-width: 1366px) {
                        font-size: 14px;
                    }
                    @media (max-width: 767px) {
                        padding: 15px 10px;

                        &:first-child {
                            min-width: 110px;
                        }
                    }

                    &:last-child {
                        border-left: 0;
                    }

                    .coin_data {
                        .coin_Icon {
                            width: 30px;
                            margin-right: 14px;
                        }

                        span {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 15px;
                            color: $greybg3;
                        }
                    }
                    .usdValue {
                        color: $greybg3;
                    }

                    .action_links {
                        a {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 17px;
                            margin-right: 25px;
                            color: $bluelightBg;
                            transition: all ease-in-out 0.3s;
                            position: relative;
                            @media screen and (max-width: 1366px) {
                                font-size: 14px;
                            }
                            &:before {
                                content: "";
                                position: absolute;
                                left: 0;
                                bottom: -5px;
                                width: 0;
                                height: 2px;
                                background: $bluelightBg;
                                transition: 0.3s;
                            }
                            @media (max-width: 767px) {
                                min-width: 100px;
                            }

                            &:last-child {
                                margin-right: 0;
                            }
                            &:hover {
                                color: $bluelightBg;
                            }
                            &:hover:before {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
