@import "../../../../assets/theme/_var.scss";

.SetOrder_Card {
    border: 0.5px solid $borderColor5;
    border-radius: 5px;
    padding: 20px;
    .p2p_buttons{
        .connect_wlt{
            width: 100%;
        }
    }
    @media (max-width: 767px) {
        padding: 15px 10px;
    }

    .Order_Title {
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: $white;
        margin-bottom: 18px;
    }
}
