@import "../../../../../assets/theme/_var.scss";

.Market_Tab {
  .Available_balance {
    h5 {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      padding-right: 10px;
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: $white;

      img {
        margin-right: 9px;
      }

      span {
        color: $greyclr;
      }
    }
  }

  .custom_Usdt {
    margin: 22px 0;

    .Usdt_Ipnut {
      background-color: $darkblackBg;
      border-radius: 5px 0px 0px 5px;
      height: 37px;
      padding: 8px 18px;
      padding-right: 0;

      .custom_input {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $white;
        background-color: transparent;
        border: 0;
        width: calc(100% - 30px);
        padding-right: 10px;
        padding-left: 0;

        &:focus {
          outline: 0;
        }

        &::placeholder {
          color: $greyclr;
          opacity: 1;
        }
      }

      .btc_btn {
        background-color: $blackclr;
        border-radius: 4px;
        font-weight: 400;
        font-size: 8px;
        line-height: 17px;
        padding: 4px 15px;
        color: $white;
        margin-right: 14px;
        cursor: pointer;
      }

      .usdt_btn {
        background-color: $blueclr;
        border-radius: 0px 8px 8px 0px;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        height: 37px;
        padding: 8px 6px;
        color: $white;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    &.custom_Usdt_Size {
      .Usdt_Ipnut {
        border-radius: 5px;
      }
    }

    .input_arrow {
      width: 30px;
      flex-direction: column;
      display: flex;
      align-items: flex-end;

      span {
        width: 9px;
        display: block;
        cursor: pointer;
        line-height: 15px;
      }
    }
  }

  // .Leverage_Slider {
  //     padding-bottom: 35px;
  // }

  .Advanced_Long {
    .common_heading {
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      svg {
        margin-left: 12px;
        transition: all ease-in-out 0.3s;
      }
    }

    .Advanced_btns {
      margin-top: 18px;
      display: flex;
      transition: all ease-in-out 0.3s;

      .btn-primary {
        position: relative;

        .btn_icon {
          position: absolute;
          right: 18px;
          margin: 0;

          @media (max-width: 1439px) {
            right: 12px;
            margin: 0;
            max-width: 13px;
          }
        }

        @media (max-width: 1199px) {
          font-size: 12px;
          padding-left: 4px;
        }
      }
    }

    &.openbtns {
      // .common_heading {
      //     svg {
      //         transform: rotate(180deg);
      //     }
      // }

      .Advanced_btns {
        display: flex;
      }
    }
  }
}

.common_heading {
  margin-bottom: 0;
  margin-right: 24px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: $white;
}
