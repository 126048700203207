@import "../../../assets/theme/var.scss";

.section-top.p2p_page {
  padding: 20px 0;
  text-align: left;
  position: relative;
  z-index: 1; 
  background: $gradient2;

  @media (max-width: 991px) {
    background-image: none;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      background: $gradient1;
      width: 100%;
      z-index: -1;
    }
  }

  @media (max-width: 767px) {
    padding: 45px 0 0;
  }

  h1 {
    font-size: 50px;
    font-weight: 200;
    margin-bottom: 26px;

    @media (max-width: 767px) {
      font-size: 43px;
      margin-bottom: 15px;
    }
  }

  em {
    font-weight: 400;
    font-family: $baseFont2;
  }

  p {
    color: $textGrey1;
    margin-bottom: 40px;
    font-weight: 300;
    font-size: 14px;

    @media (max-width: 767px) {
      font-size: 14px;
      max-width: 320px;
      margin: 0 auto 32px;
    }
  }

  .Banner_coin {
    position: relative;
    top: 65px;

    @media (max-width: 767px) {
      top: 35px;
    }

    img {
      @media (max-width: 424px) {
        max-width: 300px;
      }
    }
  }

  .p2p_start_btn {
    min-width: 230px;

    &:hover,
    &:focus {
      background-color: $white;
      color: $baseColor;
    }

    @media (max-width: 424px) {
      min-width: 100%;
    }
  }
}

.Set_Order_Sec {
  padding: 103px 0 40px;

  .container {
    max-width: 700px;
  }

  .loader.loader--inner {
    position: fixed;
    z-index: 9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;

    &::before {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.58;
      background-color: #000;
      content: "";
    }
    
    h2 {
      // z-index: 1;
      position: relative;
    }
}  
}

.Commom_card_Box {
  background: $darkblackBg1;
  border: 0.5px solid $borderColor4;
  border-radius: 3px;
  padding: 14px;

  h5 {
    font-weight: 700;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: $white;
    margin-bottom: 10px;
  }
}

.Ehx_Buy_Sell {
  padding: 17px 20px;

  @media (max-width: 767px) {
    padding: 15px 10px;
  }

  ul {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }

      p {
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        color: $inputlabelclr;
      }

      h5 {
        font-weight: 300;
        font-size: 12px;
        line-height: 21px;
        color: $inputlabelclr;
        padding-left: 10px;
        margin-bottom: 0;

        span {
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: $white;
          padding-right: 3px;
        }
      }
    }
  }
}