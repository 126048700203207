@import "../../../../../assets/theme/_var.scss";

.Overview_Banner {
    background-color: $bannerbg;
    background-image: url("../../../../../assets/images/admin/blue_doted_bg.svg");
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    @media (max-width:1199px) {
        background-size: cover;
    }

    @media (max-width:767px) {
        background-size: inherit;
        background-position: bottom;
    }

    &_Content {
        padding: 52px;
        width: 60%;

        @media (max-width:1599px) {
            padding: 52px 30px;
        }

        @media (max-width:1199px) {
            padding: 25px 20px;
            width: 50%;
        }

        @media (max-width:767px) {
            width: 100%;
        }

        h1 {
            font-weight: 600;
            font-size: 48px;
            line-height: 58px;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            color: $white;

            @media (max-width:1599px) {
                font-size: 40px;
                line-height: 50px;
            }

            @media (max-width:1365px) {
                font-size: 30px;
                line-height: 40px;
            }
        }

        h5 {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            color: $white;

            @media (max-width:1599px) {
                font-size: 18px;
                line-height: 22px;
            }

            @media (max-width:1365px) {
                font-size: 16px;
                line-height: 20px;
            }
        }

        h2 {
            font-weight: 600;
            font-size: 28px;
            line-height: 34px;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            color: $white;
            margin-top: 40px;

            @media (max-width:1599px) {
                font-size: 25px;
                line-height: 31px;
            }

            @media (max-width:1365px) {
                font-size: 23px;
                line-height: 35px;
            }
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            color: $white;
        }

        &_btns {
            margin-top: 20px;

            .btn-primary {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                padding-left: 18px;
                padding-right: 18px;
                margin: 5px 0;

                @media (max-width:1599px) {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }
    }

    &_featureImg {
        width: 40%;

        @media (max-width:1199px) {
            width: 50%;
        }

        @media (max-width:767px) {
            width: 100%;
        }
    }
}