@import "../../../../assets/theme/var";

.AnalysisWidget {
  .react-resizable-handle.react-resizable-handle-se {
    display: none;
  }
}

.Technical_Analysis_Box {
  margin-top: 0px;

  .Technical_Analysis_card {
    overflow: hidden;
    width: 100%;

    .card_hdr_title {
      padding: 10px 0 20px;
    }

    .tradingview-widget-container {
      iframe {
        width: 100% !important;
        background-color: #131722;

        @media (min-width: 1037px) {
          height: 200px !important;
        }

        @media (max-width: 1023px) {
          width: auto !important;
        }
      }
    }

    .tv-embed-widget-wrapper__body {
      background: transparent !important;
      border: 0 !important;
    }
  }
}

.speedometerTitle-ZWjd6Cah {
  color: #131722;
  font-size: 13px !important;
  font-weight: 400;
  letter-spacing: 0.3px;
  margin: 20px 0;
  text-align: left;
}

.tradingview-widget-container {
  .tv-embed-widget-wrapper {
    background-color: #fff !important;
  }
}

.Technical_Analysis_card .tv-embed-widget-wrapper {
  background: #fff !important;
}
