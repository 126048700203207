@import "../../../../assets/theme/_var.scss";

.Bound_Card {
    background: $darkblackBg1;
    border: 0.5px solid $borderColor4;
    border-radius: 3px;

    .Bottom_Border_Tabs {
        border-bottom: 1px solid $borderColor4;
        margin-bottom: 0;

        li {
            .nav-link {
                border-bottom: 2px solid transparent;

                @media (min-width:1200px) {
                    &:hover {
                        border-bottom: 2px solid transparent;
                    }
                }

                &.active {
                    border-bottom: 2px solid $baseColor;
                    color: $white;
                    background: transparent;
                }
            }
        }
    }

    .tab-content {
        padding: 16px 20px;

        @media (max-width: 767px) {
            padding: 15px 10px;
        }
    }

    .Out_Bound {
        border: 0.5px solid $borderColor4;
        border-radius: 3px;
        padding: 14px 9px;
        min-height: 179px;
        position: relative;

        &_Exh {
            max-height: 216px;
            overflow-y: auto;
            padding: 0 5px;

            &::-webkit-scrollbar {
                width: 3px !important;
              }
        
              // &::-webkit-scrollbar-track {
              //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              // }
        
              &::-webkit-scrollbar-thumb {
                background-color: $blueclr;
                border-radius: 10px;
              }

            .Two_Icon {
                img {
                    box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.25);

                    &:first-child {
                        position: relative;
                        z-index: 1;
                    }

                    &:last-child {
                        margin-left: -5px;
                    }
                }
            }

            h5 {
                margin-bottom: 0;
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                color: $white;
                padding-left: 8px;
            }
        }
        
        &_Box {
            border-bottom: 1px solid #1d3233;
            margin-top:15px;
            padding: 15px 0;

            .btn-primary {
                height: 38px;
            }
        }

        &_Link {
            svg {
                width: 14px;
                height: 14px;
                margin-left: 19px;
                cursor: pointer;
            }
        }

        &_List {
            margin-top: 14px;

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 6px;

                &:last-child {
                    margin-bottom: 0;
                }

                p {
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 15px;
                    margin-bottom: 0;
                    color: $inputlabelclr;
                    width: 120px;
                }

                h5 {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 15px;
                    color: $white;
                    margin-bottom: 0;
                    width: calc(100% - 120px);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    text-align: right;
                    white-space: nowrap;

                    span {
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 15px;
                        margin-bottom: 0;
                        color: $inputlabelclr;
                    }
                }
            }
        }

        .Not_Orders {
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;

            h5 {
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                color: $white;
                max-width: 166px;
                text-align: center;
                margin: 0 auto;
            }
        }
    }

   .loader.loader--inner {
        position: absolute;
        z-index: 9;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }  
}