@import "../../assets/theme/_var.scss";

.site_header {
  padding: 15px 24px;
  background: $headerBg;
  position: relative;
  z-index: 999;
  height: 74px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;

  @media (max-width: 1199px) {
    padding: 15px 15px;
  }

  @media (max-width: 767px) {
    padding: 15px 10px;
  }
  @media screen and (max-width: 420px) {
    height: 100px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: $BorderGradient;
    left: 0;
  }

  .navbar {
    padding: 0;
    align-items: center;
    width: 100%;
    .headerNav {
      @media screen and (max-width: 420px) {
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
      }
    }
    .Right_Header {
      display: flex;
      align-items: center;

      @media (max-width: 1199px) {
        margin-right: 10px;
      }

      @media (max-width: 575px) {
        margin-right: 5px;
      }

      .wallet_area {
        position: relative;

        .btn-primary {
          height: 34px;
          font-weight: 400;
          font-size: 16px;
          width: 132px;
          border-radius: 4px;
          transition: all ease-in-out 0.3s;
          position: relative;
          text-align: center;
          padding: 5px 5px;
          display: flex;
          justify-content: center;
          background-color: $blueclr;
          border-color: $blueclr;

          @media (max-width: 767px) {
            font-size: 12px;
            padding: 3px 5px;
            width: 95px;
          }
          @media screen and (max-width: 420px) {
            font-size: 10px;
            width: 75px;
          }

          &:hover,
          &:focus {
            background-color: $btnbghover;
            border-color: $btnbghover;
          }
          img {
            @media screen and (max-width: 420px) {
              width: 10px;
              margin-right: 3px;
            }
          }

          &.Disconnect_wlt {
            img {
              display: none;
            }
          }
        }

        .walladdress__drop {
          background-color: $dropdwonBg;
          padding: 20px 10px;
          position: absolute;
          border-radius: 0px;
          right: 0px;
          width: 190px;
          box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.5);
          z-index: 99;
          top: 35 px;
          margin-top: 20px;
          transition: all ease-in-out 0.3s;
          // opacity: 0;
          // visibility: hidden;
          display: none;

          button {
            background-color: transparent;
            border: 0;
            height: auto;
            padding: 0;

            &:hover,
            &:focus {
              outline: none;
              box-shadow: none;
              border: 0;
              background: transparent !important;
            }
          }

          &__copy {
            padding: 0px 14px 10px;

            button {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              padding: 0;
              font-weight: 700;
              font-size: 12px;
              line-height: 14px;

              &:hover {
                background: transparent !important;

                .cpy_icon {
                  filter: invert(0);
                  opacity: 0.5;
                }
              }

              h3 {
                color: $lightwhitetext;
                font-weight: 600;
                font-size: 12px;
                line-height: 14px;
                margin: 0;
              }

              span {
                margin-left: 10px;
              }
            }

            p {
              color: $textGrey;
              font-weight: 300;
              font-size: 7px;
              line-height: 14px;
              margin-top: 3px;
            }
          }

          &__list {
            ul {
              li {
                button {
                  font-weight: 900;
                  font-size: 10px;
                  line-height: 21px;
                  padding: 8px 14px;
                  letter-spacing: 0.1px;
                  color: $white;
                  width: 100%;
                  text-align: start;
                  transition: all ease 0.5s;
                  position: relative;
                  border-radius: 0;

                  .wll_icon {
                    width: 12px;
                    display: inline-block;
                    text-align: start;
                    margin-right: 10px;

                    svg {
                      path {
                        fill: #3e3e3e;
                      }
                    }
                  }

                  &:hover {
                    color: $lightwhitetext;
                    background-color: $greybtnbg;

                    .wll_icon {
                      svg {
                        path {
                          fill: $blueclr;
                        }
                      }
                    }

                    &::after {
                      content: "";
                      display: inline-block;
                      background-image: url("../../assets/images/big_arrow.svg");
                      background-size: 11px;
                      width: 11px;
                      height: 7px;
                      background-repeat: no-repeat;
                      transition: all ease-in-out 0.3s;
                      position: absolute;
                      right: 15px;
                      top: 50%;
                      transform: translateY(-50%);
                    }
                  }
                }

                &:last-child {
                  border-top: none;
                }
              }
            }
          }
        }

        &:hover {
          .walladdress__drop {
            display: block;
            // visibility: visible;
            // opacity: 1;
            margin-top: 0;
          }
        }
      }
      .calculatorBtn {
        margin-left: 24px;
        cursor: pointer;
        img {
          @media screen and (max-width: 767px) {
            width: 12px;
            height: auto;
          }
        }
      }
      .Network_Dropdown {
        margin-left: 12px;

        @media (max-width: 767px) {
          margin-left: 6px;
        }

        .dropdown {
          .dropdown-toggle {
            min-width: 70px;
            height: 34px;
            padding: 3px 12px;
            background: $darkblackBg;
            border-radius: 3px;
            box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.5);
            border-radius: 8px;
            border: 0;

            @media (max-width: 767px) {
              padding: 3px 10px;
            }
            @media screen and (max-width: 420px) {
              min-width: 40px;
              padding: 3px 5px;
            }

            &::after {
              margin-left: 0;
              background-image: url(../../assets/images/down_arr_icon.svg);
              width: 10px;
              height: 5px;
              background-repeat: no-repeat;
              border: none;
              background-position: center;
              position: relative;
              top: 2px;
              transition: all ease-in-out 0.3s;
              display: inline-block;
            }

            span {
              border-right: 1px solid $borderColor1;
              margin-right: 14px;
              padding-right: 14px;
              display: inline-block;
              vertical-align: middle;
              @media screen and (max-width: 420px) {
                margin-right: 8px;
                padding-right: 8px;
              }

              img {
                width: 13px;
                display: block;
                @media screen and (max-width: 420px) {
                  width: 10px;
                }
              }
            }

            &.show {
              &::after {
                transform: rotate(180deg);
              }
            }
          }

          .dropdown-menu {
            background-color: $dropdwonBg;
            box-shadow: none;
            border-radius: 0px 0px 5px 5px;
            margin-top: 3px;
            border: 0;
            min-width: 166px;
            padding: 11px;
            top: 41px;

            a {
              margin-left: 0;
              padding: 12px;
              font-weight: 700;
              border-bottom: 0;
              display: flex;
              align-items: center;
              position: relative;
              border-radius: 3px;
              color: $white;
              // &:hover,
              // &.active,
              // &:focus {
              //     background-color: #272933;
              //     color: $white;
              // }

              &::after {
                content: "";
                display: inline-block;
                background-image: url("../../assets/images/big_arrow.svg");
                background-size: 11px;
                width: 11px;
                height: 7px;
                background-repeat: no-repeat;
                transition: all ease-in-out 0.2s;
                position: absolute;
                right: 18px;
                top: 50%;
                transform: translateY(-50%);
                opacity: 0;
                visibility: hidden;
              }

              &:hover,
              &.active,
              &:focus {
                background-color: $greybtnbg;
                color: $white;

                &::after {
                  right: 12px;
                  opacity: 1;
                  visibility: visible;
                }

                svg {
                  path {
                    fill: $blueclr;
                  }
                }
              }

              .seleted-label {
                display: flex;
                align-items: center;

                .thumb {
                  display: flex;
                  align-items: center;

                  img {
                    width: 100%;
                    max-width: 14px;
                  }

                  span {
                    padding-left: 13px;
                    font-weight: 600;
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }

      .Header_Setting {
        .Lang_Into_Set {
          margin-left: 24px;
          transition: all ease-in-out 0.3s;

          @media (max-width: 767px) {
            margin-left: 10px;
          }

          .dropdown {
            .dropdown-toggle {
              padding: 0;
              display: block;
              line-height: 0;
              cursor: pointer;
              &::after {
                display: none;
              }

              &:hover,
              &.show {
                svg {
                  @media screen and (max-width: 420px) {
                    width: 15px;
                  }
                  path {
                    fill: $white;
                  }
                }
              }
              svg {
                width: 15px;
                height: 15px;
              }
            }
          }
        }

        .Language_Dropdown {
          .dropdown-menu {
            width: 340px;
            top: 46px;
            padding: 20px 17px;

            @media (max-width: 767px) {
              width: 300px;
              padding: 20px 15px;
              right: -60px;
            }

            .nav-tabs {
              border-color: $borderColor4;
              display: flex;
              flex-direction: row;

              .nav-item {
                margin-right: 25px;

                &:last-child {
                  margin-right: 0;
                }

                .nav-link {
                  background-color: transparent;
                  border: 0;
                  color: $greyclr;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 22px;
                  border-bottom: 2px solid transparent;
                  padding: 0;
                  padding-bottom: 27px;

                  &.active {
                    color: $blueclr;
                    border-color: $blueclr;
                  }
                }
              }
            }

            a {
              &:hover {
                background-color: transparent;
              }
            }
          }

          .Language_List {
            ul {
              margin-top: 20px;

              li {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: $greyclr;
                padding: 9px 10px;
                margin-bottom: 10px;
                position: relative;

                &:last-child {
                  margin-bottom: 0;
                }

                &.active,
                &:hover {
                  background-color: $blueclr;
                  color: $white;
                  border-radius: 4px;
                }

                &.active {
                  &::after {
                    content: "";
                    background-image: url("../../assets/images/Checkmark.svg");
                    width: 21px;
                    height: 20px;
                    background-size: 21px;
                    position: absolute;
                    right: 10px;
                    background-repeat: no-repeat;
                    top: 10px;
                  }
                }
              }
            }
          }
        }

        &.UserAdmin_Dropdown {
          .dropdown {
            .dropdown-toggle {
              color: $white;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: transparent;
              border-radius: 100px;
              cursor: pointer;
              @media (max-width: 767px) {
                width: 20px;
                height: 20px;
              }
              @media screen and (max-width: 420px) {
                width: 15px;
                height: 15px;
              }
              svg{
                path{
                  fill: $greybg1;
                }
              }
              &:hover{
                svg {
                  path {
                    fill: $white;
                  }
                }
              }
              svg {
                @media screen and (max-width: 420px) {
                  width: 8px;
                  height: 8px;
                }
              }
            }

            .dropdown-menu {
              width: 310px;
              top: 46px;
              padding: 0;
              background-color: $darkblackBg;

              @media (max-width: 767px) {
                width: 300px;
                padding: 20px 15px;
                right: -60px;
              }

              a {
                &:hover {
                  background-color: transparent;
                }
              }

              .UserAdmin_List {
                ul {
                  li {
                    a {
                      font-weight: 700;
                      font-size: 12px;
                      line-height: 22px;
                      color: $white;
                      padding: 17px 15px;
                      margin-bottom: 10px;
                      position: relative;
                      display: block;

                      &:last-child {
                        margin-bottom: 0;
                      }

                      span {
                        padding-left: 13px;
                      }

                      svg {
                        width: 24px;
                      }

                      &.active,
                      &:hover {
                        background-color: $greybg4;
                        color: $white;
                        border-radius: 4px;

                        svg {
                          width: 24px;

                          path {
                            fill: $blueclr1;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .navbar-toggler {
      padding: 0;
      font-size: 18px;
      border: 0;
      border-radius: 0;

      .navbar-toggler-icon {
        background-image: url("../../assets/images/menu.svg");
        background-repeat: no-repeat;
        width: 19px;
        height: 18px;
        @media screen and (max-width: 420px) {
          width: 12px;
          height: 15px;
        }
      }
    }

    .Setting_Dropdown {
      .dropdown-menu {
        width: 312px;
        top: 46px;
        padding: 20px 18px;
        background-color: $darkblackBg;
        box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.5);
        border-radius: 0px 0px 5px 5px;
        overflow-y: auto;
        height: 285px;

        @media (max-width: 767px) {
          width: 300px;
          right: -10px;
          overflow-y: auto;
          height: 80vh;
        }

        .Setting_options {
          h5 {
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            color: $greyclr;
          }

          ul {
            li {
              padding: 11px 0;
              cursor: pointer;
              border-right: 2px solid transparent;
              margin-bottom: 0px;
              transition: all ease-in-out 0.3s;
              display: flex;
              justify-content: space-between;

              &:last-child {
                margin-bottom: 0;
              }

              svg {
                width: 20px;
                height: 20px;

                path {
                  fill: $greybg3;
                }
              }

              span {
                display: inline-block;
                margin-left: 0;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                color: $white;
              }

              .Trade_Notifications_list {
                & > div {
                  margin-bottom: 20px;
                  align-items: center;

                  &:last-child {
                    margin-bottom: 0;
                  }
                }

                .checkbtn_btn {
                  .form-check-input {
                    border: 1px solid #2b3144;
                    border-radius: 3px;
                    width: 20px;
                    height: 20px;
                    background-color: transparent;

                    &:checked {
                      background-image: url("../../assets/icons/green_check.svg");
                      background-size: 12px;
                    }
                  }

                  .form-check-label {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    color: $white;
                    margin-left: 12px;
                  }
                }

                svg {
                  width: 6px;
                  height: 10px;
                  margin-left: 19px;

                  path {
                    fill: transparent;
                  }
                }
              }
            }

            &.Color_Preferences {
              padding-top: 5px;

              li {
                padding: 8px 0;
              }
            }
          }

          &.top_border {
            border-top: 1px solid $greybg;
            padding-top: 15px;
            margin-top: 10px;
          }
        }
      }
    }

    .Notification_Dropdown {
      .Show_Notification_No {
        width: 14px;
        height: 14px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: $blueclr;
        font-weight: 600;
        font-size: 9px;
        line-height: 11px;
        color: $white;
        border-radius: 100px;
        position: absolute;
        top: -4px;
        right: -3px;
      }

      .dropdown-menu {
        width: 300px;
        top: 46px;
        padding: 0px 0px;
        border-radius: 16px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.38);

        @media (max-width: 767px) {
          right: -40px;
        }

        ul {
          li {
            padding: 17px;
            cursor: pointer;
            margin-bottom: 5px;
            transition: all ease-in-out 0.3s;
            display: flex;
            align-items: flex-start;
            background-color: $darkblackBg;

            &:first-child {
              border-radius: 16px 16px 0 0;
            }

            &:last-child {
              border-radius: 0 0 16px 16px;
              margin-bottom: 0;
            }

            &:hover,
            &.active {
              background-color: $greybg4;

              span {
                // background-color: $darkblackBg;
                transform: scale(1.05);
              }

              h5 {
                // color: $blueclr;

                img {
                  margin-left: 10px;
                }
              }
            }

            span {
              // display: flex;
              // justify-content: center;
              // align-items: center;
              // width: 32px;
              // height: 32px;
              // background-color: $blackclr;
              // border-radius: 50px;
              transition: all ease-in-out 0.3s;
            }

            h5 {
              font-weight: 400;
              font-size: 12px;
              line-height: 13px;
              color: $white;
              width: calc(100% - 32px);
              padding-left: 17px;
              transition: all ease-in-out 0.3s;

              small {
                display: block;
                font-weight: 300;
                font-size: 10px;
                line-height: 14px;
                color: $textGrey2;
                margin-top: 6px;
              }

              img {
                margin-left: 5px;
                transition: all ease-in-out 0.3s;
              }
            }
          }
        }
      }
    }
  }

  .navbar-collapse {
    padding-left: 40px;

    .mobilenav {
      @media (max-width: 1199px) {
        margin: 80px 20px 0;
        width: calc(100% - 40px);
      }
    }

    &.show {
      .Mob_header_top_box {
        display: block;
      }
    }
  }

  .NabBar_Menu {
    .navbar-nav {
      @media (max-width: 1199px) {
        padding: 18px 0 20px;
      }

      li {
        margin-right: 38px;

        @media (max-width: 1199px) {
          margin-right: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      a {
        padding: 7px 13px;
        font-weight: 400;
        color: $textGrey;
        font-size: 16px;
        transition: all ease 0.5s;
        border-radius: 4px;

        @media (max-width: 1199px) {
          margin: 0px 0 !important;
          padding: 17px 0px;
          font-size: 14px;
          line-height: 21px;
          border: 0;
          font-weight: 500;
        }

        .bubble {
          position: relative;
          border-radius: 5px;
          transition: all ease 0.5s;
          display: inline-block;
          height: auto;
          width: auto;
          padding: 1px 7px;
          margin-left: 8px;
          font-size: 12px;
          background: $specialColor;

          &::after {
            content: "";
            position: absolute;
            right: 100%;
            top: 4px;
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-right: 8px solid $specialColor;
            border-bottom: 5px solid transparent;
          }
        }

        &:hover,
        &.show,
        &:focus {
          @media (min-width: 1200px) {
            color: $white;
            background: $blueclr;
            border-color: $blueclr;
          }
        }

        &:hover,
        &.show,
        &:focus {
          @media (max-width: 1199px) {
            color: $white;
          }
        }

        &.show {
          opacity: 0.8;
        }
      }

      .dropdown {
        &.inner_submenu {
          .dropdown-toggle.btn-primary {
            border: 0;
            background-color: transparent;
            font-weight: 400;
            color: $textGrey;
            font-size: 16px;
            transition: all ease 0.5s;
            padding: 8px 13px;
            text-align: left;
            height: auto;
            width: 100%;
            display: block;
            border-radius: 4px;

            @media (max-width: 1199px) {
              padding: 14px 0;
              font-size: 14px;
              font-weight: 500;
            }

            &:hover,
            &.show,
            &:focus {
              @media (min-width: 1200px) {
                color: $white;
                border-color: $blueclr;
                background: $blueclr;
              }

              @media (max-width: 1199px) {
                color: $white;
              }
            }
          }
        }

        .dropdown-menu {
          padding: 19px 9px;
          background-color: $dropdwonBg;
          box-shadow: none;
          min-width: 332px;
          border-radius: 0px 0px 5px 5px;
          margin-top: 3px;
          border: 0;
          top: 38px;

          @media (max-width: 1199px) {
            width: 100%;
            padding: 0;
            min-width: 100%;
            position: static !important;
            transform: none !important;
          }

          a {
            margin-left: 0;
            padding: 12px;
            font-weight: 700;
            border-bottom: 0;
            display: flex;
            align-items: center;
            position: relative;
            border-radius: 3px;
            color: $white;
              &.soonTag{
                span{
                    position: relative;
                    &::before{
                      content: "Soon";
                      opacity: 0;
                      margin-left: 10px;
                      font-weight: 700;
                      font-size: 10px;
                      line-height: 13px;
                      color: #ffffff;
                      background: #0935dc;
                      border-radius: 3px;
                      padding: 2px 5px 2px 5px;
                      text-transform: capitalize;
                      display: inline-block !important;
                      position: absolute;
                      top: 2px;
                      opacity: 1;
                      visibility: visible;
                      left: 100px;                    
                    }
                }
              }

            img {
              width: 25px;

              @media (max-width: 1199px) {
                display: none;
              }
            }

            span {
              max-width: 220px;
              display: block;
              padding-left: 25px;
              font-size: 12px;
              line-height: 20px;

              @media (max-width: 1199px) {
                padding-left: 0;
              }
            }

            small {
              display: block;
              white-space: normal;
              font-weight: 400;
              font-size: 10px;
              line-height: 140%;
              color: $inputlabelclr;
              margin-top: 4px;

              @media (max-width: 1199px) {
                display: none;
              }
            }

            svg {
              margin-right: 13px;

              path {
                fill: $textGrey;
              }
            }

            &:first-child {
              &.active {
                background-color: $greybtnbg;
                color: $white;
                opacity: 1;

                @media (max-width: 1199px) {
                  background-color: transparent;
                }
              }
            }

            &::after {
              content: "";
              display: inline-block;
              background-image: url("../../assets/images/big_arrow.svg");
              background-size: 11px;
              width: 11px;
              height: 7px;
              background-repeat: no-repeat;
              transition: all ease-in-out 0.2s;
              position: absolute;
              right: 18px;
              top: 50%;
              transform: translateY(-50%);
              opacity: 0;
              visibility: hidden;
            }

            &:hover,
            &.active,
            &:focus {
              background-color: $greybtnbg;
              color: $white;

              &::after {
                right: 12px;
                opacity: 1;
                visibility: visible;
              }

              svg {
                path {
                  fill: $blueclr;
                }
              }
            }
          }

          &.Mega_Submenu {
            display: none;
            flex-wrap: wrap;
            min-width: 750px;

            @media (max-width: 1199px) {
              min-width: 100%;
            }

            &.show {
              display: flex;
              flex-wrap: wrap;
            }

            a.nav-link {
              width: 50%;

              @media (max-width: 1199px) {
                width: 100%;
              }

              span {
                max-width: 290px;

                @media (max-width: 1199px) {
                  min-width: 100%;
                }
              }
            }
          }

          .inner_to_submenu {
            .dropdown-toggle.btn-primary {
              border: 0;
              background-color: transparent;
              color: $white;
              padding: 0;
              font-weight: 700;
              font-size: 12px;
              transition: all ease 0.3s;
              padding: 12px;
              text-align: left;
              height: auto;
              width: 100%;
              justify-content: flex-start;
              display: flex;

              img {
                width: 25px;

                @media (max-width: 1199px) {
                  display: none;
                }
              }

              span {
                max-width: 220px;
                display: block;
                padding-left: 25px;

                @media (max-width: 1199px) {
                  padding-left: 0px;
                }
              }

              small {
                display: block;
                white-space: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 140%;
                color: $inputlabelclr;
                margin-top: 4px;

                @media (max-width: 1199px) {
                  display: none;
                }
              }

              &:hover,
              &.show,
              &:focus {
                color: $white;

                @media (max-width: 1199px) {
                  color: $white;
                }
              }
            }

            .dropdown-toggle {
              cursor: pointer;
              &::after {
                background-image: url("../../assets/images/rightArrow.svg");
                top: 0px;
                transform: rotate(0deg);
                height: 8px;
                width: 8px;
                opacity: 0.3;
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
                background-size: 5px;
                transition: all ease-in-out 0.2s;
                display: inline-block;

                @media (max-width: 1199px) {
                  top: 18px;
                  transform: rotate(0deg);
                  background-image: url(../../assets/icons/m_down_arr_icon.svg);
                  height: 7px;
                  width: 14px;
                  background-size: 14px;
                  opacity: 1;
                }
              }

              &:hover,
              &.show,
              &:focus {
                background-color: $greybtnbg;

                &::before {
                  filter: brightness(1) invert(0);
                  opacity: 1;
                }
              }

              &.show {
                &::after {
                  @media (max-width: 1199px) {
                    background-image: url(../../assets/icons/m_down_arr_icon.svg);
                    transform: rotate(180deg);
                  }
                }
              }
            }

            .dropdown-menu {
              left: 321px;
              top: -19px;
              border: 0;
              min-width: 306px;
              background-color: $greybtnbg;
              box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.5);
              transition: all ease-in-out 0.3s;
              display: block;
              opacity: 0;
              visibility: hidden;
              padding: 0;
              margin-top: 0;
              border-radius: 0;
              border-radius: 0px 0px 5px 5px;

              &.show {
                left: 321px;
                opacity: 1;
                visibility: visible;
              }

              @media (max-width: 1199px) {
                display: none;
                opacity: 1;
                visibility: visible;
                left: 0;
                top: 0;
                width: 100%;
                background-color: $dropdwonBg;
                box-shadow: none;
                min-width: 100%;

                &.show {
                  left: 0;
                  display: block;
                  padding-left: 20px;
                }
              }

              .navbar-nav {
                &.nav-pills {
                  padding: 0 12px;
                  border-bottom: 1px solid #4c4d52;

                  @media (max-width: 1199px) {
                    display: flex;
                    flex-direction: unset;
                    padding: 0;
                  }

                  .nav-item {
                    padding-right: 20px;

                    &:last-child {
                      padding-right: 0;
                    }

                    a {
                      border-bottom: 3px solid transparent;
                      border-radius: 0;
                      padding: 20px 0 15px;

                      &::after {
                        display: none;
                      }

                      &.active {
                        color: $white;
                        border-color: $blueclr;
                      }
                    }
                  }
                }
              }

              .Tabs_Content_Box {
                padding: 17px 12px;

                @media (max-width: 1199px) {
                  padding: 17px 0;
                }

                .Tabs_Btns {
                  span {
                    background: $dropdwonBg;
                    border: 1px solid $dropdwonBg;
                    border-radius: 3px;
                    color: $white;
                    font-weight: 700;
                    font-size: 11px;
                    line-height: 21px;
                    letter-spacing: 0.1px;
                    height: 24px;
                    display: inline-flex;
                    align-items: center;
                    text-align: center;
                    padding: 0 12px;
                    cursor: pointer;
                  }
                }
              }

              .tab-content {
                max-height: 270px;
                overflow-y: auto;

                .nav-link {
                  padding: 11px 12px;
                  position: relative;

                  @media (max-width: 1199px) {
                    padding: 11px 0;
                  }

                  img {
                    width: 22px;
                    height: 22px;
                    object-fit: cover;
                    border-radius: 50px;
                  }

                  h4 {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 21px;
                    letter-spacing: 0.1px;
                    margin-bottom: 0;
                    padding-left: 13px;
                    color: $lightwhitetext;

                    span {
                      font-weight: 300;
                      padding-left: 0;
                      display: inline-block;
                    }

                    .token_full_name {
                      display: block;
                      font-weight: 300;
                      font-size: 9px;
                      line-height: 14px;
                    }
                  }

                  &.active,
                  &:hover {
                    background-color: $dropdwonBg;

                    &::after {
                      content: "";
                      display: inline-block;
                      background-image: url("../../assets/images/big_arrow.svg");
                      background-size: 11px;
                      width: 11px;
                      height: 7px;
                      background-repeat: no-repeat;
                      transition: all ease-in-out 0.3s;
                      position: absolute;
                      right: 15px;
                      top: 50%;
                      transform: translateY(-50%);
                    }
                  }
                }

                .Content_links_list {
                  a {
                    img {
                      display: inline-block;
                    }
                  }
                }
              }
            }
          }
        }

        .dropdown-toggle::after {
          margin-left: 11px;
          background-image: url(../../assets/images/down_arr_icon.svg);
          width: 11px;
          height: 6px;
          background-repeat: no-repeat;
          border: none;
          background-position: center;
          position: relative;
          top: 2px;
          transition: all ease-in-out 0.3s;
          display: inline-block;

          @media (max-width: 1199px) {
            float: right;
            top: 8px;
            background-image: url(../../assets/icons/m_down_arr_icon.svg);
            width: 14px;
            height: 7px;
          }
        }

        &.show {
          .dropdown-toggle {
            color: $blueclr;

            &::after {
              @media (min-width: 1200px) {
                transform: rotate(180deg);
                filter: brightness(0) invert(1);
                // background-image: url(../../assets/images/down_arr_icon1.png);
              }
            }
          }
        }

        &.show {
          .dropdown-toggle {
            color: $blueclr;

            &::after {
              @media (max-width: 1199px) {
                background-image: url(../../assets/icons/m_down_arr_icon.svg);
                transform: rotate(-180deg);
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .navbar-nav {
    .dropdown:hover {
      .dropdown-menu {
        display: block;
        margin-top: 0;
      }
    }
  }
}

.site_header.offcanvas {
  width: 100%;
  max-width: 350px;
  background: $dropdwonBg;
  transition: all ease-in-out 0.2s;
  padding: 0;
  height: auto;
  overflow-y: auto;

  &::after {
    display: none;
  }

  @media (max-width: 767px) {
    max-width: 320px;
  }

  .Mob_header_top_box {
    padding: 18px 20px 20px;
    position: fixed;
    right: 0;
    display: none;
    background-color: $dropdwonBg;
    width: 300px;
    z-index: 9;

    .navbar-toggler {
      .navbar-toggler-icon {
        background-image: url("../../assets/images/crossIcon.svg");
      }
    }
  }

  .offcanvas-header {
    position: fixed;
    width: 320px;
    right: 0;
    background-color: $dropdwonBg;
    text-align: right;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    z-index: 1;

    .btn-close {
      filter: brightness(1) invert(1);
      opacity: 1;
      width: 20px;
      height: 20px;
      background-size: 20px;

      &:focus {
        box-shadow: none;
        outline: 0;
      }
    }
  }

  .offcanvas-body {
    margin-top: 60px;
    padding: 20px 20px;

    .NabBar_Menu {
      .btn-primary {
        width: 100%;
      }
    }
  }
}

.SendNotification_modal.modal {
  .modal-dialog {
    max-width: 600px !important;
  }

  .SendNotification_modal_Content {
    .FormGroup {
      label {
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
      }

      .custom_input {
        width: 100%;
        background-color: $dashbg;
        border-radius: 8px;
        height: 55px;
        border-color: $dashbg;
      }
    }

    .checkbtn_btn {
      .form-check-input {
        border: 1px solid #2b3144;
        border-radius: 3px;
        width: 20px;
        height: 20px;
        background-color: transparent;

        &:checked {
          background-image: url("../../assets/icons/green_check.svg");
          background-size: 12px;
        }
      }

      .form-check-label {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: $white;
        margin-left: 12px;
      }
    }

    .btn-primary {
      border-radius: 8px;
    }
  }
}

.Logo_Icon_animate {
  animation: mylogo 5s infinite;
}

@keyframes mylogo {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(360deg);
  }

  50% {
    transform: rotate(360deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.calcl_btc {
  margin-right: 10px;
}

// .calcBtn {
//     // height: 34px;
//     // font-weight: 400 !important;
//     // font-size: 16px !important;
//     // width: 132px;
//     // border-radius: 4px;
//     // transition: all ease-in-out 0.3s;
//     // position: relative;
//     // text-align: center;
//     // padding: 5px 5px;
//     // display: flex;
//     // justify-content: center;
//     // background-color: #0935dc;
//     // border-color: #0935dc;

// }
.sectionCalc {
  margin-right: 5px;
}
