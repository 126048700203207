@import "../../../../assets/theme/_var.scss";

.NewsLetter_Sec {
    background-color: $blueclr;
    padding: 90px 0;

    @media (max-width: 767px) {
        padding: 50px 0;
    }

    .News_Heading {

        h2 {
            font-weight: 200;
            font-size: 40px;
            line-height: 120%;
            max-width: 350px;

            @media (max-width: 767px) {
                margin-bottom: 30px;
                font-size: 35px;
                line-height: 120%;
            }

            em {
                font-family: $baseFont2;
            }
        }
    }

    .Newsletter_Box {
        .input-group {
            .form-control {
                font-weight: 300;
                font-size: 14px;
                line-height: 180%;
                height: 45px;
                border-radius: 3px;
                border: 0;
                background-color: $white;

                &::placeholder {
                    color: $blackBg;
                    opacity: 1;
                }

                &:focus {
                    outline: 0;
                    box-shadow: none;
                }
            }

            .input-group-text {
                font-weight: 700;
                font-size: 14px;
                line-height: 180%;
                background-color: $blackBg;
                border: 0;
                color: $white;
                width: 145px;
                justify-content: center;
                height: 45px;
                border-radius: 3px;

                @media (max-width: 767px) {
                    width: 100px;
                }
            }
        }
    }
}