@import "../../../../../assets/theme/_var.scss";

.Deposit {

    &_Card {
        .Label_Text {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: $white;
        }

        &_Inputgroup {
            margin-bottom: 48px;

            label {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 18px;
            }

            .dropdown {
                .dropdown-toggle {
                    border: 1px solid $borderColor8;
                    background-color: $bgclr4;
                    padding: 14px 28px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: $white;

                    span {
                        margin-left: 12px;
                    }

                    img {
                        width: 30px;
                        height: 30px;
                    }

                    &::after {
                        position: absolute;
                        right: 25px;
                        top: 25px;
                        background: transparent;
                        border-top: 8px solid #6C76AA;
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        transition: all ease-in-out 0.3s;
                        border-radius: 2px;
                    }

                    &:focus {
                        border: 1px solid $borderColor8;
                    }

                    &.show {
                        &::after {
                            transform: rotate(180deg);
                        }
                    }
                }

                .dropdown-menu {
                    background-color: $bgclr4;
                    border-radius: 10px;
                    margin-top: 0px;
                    width: 100%;
                    border: 1px solid $borderColor8;
                    padding: 0;
                    height: 414px;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 5px !important;
                    }

                    //   &::-webkit-scrollbar-track {
                    //     box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.06);
                    //   }

                    &::-webkit-scrollbar-thumb {
                        background-color: $blueclr1;
                        border-radius: 10px;
                    }

                    a {
                        color: $white;
                        padding: 14px 28px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        border-bottom: 1px solid $borderColor8;
                        position: relative;

                        &:last-child {
                            border-bottom: 0;
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            right: 25px;
                            top: 25px;
                            background: transparent;
                            border-left: 8px solid #6C76AA;
                            border-top: 6px solid transparent;
                            border-bottom: 6px solid transparent;
                            transition: all ease-in-out 0.3s;
                            border-radius: 2px;
                        }

                        img {
                            width: 30px;
                            height: 30px;
                        }

                        span {
                            margin-left: 12px;
                        }

                        small {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 15px;
                            color: $greybg3;
                            margin-left: 12px;
                        }

                        &:hover {
                            background-color: transparent;
                        }
                    }
                }
            }

            .Deposit_Card_Network {
                border: 1px solid $borderColor8;
                background-color: $bgclr4;
                padding: 14px 28px;
                width: 100%;
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: $greybg3;
                border-radius: 4px;
                cursor: pointer;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    right: 25px;
                    top: 22px;
                    background: transparent;
                    border-top: 8px solid #6C76AA;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    transition: all ease-in-out 0.3s;
                    border-radius: 2px;
                }
            }
        }

        &_OrderInfo {
            .Sub_text {
                display: none;
            }

            &_List {
                display: flex;
                flex-wrap: wrap;
                margin-top: 30px;

                li {
                    width: 50%;
                    margin-bottom: 25px;

                    p {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: $greybg3;
                        margin-bottom: 18px;
                    }

                    h4 {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        color: $white;

                        span {
                            font-weight: 500;
                            padding-left: 15px;
                        }
                    }
                }
            }

            &_Fee {
                span {
                    width: 50%;
                    margin-bottom: 25px;
                    display: block;

                    &.cardgreen_btn {
                        background: $greenbg;
                        color: $greenclr1;
                        padding: 2px 4px;
                        display: inline-flex;
                        align-items: center;
                        width: auto;
                    }
                }
            }

            &_sendList {
                list-style: disc;
                padding-left: 15px;
                margin-bottom: 50px;

                li {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 28px;
                    margin-bottom: 15px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .Deposit_btn {
                width: 235px;
            }

            .netweork_fee {
                h3 {
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 28px;
                    color: $white;
                    margin-bottom: 10px;
                }

                p {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 18px;
                    color: $white;
                }

                .tooltipIcon {
                    margin-left: 5px;

                    svg {
                        width: 16px;
                        height: 16px;

                        path {
                            fill: $blueclr1;
                        }
                    }
                }
            }
        }
    }
}

.Admin_Modal.modal {
    .modal-dialog {
        max-width: 730px;
        margin: 0 auto;
        padding: 0 15px;

        .modal-content {
            background: $darkblackBg;
            border: 0;
            border-radius: 0;
            padding: 48px 34px;

            @media (max-width: 767px) {
                padding: 35px 15px 20px;
            }

            .modal-header {
                padding: 0;

                .modal-title {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 130%;

                    @media (max-width: 1559px) {
                        font-size: 20px;
                    }

                    @media (max-width: 991px) {
                        font-size: 16px;
                    }
                }

                .btn-close {
                    background: url("../../../../../assets/images/icon_close.svg") center no-repeat;
                    outline: none;
                    width: 16px;
                    height: 16px;
                    background-size: 16px;
                    right: 43px;
                    top: 54px;

                    @media (max-width:767px) {
                        right: 15px;
                        top: 48px;
                    }
                }
            }

            .modal-body {
                padding: 0;

                .Convert_Usdt_data {
                    padding: 82px 0 34px;
                    position: relative;

                    @media (max-width: 1559px) {
                        padding: 40px 0 34px;
                    }

                    ul {
                        li {
                            padding-bottom: 100px;

                            @media (max-width: 1559px) {
                                padding-bottom: 60px;
                            }

                            &:last-child {
                                padding-bottom: 0;
                            }

                            h3 {
                                display: flex;
                                align-items: center;

                                img {
                                    width: 30px;
                                    margin-right: 18px;
                                }
                            }

                            h3,
                            h4 {
                                margin-bottom: 0;
                                font-weight: 600;
                                font-size: 28px;
                                line-height: 34px;
                                color: $white;

                                @media (max-width: 1559px) {
                                    font-size: 22px;
                                    line-height: 28px;
                                }

                                @media (max-width:767px) {
                                    font-size: 18px;
                                    line-height: 24px;
                                }
                            }
                        }
                    }

                    .Arrow_down {
                        position: absolute;
                        left: 50%;
                        top: 58%;
                        transform: translate(-50%, -45%) rotate(90deg);

                        @media (max-width: 1559px) {
                            top: 52%;
                        }

                        img {
                            width: 35px;

                            @media (max-width: 1559px) {
                                width: 25px;
                            }
                        }
                    }
                }

                .Select_Network_modal_Content {
                    max-width: 100%;

                    .Select_Network_Information {
                        ul {
                            height: 350px;
                            overflow-y: auto;
                            padding-right: 10px;

                            &::-webkit-scrollbar {
                                width: 5px !important;
                            }

                            &::-webkit-scrollbar-track {
                                background-color: transparent;
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: $greybg1;
                                border-radius: 10px;
                            }

                            li {
                                border: 1px solid $borderColor1;
                                border-radius: 4px;
                                padding: 16px 30px;
                                margin-bottom: 34px;
                                cursor: pointer;
                                transition: all ease-in-out 0.3s;

                                @media (max-width:767px) {
                                    padding: 16px 15px;
                                    margin-bottom: 20px;
                                }

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                &:hover {
                                    background-color: $blueclr1;
                                    border-color: $blueclr1;
                                }

                                p,
                                h4 {
                                    font-weight: 700;
                                    font-size: 18px;
                                    line-height: 22px;
                                    color: $white;

                                    span {
                                        display: block;
                                        font-weight: 600;
                                        color: $textGrey6;
                                        margin-top: 8px;
                                    }

                                    @media (max-width:767px) {
                                        font-size: 16px;
                                        line-height: 20px;
                                    }
                                }

                                h4 {
                                    margin-bottom: 0;

                                    @media (max-width:767px) {
                                        margin-top: 15px;
                                    }
                                }
                            }
                        }
                    }

                    .error_msg {
                        padding: 0px 16px;
                        margin-top: 40px;
                        margin-bottom: 40px;

                        @media (max-width:767px) {
                            padding: 0px;
                            margin-top: 30px;
                            margin-bottom: 20px;
                        }

                        img {
                            margin-right: 20px;
                        }

                        p {
                            color: $white;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 150%;

                            @media (max-width:767px) {
                                font-size: 14px;
                                line-height: 20px;

                            }
                        }
                    }
                }
            }

            .btn-primary {
                margin-top: 34px;
                height: 68px;
                font-weight: 600;
                font-size: 20px;
                line-height: 130%;

                @media (max-width:767px) {
                    margin-top: 25px;
                    height: 40px;
                    font-size: 16px;
                }
            }
        }
    }
}

.Confirm_modal.modal {
    .modal-dialog {
        max-width: 595px;

        .modal-content {
            .Confirm_modal_Content {
                .Confirm_transaction {
                    padding-top: 40px;

                    svg {
                        width: 39px;
                        height: 39px;

                        path {
                            fill: $blueclr1;
                        }
                    }

                    p {
                        width: calc(100% - 39px);
                        padding-left: 25px;
                    }

                    .Confirmation_Network {
                        margin-top: 30px;
                        text-align: center;

                        h4 {
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 160%;

                            span {
                                font-weight: 500;
                                color: $greybg3;
                            }
                        }
                    }

                    .Confirmation_No {
                        margin-top: 30px;

                        h3 {
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 160%;
                            color: $white;
                            text-align: center;

                            span {
                                font-weight: 700;
                            }
                        }
                    }

                    .No_Transaction {
                        h4 {
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 160%;
                            margin: 20px 0;
                        }

                        p {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 160%;
                        }

                        ul {
                            list-style: disc;
                            text-align: left;
                            padding-left: 20px;
                            max-width: 530px;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin-top: 20px;

                            li {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 160%;
                                margin-bottom: 10px;

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.Deposit_successful_modal.modal {
    .modal-dialog {
        max-width: 595px;

        .modal-content {
            .modal-header {
                justify-content: center;
            }

            .modal-body {
                .Deposit_successful {
                    margin: 40px 0;

                    h3 {
                        font-weight: 600;
                        font-size: 28px;
                        line-height: 160%;
                        margin: 10px 0;
                    }

                    p {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 160%;
                    }
                }

                .Deposit_successful_Information {
                    ul {
                        li {
                            padding: 7px 0;

                            p {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 160%;
                                color: $greybg3;
                            }

                            h4 {
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 160%;
                                color: $white;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}