@import "../../../../../assets/theme/_var.scss";

.deposit_modal.modal {
    .modal-dialog {
        max-width: 600px;

        .modal-content {
            .modal-header {
                padding: 20px 34px;

                .btn-close {
                    right: 45px;
                }
            }

            .modal-body {
                padding: 20px 34px;

                .Input_Group {
                    .input-type2 {
                        height: 60px;
                        padding: 8px 25px;
                    }

                    label {
                        span {
                            font-weight: 700;
                            color: #777ea1;
                        }
                    }
                }
                .swapIcon {
                    width: 100%;
                    display: inline-block;
                    text-align: center;
                    position: relative;
                    top: 8px;
                    img {
                        width: 20px;
                        height: auto;
                        cursor: pointer;
                    }
                }
                button.btn-primary {
                    height: 60px;
                    border-radius: 8px;

                    @media (max-width: 767px) {
                        height: 45px;
                    }
                }

                h6 {
                    color: $greybg3;
                    margin-bottom: 0;
                    line-height: 24px;
                }

                .error_msg {
                    background-color: $redbgclr;
                    padding: 12px 32px;
                }
            }
        }
    }
}
