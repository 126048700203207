@import "../../../../assets/theme/_var.scss";

.Common_TopBar {
    .Common_Header_Btns {
        @media (max-width: 1199px) {
            margin-top: 15px;
        }
        .btn-primary {
            margin-left: 24px;

            &:first-child {
                margin-left: 0;
            }

            @media (max-width: 767px) {
                margin-left: 0;
                margin-right: 10px;
                margin-bottom: 10px;
                height: 40px;
                font-weight: 500;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
.width-2 {
    column-gap: 24px;
    column-count: 2;
    @media screen and (max-width: 767px) {
        column-gap: 0;
        row-gap: 25px;
    }
    .Spot_blnc_Card {
        flex: 0 0 calc(50% - 15px);
        max-width: 50%;

        @media screen and (max-width: 767px) {
            width: 100%;
            flex: 0 0 100%;
        }
    }
}

.Spot_blnc_Card {
    // background: $gradientgreyclr;
    background-image: $gradientCard;
    border-radius: 14px;
    padding: 38px;

    @media (max-width: 767px) {
        padding: 30px 15px;
    }

    h5 {
        font-weight: 600;
        font-size: 18px;
        line-height: 130%;
        margin-bottom: 0;
    }

    .Show_Blnc {
        background-color: $darkblackBg;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        cursor: pointer;
    }

    h3 {
        font-weight: 700;
        font-size: 28px;
        line-height: 130%;
        color: $white;
        margin-bottom: 0;
        margin-top: 20px;

        @media (max-width: 1199px) {
            font-size: 25px;
        }

        @media (max-width: 991px) {
            font-size: 20px;
        }

        @media (max-width: 767px) {
            font-size: 16px;
        }

        span {
            color: $greyclr;
        }
    }
}

.Spot_Pl {
    h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 0;
        padding-left: 52px;
    }

    h2 {
        font-weight: 500;
        font-size: 26px;
        line-height: 31px;
        margin: 8px 0;

        img {
            margin-right: 10px;
        }
    }

    p {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: $greyclr;
        padding-left: 52px;
    }
}

.Convert_Coin {
    .Dash_SearchBar {
        max-width: 300px;
    }

    .form-check {
        align-items: center;

        .form-check-input {
            background-color: transparent;
            border: 1px solid $greybg1;
            height: 24px;
            width: 24px;
        }

        .form-check-label {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: $white;
        }
    }

    .Convert_Blnc {
        img {
            margin-right: 5px;
        }
    }
}
