@import "../../../../../assets/theme/_var.scss";

.Market_Tab {
  .Available_balance {
    h5 {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      padding-right: 10px;
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: $white;

      img {
        margin-right: 9px;
      }

      span {
        color: $greyclr;
      }
    }
  }

  .custom_Usdt {
    margin: 22px 0;

    .Usdt_Ipnut {
      &.leverage-box {
        width: 100%;
        .custom_input {
          width: calc(100% - 100px);
        }
      }
      &.fullWidth{
        width: 100%;
      }

      background-color: $darkblackBg;
      border-radius: 5px 0px 0px 5px;
      height: 37px;
      padding: 8px 18px;
      padding-right: 0;
      width: 90%;

      .custom_input {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $white;
        background-color: transparent;
        border: 0;
        width: calc(100% - 30px);
        padding-right: 10px;
        padding-left: 0;

        &:focus {
          outline: 0;
        }

        &::placeholder {
          color: $greyclr;
          opacity: 1;
        }
      }

      .btc_btn {
        background-color: $blackclr;
        border-radius: 4px;
        font-weight: 400;
        font-size: 8px;
        line-height: 17px;
        padding: 4px 15px;
        color: $white;
        margin-right: 14px;
        cursor: pointer;
      }

      .leverage-label {
        background-color: $blackclr;
        border-radius: 4px;
        font-weight: 400;
        font-size: 10px;
        line-height: 17px;
        padding: 4px 15px;
        color: $white;
        margin-right: 14px;
        // cursor: pointer;
        width: 100px;
        text-align: center;
      }

      .usdt_btn {
        background-color: $blueclr;
        border-radius: 0px 8px 8px 0px;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        height: 37px;
        padding: 8px 6px;
        color: $white;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .input_arrow {
      width: 30px;
      flex-direction: column;
      display: flex;
      align-items: flex-end;

      span {
        width: 9px;
        display: block;
        cursor: pointer;
        line-height: 15px;
      }
    }
  }

  //   .Leverage_Slider {
  //     // padding-bottom: 35px;
  //   }

  .Advanced_Long {
    .common_heading {
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      .blue_check_box{
        input{
          border-color: $blueclr;
        }
      }
      svg {
        margin-left: 12px;
        transition: all ease-in-out 0.3s;
      }
    }

    .Advanced_btns {
      margin-top: 5px;
      display: flex;
      transition: all ease-in-out 0.3s;

      .btn-primary {
        position: relative;

        .btn_icon {
          position: absolute;
          right: 18px;
          margin: 0;

          @media (max-width: 1439px) {
            right: 12px;
            margin: 0;
            max-width: 13px;
          }
        }
      }
    }

    &.openbtns {
      // .common_heading {
      //     svg {
      //         transform: rotate(180deg);
      //     }
      // }

      .Advanced_btns {
        display: flex;
      }
    }
    .tradeBtn {
      width: 100%;
      display: inline-block;
      margin: 15px 0 20px;
      button {
        &.tradeBtnCtaLong {
          width: 100%;
          background: $greenclr1;
          color: $baseColor;
          height: 45px;
        }
        &.tradeBtnCtaShort {
          width: 100%;
          background: $redclr1;
          color: $baseColor;
          height: 45px;
        }
      }
    }
  }
}

.common_heading {
  margin-bottom: 0;
  margin-right: 24px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: $white;
}

.addTpSlSection {
  display: inline-block;
  width: 100%;

  //   margin-top: 20px;
  .addTpSlSectionInnner {
    display: inline-block;
    margin-top: 10px;
  }
  .quantity_box {
    background-color: #131623;
    border-radius: 4px;
    padding: 10px 20px;
  }

  .Input_Group {
    label {
      display: block;
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      color: $white;
      margin-bottom: 10px;
      height: 20px;
    }

    .quantity_input {
      height: auto;
      border: 0;
      background-color: transparent;
      color: $white;
      text-align: center;
      padding: 0 10px;
      width: calc(100% - 40px);

      &.vreadonly {
        padding: 0;
      }

      &::placeholder {
        color: $white;
      }
    }

    .common_select {
      &.Profit_select {
        .react-select__control {
          width: 90px;
          font-size: 14px;
        }

        .react-select__placeholder {
          font-size: 14px;
        }
      }
    }
  }

  h6 {
    font-size: 14px;
    display: inline-block;
    margin-top: 20px;
  }
}

.Custom_Order_Tab {
  margin-bottom: 24px;
  margin-top: 10px;
}
