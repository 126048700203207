@import "../../../../assets/theme/_var.scss";

.HelpDesk {
    &_help {
        h5 {
            color: $greybg3;
        }

        svg {
            path {
                fill: #96969A;
            }
        }
    }

    &_Boxs {
        text-align: center;

        figure {
            margin-bottom: 38px;
            background-color: $darkblackBg;
            padding: 70px;
            border-radius: 20px;

            @media (max-width:767px) {
                padding: 60px;
            }
        }

        .btn-primary {
            padding-left:25px;
            padding-right:25px;
        }
    }
}