@import "../../assets/theme/_var.scss";

.modal.connect_wallet {
  .modal-dialog {
    display: flex;
    flex: 1;
    height: auto;
    max-width: 548px;
    margin: 0 auto;

    @media (max-width: 767px) {
      max-width: 100%;
    }

    .modal-content {
      .modal-header {
        justify-content: center;

        .modal-title {
          font-size: 23px;

          @media (max-width: 767px) {
            font-size: 12px;
          }
        }
      }

      .modal-body {
        padding: 0;

        .token_list {
          padding: 0;

          ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0px 9px 24px;
            margin-top: 24px;

            @media (max-width: 767px) {
              margin-top: 10px;
              padding: 0px 10px;
            }

            li {
              margin-bottom: 10px;
              width: 50%;
              padding: 0 9px;
              position: relative;

              @media (max-width: 767px) {
                width: 50%;
                padding: 0 5px;
              }

              &:last-child {
                margin-bottom: 0;
              }

              button {
                background-color: $darkblackBg;
                border: 1px solid $borderColor1;
                width: 100%;
                font-weight: 600;
                transition: all ease 0.5s;
                padding: 5px 27px;
                border-radius: 8px;
                color: $white;
                font-size: 16px;
                text-align: left;
                display: flex;
                align-items: center;
                height: 61px;

                @media (max-width: 767px) {
                  background-color: $btnGradienthover;
                  font-size: 11px;
                  padding: 5px 15px;
                  height: 40px;
                }

                img {
                  width: 35px;
                  margin-right: 21px;

                  @media (max-width: 767px) {
                    width: 20px;
                    margin-right: 10px;
                  }
                }

                &:hover {
                  background-color: $greybtnbg;
                }
              }

              .ClickArrow {
                position: absolute;
                right: 17px;
                opacity: 0.3;
                top: 49%;
                transform: translateY(-50%);
              }
            }

          }

          .add_new {
            font-size: 11px;
            font-weight: 300;
            max-width: 195px;
            margin: 24px auto;

            a {
              color: $blueclr;
              // text-decoration: underline;
              font-weight: 700;
              display: block;
            }
          }
        }
      }
    }
  }
}