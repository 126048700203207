@import "../../../assets//theme/_var.scss";

.staking-tabs {
  &.nav-tabs {
    border: 0;

    .nav-item {
      margin-right: 1px;

      @media (max-width: 575px) {
        flex: 1 0;
      }
    }

    .nav-link {
      min-width: 140px;
      padding: 9px 30px;
      background-color: $darkblackBg4;
      border-radius: 0;
      border: 0.5px solid $borderColor5;
      color: $lightwhitetext3;
      font-size: 14px;
      font-weight: 700;
      border-bottom:0.5px solid transparent;

      @media (max-width: 575px) {
        min-width: 100%; 
        padding: 10px 15px;
        font-size: 12px;
      }

      // &:hover {
      //   border-top: 3px solid $baseColor2;
      //   color: $lightwhitetext3;        
      // }

      &.active {
        border-top: 3px solid $baseColor2;
        background-color: $darkblackBg4;
        color: $white;
      }
    }
  }
}

.staking-section {
  .tab-content {
    margin-top: -1px;

    .staking-card {
      border-radius: 0 5px 5px 5px;
      @media (max-width: 575px) { 
        border-radius: 0 0px 5px 5px;
      }
    }
  }
}