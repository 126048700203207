@import "../../../../../assets/theme/_var.scss";

.BuySellTab_Modal {

    @media (max-width:1023px) {
        bottom: 0;
        top: auto;
        height: 100%;

        &.show {
            bottom: 0;
        }
    }

    .modal-dialog {
        margin: 0;

        .modal-content {
            .modal-header {
                padding: 0;
                border: 0;

                .btn-close {
                    top: 20px;
                    right: 20px;
                }
            }

            .modal-body {
                padding: 10px;
                padding-top: 35px;

                @media (max-width:1023px) {
                    padding-top: 16px;
                    padding-bottom: 0;
                    // padding: 30px 10px ;
                }

                .BuySellTab.cardStyle {
                    background-color: transparent;

                    @media (max-width:1023px) {
                        padding: 0px;
                        border: 0;
                    }

                    .orderPlace,
                    .orderField {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    .orderField {
                        padding-bottom: 0;

                        @media (max-width:1023px) {
                            padding-bottom: 13px;
                        }
                    }

                    .orderPlace {
                        @media (max-width:1023px) {
                            padding-top: 0;
                        }
                    }
                }
            }
        }
    }
}