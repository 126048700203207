@import "../../../../assets/theme/_var.scss";

.BackArrow {
    margin-right: 14px;

    img {
        transform: rotate(180deg);
        cursor: pointer;
    }
}



.Dropdown_Token_List,
.Common_Dropdown_Two {
    .dropdown {
        .dropdown-toggle {
            width: 100%;
            padding: 0;
            text-align: left;
            font-weight: 700;
            font-size: 12px;
            line-height: 21px;
            color: $white;
            border: 0;

            img {
                margin-right: 9px;
            }

            &:focus {
                outline: 0;
                box-shadow: none;
            }

            &::after {
                left: auto;
                right: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            &.show {
                &::after {
                    transform: rotate(180deg) translateY(50%);
                }
            }
        }

        .dropdown-menu {
            background-color: $dropdwonBg1;
            box-shadow: none;
            border-radius: 0px 0px 5px 5px;
            margin-top: 3px;
            border: 0;
            min-width: 130px;
            padding: 11px;
            top: 41px;
            right: 0;

            a {
                margin-left: 0;
                padding: 6px 15px;
                border-bottom: 0;
                display: flex;
                align-items: center;
                position: relative;
                border-radius: 3px;
                color: $white;
                font-weight: 700;
                font-size: 12px;
                line-height: 21px;

                &::after {
                    content: "";
                    display: inline-block;
                    background-image: url("../../../../assets/images/big_arrow.svg");
                    background-size: 11px;
                    width: 11px;
                    height: 7px;
                    background-repeat: no-repeat;
                    transition: all ease-in-out 0.2s;
                    position: absolute;
                    right: 18px;
                    top: 50%;
                    transform: translateY(-50%);
                    opacity: 0;
                    visibility: hidden;
                }

                &:hover,
                &.active,
                &:focus {
                    background-color: $greybtnbg;
                    color: $white;

                    &::after {
                        right: 12px;
                        opacity: 1;
                        visibility: visible;
                    }

                    svg {
                        path {
                            fill: $blueclr;
                        }
                    }
                }
            }
        }
    }
}

.Reverse_Token_card {
    position: relative
}

.Commom_card_Box {
    margin-bottom: 27px;

    &:last-child {
        margin-bottom: 0;
    }

    .Card_Lebel {

        h6 {
            font-weight: 500;
            font-size: 11px;
            line-height: 18px;
        }

        .max_text {
            margin-left: 3px;
        }
    }

    .Card_Token {
        display: flex;
        align-items: center;
        background: #1B1B1D;
        border: 0.5px solid #36383F;
        border-radius: 3px;
        padding: 14px;
        position: relative;

        .Select_Token_List {
            width: 125px;
            padding-right: 13px;
            color: #0000;

            &.common_select {
                .react-select__control {
                    color: #0000;

                    .react-select__single-value {
                        img {
                            margin-right: 8px;
                            width: 25px;
                        }
                    }

                    .react-select__input-container.css-qbdosj-Input {
                        opacity: 0;
                    }
                }

                .react-select__menu {
                    min-width: 125px;
                    background-color: #1B1B1D;
                }


            }
        }



        .Card_Token_Bal {
            width: calc(100% - 125px);
            padding-left: 13px;
            border-left: 1px solid $borderColor;

            .form-control {
                text-align: right;
                background-color: transparent;
                border: 0;
                font-weight: 700;
                font-size: 18px;
                line-height: 21px;
                color: $white;
                padding: 0;

                &::placeholder {
                    color: $white;
                }

                &:focus {
                    box-shadow: none;
                    outline: 0;
                }
            }

            span {
                display: block;
                text-align: right;
                font-weight: 300;
                font-size: 12px;
                line-height: 21px;
                color: $inputlabelclr;
            }
        }
    }

    .text-danger {
        font-size: 10px;
        position: absolute;
        bottom: 0;
    }
}

.Reverse_btn {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    border-radius: 3px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    background-color: $blueclr;
}



.Get_Expires_card {
    margin-top: 11px;
    display: flex;

    .Commom_card_Box {
        width: calc(100% - 160px);
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        .custom_input {
            background: #1B1B1D;
            border: 0.5px solid #333540;
            border-radius: 3px;
            padding: 8px 10px;
            height: 42px;
            color: #ffffff;
            opacity: 1;
        }

        // .common_select {
        //     .react-select__control {
        //         .react-select__single-value {
        //             font-weight: 700;
        //             font-size: 12px;
        //             letter-spacing: 0.1px;
        //             color: $white;
        //         }
        //     }
        // }
    }

    .Expires_Box {
        width: 160px;
        padding-left: 14px;
        display: flex;

        .Commom_card_Box {
            width: 100%;
            display: block;

            h5 {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

.Order_modal.modal {
    .modal-dialog {
        max-width: 720px;

        .modal-body {
            padding: 40px 20px;
        }


    }
}

.react_select_style__menu {
    background-color: #0000;
    border-radius: 4px;
}