@import "../../../assets/theme/_var.scss";

.btn-style,
.white-btn {
  font-family: $baseFont;
  height: 44px;
  padding: 10px 30px;
  border: 0;
  border-radius: 3px;
  background: $blueclr;
  font-size: 14px;
  font-weight: 700;
  transition: all 0.25s;
  color: $white;
  cursor: pointer;

  &:focus,
  &:hover {
    box-shadow: unset;
    background: $dark_blue;
  }

  @media (max-width: 1199px) {
    padding: 10px 15px;
  }

  @media (max-width: 575px) {
    font-size: 14px;
    height: 42px;
    padding: 5px 5px;
  }

  &.white-btn {
    background: $lightwhitetext;
    color: $blueclr;

    &:focus,
    &:hover {
      color: $lightwhitetext;
      background: $blueclr;
    }
  }

  &.grey-btn {
    background: $greybtnbg;
    font-size: 12px;
    font-weight: 300;
    color: $lightwhitetext2;
    border: 1px solid $greybtnbg;
    height: 28px;
    padding: 5px 10px;
    width: 100px;

    &.active {
      font-weight: 700;
      color: $white;
      border-color: $blueclr;
      background: $navy_blue;
    }
  }

  .light_text {
    display: inline-block;
    font-weight: 300;
  }

  .Btn_Icon {
    display: none;
  }
}