@import "../../../../assets/theme/_var.scss";

.AccountUpdate_V2 {
  // .CircularProgressbar {
  // font-family: Georgia, "Times New Roman", Times, serif;
  // }
  .Ticker_data {
    width: 100%;

    ul {
      align-items: center;
      justify-content: space-between;
      width: 100%;

      li {
        margin-left: 15px;

        @media (max-width: 1499px) {
          margin-left: 5px;
        }

        &:first-child {
          margin-left: 0;
        }

        img {
          max-width: 28px;
        }

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @media (max-width: 1499px) {
            font-size: 10px;
            line-height: 18px;
          }
          @media (max-width: 1439px) {
            width: 50px;
          }
        }

        &.Hr_Pl {
          span {
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
          }

          p {
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
          }
        }
        &.countdown_timer {
          max-width: 28px;
        }
      }
    }
  }
}
