@import "../../../../assets/theme/_var.scss";


.Refferal_info_Banner {
    padding: 20px 0 0;
    text-align: left;
    position: relative;
    z-index: 1;
    // background: $gradient2;
    min-height: 346px;
    background-image: url("../../../../assets/images/admin/referral-banner-bg.svg");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;

    @media (max-width: 991px) {
        background-image: none;
        height: auto;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100%;
            background: $gradient1; 
            width: 100%;
            z-index: -1;
        }
    }

    @media (max-width: 767px) {
        padding: 30px 0;
    }

    .Refferal_Banner_content {

        h1 {
            font-size: 50px;
            font-weight: 200;
            margin-bottom: 18px;
            max-width: 450px;
            margin-bottom: 40px;
            margin-top: -15px;

            @media (max-width: 1199px) {
                font-size: 37px;
                margin-bottom: 30px;
            }

            @media (max-width: 991px) {
                margin: 0 auto;
                margin-top: 0;
                margin-bottom: 40px;
            }

            @media (max-width: 767px) {
                font-size: 37px;
                margin-bottom: 30px;
            }
        }


        em {
            font-weight: 400;
            font-family: $baseFont1;
        }
    }

    .Banner_coin {
        position: relative;
        top: 15px;

        @media (max-width:991px) {
            margin-top: 5px;
            top: 0;
        }

        @media (max-width:575px) {
            max-width: 227px;
            margin: 0 auto;
        }
    }

    .share_btn {
        margin-left: 12px;
        position: relative;

        @media (max-width:1199px) {
            margin-left: 0;
            margin-top: 10px;
        }

        @media (max-width:1199px) {
            margin-left: 10px;
            margin-top: 0px;
        }

        @media (max-width:575px) {
            margin-left: 0;
            margin-top: 10px;
        }

        &::after {
            content: "";
            display: inline-block;
            background-image: url("../../../../assets/images/admin/share_Icon.svg");
            background-size: 15px;
            width: 15px;
            height: 13px;
            background-repeat: no-repeat;
            transition: all ease-in-out 0.3s;
            margin-left: 8px;

            @media (max-width: 575px) {
                position: absolute;
                top: 50%;
                right: 33px;
                transform: translateY(-50%);
            }
        }

        &:hover {
            &::after {
                filter: brightness(0) invert(1);
            }
        }
    }

    .affilliate_btn {
        position: relative;
        padding: 10px 33px;

        @media (max-width:991px) {
            padding: 10px 20px;
        }

        &::after {
            content: "";
            display: inline-block;
            background-image: url("../../../../assets/images/copying_Icon.svg");
            background-size: 16px;
            width: 16px;
            height: 16px;
            background-repeat: no-repeat;
            transition: all ease-in-out 0.3s;
            margin-left: 8px;
            filter: brightness(0) invert(1);

            @media (max-width: 575px) {
                position: absolute;
                top: 50%;
                right: 33px;
                transform: translateY(-50%);
            }
        }
    }

    @media (max-width:575px) {
        .btn-primary {
            padding: 10px 15px;
            font-size: 14px;
            max-width: 100%;
            width: 100%;
            display: block;
            margin: 10px auto;
            height: 45px;
        }
    }

}

.Refferal_Sec {
    .Refferal_Row {
        display: flex;
        flex-wrap: wrap;

        h4 {
            font-weight: 700;
            font-size: 20px;
            color: $lightwhitetext;
            margin-bottom: 28px;

            @media (max-width: 767px) {
                margin-bottom: 25px;
                text-align: center;
            }
        }

        .Refferal_Account {
            width: 486px;

            @media (max-width: 1199px) {
                width: 100%;
            }

            .Refferal_Account_Box {
                background: $dashbg;
                border: 0.5px solid $borderColor1;
                border-radius: 10px;
                padding: 38px 29px 30px;
                position: relative;

                @media (max-width: 1199px) {
                    padding: 35px 20px 30px;
                }

                @media (max-width: 767px) {
                    padding: 30px 16px 22px;
                }

                .userIcon {
                    box-shadow: 0px 0 40px 1px #2c77ec;
                    border-radius: 100px;
                    background-color: $blueclr;
                    width: 68px;
                    height: 68px;

                    @media (max-width: 767px) {}
                }

                .Sapphire_text {
                    text-align: center;

                    .Sapphire_Icon {
                        width: 60px;
                        height: 44px;
                    }

                    span {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 14px;
                        color: $textGrey;
                        display: block;
                        margin-top: 12px;
                    }
                }

                h6 {
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 145%;
                    color: $whitelightclr1;
                    margin-bottom: 14px;

                    @media (max-width: 575px) {
                        margin-bottom: 10px;
                        font-size: 10px;
                    }
                }

                h3 {
                    font-weight: 700;
                    font-size: 26px;
                    color: $lightwhitetext;
                    margin-bottom: 0;

                    @media (max-width: 1199px) {
                        font-size: 20px;
                    }

                    @media (max-width: 575px) {
                        font-size: 16px;
                    }
                }

                h5 {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 14px;
                    color: $lightwhitetext;
                    margin-bottom: 0;
                    word-wrap: break-word;

                    @media (max-width: 1199px) {
                        font-size: 14px;
                    }

                    @media (max-width: 575px) {
                        font-size: 12px;
                    }
                }

                svg {
                    width: 15px;
                    height: 15px;
                    margin-left: 12px;
                    cursor: pointer;

                    path {
                        fill: $lightwhitetext;
                    }
                }

                .Refferal_Common_Row {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 -13px;
                    margin-bottom: 32px;

                    .Col_100 {
                        width: 100%;
                        padding: 0 13px;
                    }

                    .Col_50 {
                        width: 50%;
                        padding: 0 13px;

                        @media (max-width: 575px) {
                            width: 42%;
                        }
                    }

                    .Col_25 {
                        width: 25%;
                        padding: 0 13px;

                        @media (max-width: 575px) {
                            width: 29%;
                        }
                    }

                    @media (max-width: 767px) {
                        margin: 0 -7px;
                        margin-bottom: 25px;

                        .Col_100,
                        .Col_50,
                        .Col_25 {
                            padding: 0 7px;
                        }
                    }
                }

                .Stak_more_Btn {
                    @media (max-width: 575px) {
                        .btn-primary {
                            width: 100% !important;
                            height: 45px;
                            margin: 10px 0 0 !important;

                            &:first-child {
                                margin-top: 0 !important;
                            }
                        }
                    }
                }
            }
        }

        .Refferal_Affiliaties {
            width: calc(100% - 486px);
            padding-left: 100px;

            @media (max-width: 1199px) {
                width: 100%;
                margin-top: 40px;
                padding-left: 0px;
            }

            .Refferal_Table {

                &_Row {

                    @media (max-width: 767px) {
                        margin-top: 0px;
                        overflow-x: auto;
                    }

                    .Refferal_Table_Heading {
                        ul {
                            display: flex;
                            padding: 0 24px 7px;

                            @media (max-width: 767px) {
                                padding: 0 20px 7px;
                                width: 570px;
                            }

                            li {
                                width: 18.755%;
                                padding: 0 4px;

                                &:nth-child(5),
                                &:nth-child(6) {
                                    text-align: center;
                                    width: 12.555%;

                                    @media (max-width: 767px) {
                                        // width: 50%;
                                    }
                                }

                                span {
                                    font-weight: 200;
                                    font-size: 12px;
                                    color: $lightwhitetext;
                                }
                            }
                        }

                        @media (max-width: 767px) {
                            // display: none;
                        }
                    }

                    .Refferal_Table_Data {
                        ul {
                            display: flex;
                            border: 0.5px solid $borderColor1;
                            border-radius: 10px;
                            padding: 28px 20px;
                            margin-bottom: 16px;

                            @media (max-width: 767px) {
                                padding: 20px 20px;
                                // flex-wrap: wrap;
                                width: 570px;

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }

                            li {
                                width: 18.755%;
                                padding: 0 4px;

                                @media (max-width: 767px) {
                                    // display: flex;
                                    // justify-content: space-between;
                                    // align-items: center;
                                    // width: 50%;
                                    // padding: 7px 8px;
                                }

                                &:nth-child(5),
                                &:nth-child(6) {
                                    text-align: center;
                                    width: 12.555%;

                                    @media (max-width: 767px) {
                                        // width: 50%;
                                    }
                                }

                                span {
                                    font-weight: 700;
                                    font-size: 12px;
                                    line-height: 14px;
                                    color: $white;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    display: inherit;
                                }
                            }
                        }

                        .mobile_Content {
                            display: none;

                            @media (max-width:767px) {
                                // display: block;
                                // font-weight: 200;
                            }
                        }
                    }
                }
            }
        }
    }
}

.Tier_Unlock_Progress {
    background: $dashbg;
    border: 0.5px solid $borderColor1;
    border-radius: 10px;
    padding: 28px 23px;
    position: relative;
    margin-top: 18px;

    @media (max-width: 1199px) {
        padding: 35px 20px 30px;
    }

    @media (max-width: 767px) {
        padding: 30px 16px 22px;
    }

    h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        color: $white;
        text-align: center;
        margin-bottom: 52px;
    }

    ul {
        display: flex;
        text-align: center;
        justify-content: center;

        li {
            padding: 0 8px;
            width: 20%;
            cursor: pointer;

            .TierProgress_Icon {
                .Tier_Icon {
                    background-color: $darkblackBg;
                    width: 26px;
                    height: 26px;
                    border-radius: 80px;
                    display: block;
                    margin: 0 auto;
                }

                .Tier_img {
                    @media (max-width: 575px) {
                        width: 30px;
                    }
                }

                &.tier_blue,
                &.tier_yellow {
                    position: relative;
                    z-index: 1;

                    &::after {
                        content: "";
                        border: 2px solid $blueclr;
                        position: absolute;
                        height: 70px;
                        width: 70px;
                        border-radius: 1000px;
                        top: -23px;
                        left: 50%;
                        transform: translateX(-50%);
                        z-index: -1;

                        @media (max-width: 575px) {
                            height: 54px;
                            width: 54px;
                            top: -12px;
                        }
                    }
                }

                &.tier_yellow {
                    &::after {
                        border: 2px solid #DFB520;
                    }
                }
            }

            h6 {
                font-weight: 600;
                font-size: 14px;
                line-height: 180%;
                margin-bottom: 0;
                color: $whitelightclr;
                word-break: break-word;

                @media (max-width: 767px) {
                    font-size: 10px;
                }
            }

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 180%;
                color: $greybg3;

                @media (max-width: 767px) {
                    font-size: 10px;
                }
            }

            &:hover {

                h6,
                p {
                    color: $white;
                }
            }
        }
    }
}

.Admin_Modal {
    &.EhxStake_Modal {
        .modal-dialog {
            max-width: 762px;
        }

        .EhxStake_Modal_Content {
            overflow-x: auto;

            ul {
                border: 1px solid $greybg1;
                border-radius: 10px;
                padding: 18px 15px;
                margin-bottom: 15px;
                justify-content: space-between;

                &:last-child {
                    margin-bottom: 0;
                }

                p {
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 25px;

                    .text_blue {
                        font-weight: 600;
                    }
                }

                li {
                    padding: 0 5px;

                    .EhxStake_text {
                        padding-left: 12px;

                        p {
                            font-weight: 500;
                        }

                        span {
                            color: $textGrey4;
                        }
                    }

                    &:last-child {
                        text-align: right;
                    }
                }
            }

            .EhxStake_info {
                width: 650px;
            }
        }
    }
}