@import "../../../assets/theme/_var.scss";

.Mobile_view_design {
    padding: 0 10px 10px;

    .Mobile_Chart_Tabs {
        border: 0;
        padding-top: 4px;
        padding-bottom: 4px;

        &.nav-tabs {
            .nav-link {
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                color: $lightwhitetext;
                background-color: transparent;
                border: 0;
                padding: 0.5rem 2.4rem 0.5rem 0.5rem;

                &.active,
                &:hover,
                &:focus {
                    color: $blueclr;
                }
            }
        }
    }

    .buy_Sell_mobile_btns {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: $baseColor;
        padding: 15px 11px;
        z-index: 9;
        left: 0;

        &>div {
            padding: 0 4px;
        }

        .btn-primary {
            border-radius: 3px;
            height: 45px;
            font-size: 14px;
        }
    }

}