@import '../../assets/theme/_var.scss';

.tooltip {
  opacity: 1 !important;
  margin-right: 0;
  font-weight: 300;

  .tooltip-inner {
    color: $textGrey4 !important;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    text-align: left;
    padding: 16px 20px;
    background: $dropdwonBg;
    min-width: auto;
    border-radius: 0px;
    opacity: 1;
    text-align: center;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.5);

    &::after {
      color: $dropdwonBg !important;
      right: 16px;
      border-bottom: 8px solid $dropdwonBg;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      position: absolute;
      top: 0;
      content: "";
      display: none;
    }
  }

  .tooltip-arrow {
    &::before {
      border-bottom-color: $dropdwonBg;
    }
  }
}

.tooltip {
  &.Expires_tooltip {
    margin-right: -50px;

    .tooltip-inner {
      min-width: 217px;
    }
  }
}