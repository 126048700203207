@import "../../../../assets/theme/_var.scss";

.Margin_Ratio {
    border-top: 1px solid $borderColor3;
    padding-top: 26px;
    margin-top: 30px;

    &_List {
        margin-top: 14px;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 0;

            span {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: $white;                
            }

            h5 {
                margin-bottom: 0;
                display: flex;
                align-items: center;
                
                img {
                    margin-right: 17px;
                }
            }
        }
    }
}