@import "../../../../assets/theme/_var.scss";

.TradeChart {
  padding: 16px !important;

  @media (max-width: 767px) {
    padding: 10px !important;
  }

  .TradeChart_V2 {
    height: 100%;
    .Trading_Tabs {
      height: 100%;
      .Nav_Trading_Tabs {
        a {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: $greyclr;
          border: 1px solid transparent;

          @media (max-width: 767px) {
            font-size: 12px;
            line-height: 15px;
            padding: 6px 10px;
          }

          &.active {
            color: $white;
            background-color: $btnbghover;
            border-color: $blueclr;
          }
        }
      }

      .Content_Trading_Tabs {
        margin-top: 29px;
        height: 97.5%;
        .tab-pane {
          height: 97.5%;
          .tradingview-widget-container {
            height: 97.5%;
            #tv_chart_container {
              height: 97.5%;
              iframe {
                height: 97.5%;
              }
            }
            #tv_chart_container2 {
              height: 97.5%;
              iframe {
                height: 97.5%;
              }
            }
            #tv_chart_container_spot {
              height: 97.5%;
              iframe {
                height: 97.5%;
              }
            }
          }
        }
      }
    }
  }
}
