@import "../../../../assets/theme/_var.scss";

.UserId {
    &_Time {
        figure {
            width: 70px;
            height: 70px;
            background: $blueclr1;
            border-radius: 100px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
 
            svg {
                width: 17px;
                height: 22px;
            }
        }

        &_Rank {
            .TokenAdds {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: $white;
                padding-right: 30px;

                @media (max-width:767px) {
                    padding-right: 10px;
                    font-size: 14px;
                    ;
                    white-space: nowrap;
                }

            }

            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: $whitelightclr;
                padding-right: 30px;

                @media (max-width:767px) {
                    padding-right: 10px;
                    font-size: 14px;
                    ;
                }

                span {
                    color: $greybg3;
                }
            }

            h5 {
                font-size: 18px;
                line-height: 22px;
                text-transform: uppercase;
                font-weight: 500;
                margin-bottom: 0;
                display: flex;
                align-items: center;

                @media (max-width:767px) {
                    font-size: 14px;
                    ;
                    white-space: nowrap;
                }

                span {
                    color: $greybg3;
                    font-weight: 300;
                    padding-right: 3px;
                }

                svg {
                    margin-left: 12px;
                    width: 9px;
                    height: 16px;

                    path {
                        stroke: $blueclr;
                    }
                }

                img {
                    margin-right: 14px;
                }
            }
        }

        &_LastLogin {
            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
            }
        }
    }
}

.Dash_SearchBar {
    max-width: 400px;
    width: 100%;

    .searchBar_input {
        position: relative;
        margin-left: 20px;
        width: 100%;

        @media (max-width:767px) {
            margin-left: 0;
            margin-top: 8px;
        }

        .control_searchBar {
            background: $modalBgclr;
            border-color: $borderColor;
            padding-right: 40px;
            width: 100%;
        }

        svg {
            position: absolute;
            right: 18px;
            top: 12px;
            cursor: pointer;
        }
    }
}