@import "../../../../assets/theme/_var.scss";

.Topbar_heading {
    font-weight: 700;
    font-size: 38px;
    line-height: 130%;
    margin-bottom: 0;
    color: $white;

    @media (max-width:1199px) {
        font-size: 25px;
    }

    @media (max-width:767px) {
        font-size: 22px;
    }
}