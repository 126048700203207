@import "../../assets/theme/_var.scss";

.AdminMain_Layout {
    display: flex;
    flex-wrap: wrap;

    .Admin_Sidebar {
        width: 390px;
        background-color: $baseColor;

        @media (max-width:1599px) {
            width: 250px;
        }

        @media (max-width:1365px) {
            width: 230px;
        }

        @media (max-width:991px) {
            position: fixed;
            left: -280px;
            z-index: 999;
            width: 280px;
            overflow-y: auto;
            top: 0;
            height: 100%;
            padding-top: 50px;
            transition: all ease-in-out 0.3s;
        }
    }

    .Admin_Content_layout {
        width: calc(100% - 390px);
        background-color: $dashbg;

        @media (max-width:1599px) {
            width: calc(100% - 250px);
        }

        @media (max-width:1365px) {
            width: calc(100% - 230px);
        }

        @media (max-width:991px) {
            width: 100%;
        }

        .Common_TopBar {
            background-image: $gradientgreylefttoright;
            padding: 35px 28px;

            @media (max-width:1199px) {
                padding: 20px 20px;
            }

            .exportBtn.btn.btn-primary {
                display: flex;
                flex-direction: row-reverse;

                .btn_icon {
                    margin-right: 0;
                    margin-left: 10px;
                }

                &:hover {
                    .btn_icon {
                        filter: brightness(0) invert(1);
                    }
                }
            }
        }

        .Common_admin_Contentarea {
            padding: 32px 28px 70px;

            @media (max-width:1199px) {
                padding: 20px 15px 30px;
            }
        }
    }

    ::-webkit-scrollbar {
        width: 5px !important;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: $blueclr;
        border-radius: 10px;
    }
}

.MenuToggle {
    display: none;
    cursor: pointer;
    padding: 10px 15px;

    @media (max-width:991px) {
        display: block;
    }

    span {
        background: #fff;
        border-radius: 10px;
        height: 2px;
        width: 28px;
        margin: 7px 0;
        transition: .4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
        display: block;

    }

    span:nth-of-type(2) {
        width: 22px;
    }

}

.Open_Sidebar {
    .Admin_Sidebar {
        @media (max-width:991px) {
            left: 0;
        }
    }
}

.Recent_Transaction {
    margin-top: 28px;

    &_Table {
        max-height: 220px;
        margin-top: 15px;
        overflow-y: auto;
        padding-right: 22px;

        &::-webkit-scrollbar {
            width: 5px !important; 
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $borderColor3;
            border-radius: 10px;
        }

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 29px;

            &:last-child {
                margin-bottom: 0;
            }

            h5 {
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                margin-bottom: 4px;
            }

            p {
                font-weight: 400;
                font-size: 10px;
                line-height: 12px;
                color: $greybg3;
            }
        }

        &_Deposit {
            display: flex;
            align-items: center;

            .LineChar_icon {
                background-color: $hidebtnbg;
                padding: 6px;
                margin-right: 15px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                height: 24px;
                width: 24px;
            }
        }

        &_UstToekn {
            text-align: right;

            .GreenDot {
                width: 6px;
                height: 6px;
                background-color: $greenclr;
                border-radius: 100px;
                display: inline-block;
                margin-right: 4px;
            }
        }
    }
}

