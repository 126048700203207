@import "../../../../../assets/theme/_var.scss";

.withdraw_modal.modal {
    .modal-dialog {
        max-width: 600px;

        .modal-content {
            .modal-header {
                padding: 20px 34px;

                .btn-close {
                    right: 45px;
                }
            }

            .modal-body {
                padding: 20px 34px;

                .Input_Group {
                    .input-type2 {
                        height: 60px;
                        padding: 8px 25px;
                    }

                    label {
                        span {
                            font-weight: 700;
                            color: #777ea1;
                        }
                    }
                }

                button.btn-primary {
                    height: 60px;
                    border-radius: 8px;

                    @media (max-width: 767px) {
                        height: 45px;
                    }
                }

                h6 {
                    color: $greybg3;
                    margin-bottom: 0;
                    line-height: 24px;
                }

                .error_msg {
                    background-color: $redbgclr;
                    padding: 12px 32px;
                }
            }
        }
    }

    .span-max-2 {
        float: right;
        position: absolute;
        padding: 0px;
        margin-left: -43px;
        margin-top: 19px;
        cursor: pointer;
        color: $blueclr;
        .selectedCoin {
            margin-left: 5px;
            color: $white;
        }
    }
}
