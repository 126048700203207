@import "../../../../../assets/theme/_var.scss";

.FaQ {
    &_Tabs {
        @media (max-width:767px) {
            white-space: nowrap;
            flex-wrap: nowrap;
            overflow-x: auto;
        }

        .nav-item {
            margin-bottom: 10px;

            @media (max-width:767px) {
                margin-bottom: 0;
                margin-right: 10px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            a {
                background-color: transparent;
                padding: 10px 0;
                color: $textGrey6;
                font-size: 18px;
                font-weight: 500;
                line-height: 27px;
                position: relative;

                @media (max-width:1199px) {
                    font-size: 16px;
                    line-height: 25px;
                }

                &::after {
                    content: "";
                    background-image: url("../../../../../assets/icons/bigarrow.svg");
                    width: 14px;
                    height: 10px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 14px;
                    display: inline-block;
                    margin-left: 0px;
                    transition: all ease-in-out 0.3s;
                    opacity: 0;
                }

                &.active {
                    background-color: transparent;
                    color: $white;

                    &::after {
                        opacity: 1;
                        margin-left: 8px;
                    }
                }
            }
        }
    }

    &_Content {
        @media (max-width:767px) {
            margin-top: 30px;
        }
        &_Tabs {           

            h2 {
                font-size: 34px;
                font-weight: 600;
                line-height: 41px;
                color: $white;

                @media (max-width:1199px) {
                    font-size: 25px;
                    line-height: 35px;
                }
            }

            ul {
                margin-top: 20px;

                li {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 25px;
                    color: $white;
                    margin-bottom: 10px;
                    padding: 15px 0;

                    @media (max-width:1199px) {
                        font-size: 16px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    img {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}