@import "../../../../../assets/theme/_var.scss";

.Trade_Chart {
    height: 670px;

    @media (max-width:767px) {
        height: 350px;
    }
}

.cardStyle.TradeChart.card {

    @media (max-width:767px) {
        height: 600px;
    }
}

.tv_chart_container {
    min-height: 100%;
}