@import "../../../../../assets/theme/_var.scss";

.Dashboard_Slider {
    padding: 80px 0;

    .slick {
        &-list {
            background-color: #181B2C;
            border-radius: 30px;
            min-height: 330px;
            position: relative;
            z-index: 1;

            &::before {
                content: "";
                background-image: url("../../../../../assets/images/admin/Asset-bg.svg");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: left bottom;
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                bottom: 0;
                opacity: 0.15;
                z-index: -1;
            }

            .Slider {
                &_Box {
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    min-height: 330px;
                    position: relative;
                    z-index: 1;

                    @media (max-width:991px) {
                        flex-direction: column;
                        min-height: 400px;
                    }

                    &::after {
                        content: "";
                        background-image: url("../../../../../assets/images/admin/candlestick-pattern.svg");
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: right center;
                        position: absolute;
                        height: 100%;
                        width: 40%;
                        right: 0px;
                        bottom: 0;
                        opacity: 1;
                        z-index: -1;

                        @media (max-width:991px) {
                            display: none;
                        }
                    }
                }

                &_One {
                    &::after {
                        content: "";
                        background-image: url("../../../../../assets/images/admin/candlestick-pattern.svg");
                    }
                }
                &_Two {
                    &::after {
                        content: "";
                        background-image: url("../../../../../assets/images/admin/candlestick-pattern.svg");
                    }
                }
                &_Three {
                    &::after {
                        content: "";
                        background-image: url("../../../../../assets/images/admin/candlestick-pattern.svg");
                    }
                }
                &_four {
                    &::after {
                        content: "";
                        background-image: url("../../../../../assets/images/admin/candlestick-pattern.svg");
                    }
                }
                &_five {
                    &::after {
                        content: "";
                        background-image: url("../../../../../assets/images/admin/candlestick-pattern.svg");
                    }
                }

                &_logo {
                    position: absolute;
                    top: 40px;
                    left: 40px;
                    width: 235px;

                    @media (max-width:991px) {
                        position: static;
                        margin-bottom: 30px;
                    }
                }

                &_Content {
                    h3 {
                        color: $white ;
                        font-size: 40px;
                        font-weight: 700;
                        letter-spacing: -0.88px;

                        @media (max-width:1199px) {
                            font-size: 30px;
                        }

                        @media (max-width:767px) {
                            font-size: 25px;
                        }
                    }

                    h2 {
                        background: linear-gradient(to right, #ffffff 0%, #BEE0FF 0%, #0D2893 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-size: 50px;
                        font-weight: 700;
                        letter-spacing: -1.08px;
                        margin-bottom: 20px;

                        @media (max-width:1199px) {
                            font-size: 35px;
                        }

                        @media (max-width:767px) {
                            font-size: 28px;
                        }
                    }
                }
            }
        }

        &-dots {
            position: static;
            bottom: 0;
            margin-top: 50px;

            li {
                width: auto;
                height: auto;

                button {
                    width: 14px;
                    height: 14px;
                    padding: 0;
                    margin: 0;
                    background-color: rgba(255, 255, 255, 0.24);
                    border: 0;
                    border-radius: 30px;
                    transition: all ease-in-out 0.3s;

                    &:before {
                        display: none;
                    }
                }

                &.slick-active {
                    button {
                        width: 28px;
                        background-color: $blueclr1;
                    }
                }

            }
        }
    }
}