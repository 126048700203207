@import "../../../../../assets/theme/_var.scss";

.Position_Table_card {
    max-height: 350px;
    overflow-y: auto;
    padding: 0 18px;

    @media (max-width: 1199px) {
        padding: 0 10px;
        max-height: 305px;
    }

    &::-webkit-scrollbar {
        width: 12px !important;
        cursor: pointer !important;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $blueclr;
        border-radius: 10px;
        cursor: pointer;
    }
}

.Position_Card {
    margin-bottom: 18px;

    &:last-child {
        margin-bottom: 0;
    }

    @media (max-width: 1599px) {
        padding-right: 10px;
    }

    @media (max-width: 767px) {
        padding-right: 0px;
    }

    &_Box {
        background: $gradientgreen;
        padding: 18px 35px;
        border-radius: 14px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @media (max-width: 1599px) {
            padding: 18px 20px;
        }

        @media (max-width: 767px) {
            padding: 15px;
        }

        &.Gradient_Red {
            background: $gradientred;
        }
    }

    &_Tabledata {
        width: calc(100% - 215px);

        @media (max-width: 1599px) {
            width: calc(100% - 160px);
        }

        @media (max-width: 1365px) {
            width: calc(100% - 140px);
        }

        @media (max-width: 1199px) {
            width: 100%;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -5px;
            padding-bottom: 18px;

            @media (min-width: 768px) {
                &:last-child {
                    padding-bottom: 0;
                }
            }

            li {
                width: 23.33%;
                padding: 0 5px;

                &:first-child {
                    width: 30%;
                }

                @media (max-width: 767px) {
                    width: 50%;
                    // border: 1px solid $borderColor;
                    padding: 6px 0;

                    &:first-child {
                        width: 50%;
                    }
                }

                h4 {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: $white;
                    margin-bottom: 0;

                    @media (max-width: 1365px) {
                        font-size: 14px;
                        line-height: 22px;
                    }

                    @media (max-width: 767px) {
                        font-size: 11px;
                        line-height: 18px;
                    }

                    .long_btn,
                    .Short_btn {
                        margin-left: 8px;
                    }
                }

                .long_btn,
                .Btc_btn,
                .Short_btn {
                    background-color: $greenbtnclr;
                    min-width: 31px;
                    height: 20px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    font-weight: 700;
                    font-size: 8px;
                    line-height: 20px;
                    color: $white;
                    text-transform: uppercase;
                    padding: 1px 4px;
                }

                .Short_btn {
                    background-color: $redclr;
                }

                .Btc_btn {
                    background-color: $borderColor1;
                }

                h6 {
                    padding-left: 32px;

                    @media (max-width: 767px) {
                        padding-left: 0px;
                    }

                    span {
                        color: $white;
                    }
                }

                h3 {
                    padding-left: 32px;

                    @media (max-width: 1499px) {
                        font-size: 12px;
                        line-height: 15px;
                    }

                    @media (max-width: 767px) {
                        padding-left: 0px;
                    }
                }

                h6,
                h5,
                p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 24px;
                    color: $greybg3;
                    margin-bottom: 0;
                }

                p {
                    color: $textGrey;
                }
            }

            &.Perpetual_Pnl {
                li {
                    h4 {
                        display: flex;
                        align-items: center;
                        // margin-left: -32px;

                        img {
                            margin-right: 10px;
                            width: 20px;
                        }
                    }
                }
            }
        }
    }

    &_Btns {
        width: 215px;
        padding-left: 10px;

        @media (max-width: 1599px) {
            width: 160px;
        }

        @media (max-width: 1365px) {
            width: 140px;
        }

        @media (max-width: 1199px) {
            width: 100%;
            padding-left: 0;
            display: flex;
            margin-top: 10px;
        }

        @media (max-width: 767px) {
            display: block;
        }

        .grey-border-btn {
            border-radius: 8px;
            padding-left: 10px;
            padding-right: 10px;
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            height: 38px;
            width: 100%;
            margin-bottom: 8px;

            @media (max-width: 1199px) {
                margin: 0 3px;
            }

            @media screen and (max-width: 767px) {
                margin: 5px 3px;
            }
            &:last-child {
                margin-bottom: 0;
            }

            .light_text {
                color: $redclr;
                padding-left: 5px;
                font-weight: 500;
            }

            &.text_red {
                color: $redclr1 !important;
            }

            &.text_green {
                color: $greenclr !important;
            }
        }
    }
    .Position_Card_Box {
        &.desktopView {
            overflow: hidden;
            padding: 15px;
            .Position_Card_Tabledata {
                width: calc(100% - 420px);
                overflow-x: auto;
                @media screen and (min-width: 1980px) {
                    width: calc(100% - 480px);
                }
                &::-webkit-scrollbar {
                    width: 6px;
                    height: 6px;
                }

                &::-webkit-scrollbar-track {
                    background: $borderColor1;
                    border-radius: 12px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $blueclr;
                    border-radius: 10px;
                    border: 1px solid $borderColor1;
                    user-select: none;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background-color: $blueclr;
                }
                ul {
                    display: flex;
                    min-width: max-content;
                    column-gap: 40px;
                    flex-direction: row;
                    flex-wrap: wrap;
                    overflow-x: auto;
                    @media screen and (min-width: 1980px) {
                        min-width: 100%;
                        width: 100%;
                    }
                    @media screen and (max-width: 1440px) {
                        column-gap: 25px;
                    }
                    li {
                        flex: 0 0 calc(12.5% - 15px);
                        max-width: fit-content;
                        padding: 0;
                        @media screen and (min-width: 1980px) {
                            flex: 0 0 calc(12.5% - 45px);
                            max-width: none;
                            width: 12.5%;
                        }
                        &:first-child {
                            padding-left: 5px;
                            padding-right: 20px;
                            min-width: 180px;
                        }
                        &.liquiedPrice {
                            min-width: 100px;
                        }
                        h1,
                        h2,
                        h3,
                        h4,
                        h5,
                        h6 {
                            padding: 0;
                        }
                    }
                }
            }
            .Position_Card_Btns {
                display: flex;
                column-gap: 10px;
                width: 420px;
                @media screen and (min-width: 1980px) {
                    width: 480px;
                }
                .grey-border-btn {
                    font-size: 10px;
                    line-height: 1.2;
                }
            }
        }
    }
}
.no_data {
    position: absolute;
    // left: 50%;
    // margin: auto;
    // transform: translate(-50%);
    // top: 0;
    text-align: center;
    margin: 0 auto;
    color: $white;
    width: 100%;
    // opacity: 0;

    @media (max-width: 991px) {
        position: static;
        transform: unset;
        min-height: auto;
    }

    td {
        padding: 0;
        border: 0;
        font-size: 13px;

        @media (max-width: 991px) {
            font-size: 11px;
            width: 100%;
            text-align: center;
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            margin: auto;
        }
    }
}
