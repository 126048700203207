@import "../../../assets/theme/_var.scss";
@import "../../../assets/theme/_mixins.scss"; // common classes

.V2_Exchange_Layout {
    @media (max-width:1023px) {
        padding-bottom: 60px;
    }

    .V2_Exchange_Wrapper {
        margin-top: 4px;
        padding-bottom: 45px;

        // @media (min-width:1024px) {
        //     padding-bottom: 46px;
        // }

        @media (max-width:1023px) {
            margin-top: 10px;
        }

        .cardStyle {
            &.OrderBook {
                overflow-y: hidden;
            }
            background-color: $baseColor;
            border: 1px solid $borderColor;
            margin: 0;
            border-radius: 5px;

            @media (max-width:1023px) {
                margin-bottom: 10px;

                .card_body_style {
                    padding: 0;
                }
            }
        }
    }
}

.cardStyle {
    padding: 6px 16px;

    @media (max-width:1023px) {
        padding: 6px 16px;

        .card_body_style {
            padding: 0;
        }
    }
}