@import "../../../../../assets/theme/_var.scss";

.Order_Details {
    margin-top: 35px;
    margin-bottom: 22px;

    &_Table {
        border: 1px solid $borderColor3;
        border-radius: 8px;
        margin-top: 18px;

        li {
            border-bottom: 1px solid $borderColor3;
            padding: 10px 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width:1199px) {
                padding: 10px 10px;
            }

            &:last-child {
                border-bottom: 0;
            }

            span {
                font-weight: 300;
                font-size: 12px;
                line-height: 22px;
                color: $greybg3;
            }
        }
    }
}