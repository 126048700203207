@import "../../../../../assets/theme/_var.scss";

.Assets_Overview {
    background-color: $cardbg;

    &_Header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 25px;

        @media (max-width: 1365px) {
            padding: 12px 15px;
        }

        @media (max-width: 1199px) {
            display: block;
            padding: 12px 10px;
        }

        h3 {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;

            @media (max-width: 1365px) {
                font-size: 16px;
            }
        }

        &_Btns {
            display: flex;
            align-items: center;

            @media (max-width: 1199px) {
                margin-top: 20px;
            }

            .btn-primary {
                margin-right: 16px;

                @media (max-width: 1365px) {
                    margin-right: 10px;
                }
            }

            .Assets_Arrow {
                padding-left: 15px;

                svg {
                    width: 9px;
                    height: 16px;
                }
            }
        }
    }

    &_Tabs {
        .Bottom_Border_Tabs {
            border-bottom: 1px solid $borderColor;
            margin-bottom: 0;

            .nav-item {
                .nav-link {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;

                    @media (max-width: 1599px) {
                        padding: 12px 16px;
                    }

                    &::after {
                        height: 1px;
                    }
                }
            }
        }

        .tab-content {
            padding: 28px;

            @media (max-width: 1599px) {
                padding: 25px 15px;
            }

            .Spot_Account {
                h6 {
                    margin-bottom: 0;
                }

                .hide_Blnc {
                    padding: 6px 9px;
                    background-color: $hidebtnbg;
                    border-radius: 4px;
                    margin-left: 10px;
                    cursor: pointer;
                }

                h5 {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 1.5;
                    margin-bottom: 0;

                    @media (max-width: 1365px) {
                        font-size: 16px;
                    }
                    .convertedAmount {
                        color: $textGrey1;
                        display: inline-block;
                    }
                }

                h4 {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 0;

                    @media (max-width: 1599px) {
                        font-size: 16px;
                        line-height: 20px;
                    }

                    @media (max-width: 1365px) {
                        font-size: 13px;
                        line-height: 17px;
                    }
                }

                .common_select {
                    margin: 0 14px;

                    .react-select__control {
                        .react-select__single-value {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                        }

                        .react-select__indicators {
                            margin-left: 14px;

                            @media (max-width: 1365px) {
                                margin-left: 7px;
                            }

                            .react-select__indicator {
                                width: 24px;
                                height: 24px;
                                background-color: $hidebtnbg;
                                border-radius: 4px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                }
            }
        }
    }
}

.Recent_Transaction {
    margin-top: 28px;

    &_Table {
        max-height: 220px;
        margin-top: 20px;
        overflow-y: auto;
        padding-right: 22px;

        &::-webkit-scrollbar {
            width: 5px !important;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $borderColor3;
            border-radius: 10px;
        }

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 29px;

            &:last-child {
                margin-bottom: 0;
            }

            h5 {
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                margin-bottom: 4px;
            }

            p {
                font-weight: 400;
                font-size: 10px;
                line-height: 12px;
                color: $greybg3;
            }
        }

        &_Deposit {
            display: flex;
            align-items: center;

            .LineChar_icon {
                background-color: $hidebtnbg;
                padding: 6px;
                margin-right: 15px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                height: 24px;
                width: 24px;
            }
        }

        &_UstToekn {
            text-align: right;

            .GreenDot {
                width: 6px;
                height: 6px;
                background-color: $greenclr;
                border-radius: 100px;
                display: inline-block;
                margin-right: 4px;
            }
        }
    }
}

.Overview_Heading {
    h6 {
        margin-bottom: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $white;
    }

    .btn-primary {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        height: 24px;
    }
}

.Assets_Progressbar {
    max-width: 225px;
    margin: 0 auto;

    &_tabs,
    &_Value {
        background-color: $borderColor3;
        padding: 12px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            justify-content: center;
            color: $white;
            border-radius: 100px;
            padding: 8px 10px;
            cursor: pointer;
            transition: all ease-in-out 0.3s;
            margin-right: 6px;

            &:last-child {
                margin-right: 0;
            }

            &:hover,
            &.active {
                background-color: $blueclr2;
                color: $white;
            }
        }
    }

    .ProgressBar {
        margin: 12px auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 220px !important;

        .ProgressBar_value {
            position: absolute;

            h6 {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                text-align: center;

                span {
                    display: block;
                    margin-top: 4px;
                    font-weight: 400;
                }
            }
        }
    }
}

.Refer_Code {
    &_Box {
        background: $gradientblueclr;
        border-radius: 4px 4px 0px 0px;
        padding: 25px 25px 32px;
        text-align: center;

        h3 {
            font-weight: 500;
            font-size: 28px;
            line-height: 120%;

            @media (max-width: 1365px) {
                font-size: 22px;
            }

            em {
                font-family: $baseFont1;
            }
        }
    }

    &_Join {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $bgclr;
        padding: 28px;
        border-bottom: 2px solid $blueclr1;

        @media (max-width: 1599px) {
            padding: 25px 15px;
        }

        img {
            width: 34px;
        }

        h4 {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            margin: 0 20px;
            text-transform: uppercase;

            @media (max-width: 1599px) {
                font-size: 20px;
                line-height: 25px;
                margin: 0 15px;
            }

            @media (max-width: 1365px) {
                font-size: 18px;
                line-height: 23px;
            }
        }

        svg {
            width: 9px;
            height: 16px;
        }
    }

    &_Newscontent {
        padding-top: 52px;

        .Refer_News {
            display: flex;
            align-items: center;
            padding: 0 30px;

            img {
                width: 28px;
            }

            h4 {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                margin: 0 14px;
                text-transform: uppercase;

                @media (max-width: 1365px) {
                    font-size: 16px;
                    line-height: 20px;
                }
            }

            svg {
                width: 7px;
                height: 12px;
            }
        }

        .News_List {
            li {
                border-bottom: 1px solid $borderColor;
                padding: 24px 30px;

                // &:last-child {
                //     padding-bottom: 0;
                //     border-bottom: 0;
                // }

                p {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 26px;
                    color: $white;
                }
            }
        }
    }
}
