@import "../../../../assets/theme/_var.scss";


.Account {
    &_Statement {
        &_Content {
            .Transaction_History_selectBox {
                .Search_btn {
                    margin-top: 36px;
                }
            }

            &_Total {
                padding: 36px 0;
                border-bottom: 1px solid $greybg;

                &:last-child {
                    padding-bottom: 0;
                    border-bottom: 0;
                }

                h3 {
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 28px;
                    color: $white;
                }

                .overview_data {
                    li {
                        margin-right: 38px;

                        @media (max-width:767px) {
                            margin-right: 20px;
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        span {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 28px;
                            color: $greybg3;
                            display: block;
                            margin-bottom: 4px;
                        }

                        h5, h6 {
                            font-weight: 500;
                        }
                    }
                }

                &_Value {
                    h5 {
                        font-weight: 500;
                    }
                    h3 {
                        font-weight: 700;
                        font-size: 28px;
                        line-height: 130%;
                        color: $white;
                        margin-bottom: 0;
                        margin-top: 20px;
                
                        @media (max-width:1199px) {
                            font-size: 25px;
                        }
                
                        @media (max-width:991px) {
                            font-size: 20px;
                        }
                
                        @media (max-width:767px) {
                            font-size: 16px;
                        }
                
                        span {
                            color: $greyclr;
                        }
                    }
                }
            }
        }
    }
}