@import "../../assets/theme/_var.scss";

.ExFooter {
  border-top: 1px solid $borderColor1;
    position: fixed;
    bottom: 0;
    width: calc(100% - 16px);
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    border-left: 1px solid $borderColor1;
    background: #0e1019;
    border-right: 1px solid $borderColor1;
    z-index: 99;
    @media screen and (max-width: 767px) {
      position: static;
      transform: none;
      width: 100%;
    }
  // @media (min-width:1024px) {
  //   position: fixed;
  //   z-index: 9;
  //   bottom: 0;
  //   width: 100%;
  //   background-color: $baseColor;
  // }

  .Stable_Connection {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #10B981;

    @media (max-width:1023px) {
      display: none;
    }

    span {
      background: #6EE7B7;
      box-shadow: 0px 0px 8px #02DB93;
      width: 8px;
      height: 8px;
      border-radius: 20px;
      display: inline-block;
      margin-right: 10px;
    }
  }

  .Exchange_footer_links {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width:1023px) {
      justify-content: center;
      flex-wrap: wrap;
    }

    a {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: $textGrey5;
      padding: 14px 28px;
      display: flex;
      align-items: center;
      border-left: 1px solid $borderColor1;
      transition: all ease-in-out 0.3s;

      @media (max-width:1023px) {
        padding: 14px 10px;
        border: 0;
        width: 33.33%;
      }

      &:hover {
        color: $blueclr1;

        svg {
          path {
            fill: $blueclr1;
          }
        }
      }

      span {
        margin-right: 10px;
      }
    }
  }
}