@import "../../../../../assets/theme/_var.scss";

.position_history {
  max-height: 330px;

  &::-webkit-scrollbar {
    width: 10px !important;
    height: 5px !important;
  }

  &_Table {
    overflow-x: hidden;

    ul {
      padding: 20px 0;

      li {
        padding-bottom: 20px;

        &:last-child {
          padding-bottom: 0;
        }

        .token_content {
          h6 {
            margin-bottom: 0;
            margin: 0 15px;
            font-size: 12px;
            font-weight: 600;
            line-height: normal;
          }

          p {
            color: $greybg3;
          }
        }

        p {
          color: $white;
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
        }

        .open_date {
          color: $greybg3;

          strong {
            color: $white;
            margin-right: 5px;
          }
        }

        .open_date_status {
          strong {
            margin-right: 5px;
          }
        }

        .token_pnl {
          font-size: 14px;
        }

        .closetext {
          color: $white;
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          @media (max-width:575px) {
            justify-content: flex-start;
          }

          .cus_radio {
            display: inline-block;
            width: 15px;
            height: 15px;
            border: 1px solid $radioborderclr;
            border-radius: 50px;
            margin-right: 14px;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              width: 8px;
              height: 8px;
              background-color: $greybg3;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              border-radius: 50px;
            }
          }
        }

      }
    }
  }
}