@import "../../assets/theme/_var.scss";

.confirm_modal.modal.show {
  background-color: rgba(0, 0, 0, 0.6);
}

.confirm_modal {
  &.modal {
    .modal-dialog {
      max-width: 340px;

      .modal-body {
        padding-bottom: 30px;
      }
    }
  }

  &__content {
    border-bottom: 1px solid $borderColor1;
    padding-top: 15px;

    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 28px;
      &:not(:last-child) {
        align-items: center;
      }
    }

    &--loading {
      padding-bottom: 38px;
      p {
        font-size: 13px;
        font-weight: 700;
      }
    }
  }

  &__check {
    width: 30px;
    height: 30px;
    margin-right: 12px;
    flex-shrink: 0;
  }

  &__text {
    font-weight: 700;
    &--confirm {
      p {
        &:first-child {
          margin-bottom: 10px;
        }
        &:last-child {
          font-weight: 400;
        }
      }
    }
  }

  &__status {
    font-size: 11px;
    font-weight: 400;
    margin: 23px 0;
    span {
      &:first-child {
        color: $textGreynew;
      }
      &:last-child {
        font-weight: 700;
      }
    }
  }
  &__loading {
    p {
      font-weight: 700;
    }
  }
}

.loader {
  margin: 22px 0;
  .dbl-spinner {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid transparent;
    border-top: 2px solid $textBlue;
    border-left: 2px solid $textBlue;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
  }

  &--inner {
    margin: 0;
    .dbl-spinner {
      width: 28px;
      height: 28px;
    }
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
