.favourite-card-body {
    background-color: #080a12;
    border: 1px solid #262a39;
    margin: 0;
    border-radius: 5px;
    height: 30px !important;
}
.favorite-main {
    height: 30px;
    .fav-left {
        align-items: center;
        display: flex;
        height: 30px;
        justify-content: center;
        padding: 0;
        text-align: center;
    }
    .custom-scroll-btn {
        &.left {
            left: 0;
            right: auto;
            svg {
                transform: rotate(180deg);
            }
        }
        &.right {
            right: 0;
            left: auto;
        }
        position: absolute;
        top: 0;
        background: transparent;
        border: none;
        transform: translateY(-50%);
        top: 50%;
        width: 12px;
        background-color: #141416;
        height: 100%;
        line-height: 1;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            height: 12px;
            width: 12px;
        }
    }
    .favourite-scroll-section-right {
        flex-basis: calc(100% - 15px);
        flex-grow: 0;
        flex-shrink: 0;
        max-width: calc(100% - 15px);
        .favourite-scroll-main {
            height: 30px;
            max-width: 100%;
            position: relative;
            padding: 0 10px;
            .scroll-controller {
                align-items: center;
                display: flex;
                padding: 0;
                scroll-behavior: smooth;
                overflow-y: hidden;
                overflow-x: auto;
                white-space: nowrap;               
                .favourite-item {
                    align-items: center;
                    background: #0000;
                    border: 0;
                    border-radius: 0;
                    display: inline-flex;
                    justify-content: center;
                    padding: 6px 10px;
                    transition: all 0.3s ease-in-out;
                    cursor: pointer;
                    .favourite-item-symbol {
                        color: #8f9195;
                        font-size: 12px;
                        margin-right: 4px;
                        @media screen and (max-width: 1299px) {
                            font-size: 10px;
                        }
                    }
                    .favourite-item-symbol + span {
                        color: #fff;
                        font-size: 12px;
                        font-weight: 700;
                        @media screen and (max-width: 1299px) {
                            font-size: 10px;
                        }
                    }
                    .favAskRed {
                        color: #d2394e !important;
                    }
                    .favGreen {
                        color: #46a797 !important;
                    }
                }
            }
        }
    }
}
button.favourite-item.dragged {
    background: rgba(0, 0, 0);
    border: none;
    color: #fff;
    font-size: 10px;
    box-shadow: 0px 0px 4px 0px #ddd;
}