@import "../../../../assets/theme/_var.scss";

.Naviblue_Card {
    background-color: rgba(23, 69, 245, 0.18);
    padding: 12px 20px;
    margin-bottom: 28px;

    @media (max-width:767px) {
        margin-bottom: 20px;
        padding: 12px 15px;
    }
}

.future_assets_content {
    .Spot_blnc_Card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 20px;

        @media (max-width:767px) {
            width: 100%;
        }
    }

    .Spot_Pl {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width:575px) {
            width: 100%;
        }

        h2 {
            img {
                width: 33px;
            }
        }
    }
}

.Futures_Tabs_Container {
    margin-top: 38px;

    @media (max-width:767px) {
        margin-top: 25px;
    }

    .tab-content {
        margin-top: 36px;

        @media (max-width:767px) {
            margin-top: 20px;
        }
    }
}
.ordersExport{
    .dropdown-toggle {
        padding: 0;
        &:after {
            display: none !important;
        }
    }
    .dropdown-menu {
        width: 150px;
        top: 46px;
        padding: 0;
        background-color: $darkblackBg;
        box-shadow: 0px 6px 20px 0px #000000b0;
        text-align: center;
        li {
            &:hover {
                background-color: transparent;
            }
        }
        .export_list {
            ul {
                li {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 22px;
                    color: $white;
                    padding: 15px 15px;
                    position: relative;
                    display: block;
                    cursor: pointer;
                    border-bottom: 1px solid #1f2333;
                    &:last-child {
                        margin-bottom: 0;
                        border: none;
                    }

                    span {
                        padding-left: 13px;
                    }

                    svg {
                        width: 24px;
                    }

                    &.active,
                    &:hover {
                        background-color: $greybg4;
                        color: $white;
                        border-radius: 4px;

                        svg {
                            width: 24px;

                            path {
                                fill: $blueclr1;
                            }
                        }
                    }
                }
            }
        }
    }
}