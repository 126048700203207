@import "../../assets/theme/_var.scss";

.Futures_Chat_Room.offcanvas.offcanvas.offcanvas-bottom {
    max-width: 820px;
    width: 100%;
    right: 0;
    left: auto;
    background-color: $chatbgclr;
    max-height: 518px;
    height: 100%;
    transition: all ease-in-out 0.3s;

    .offcanvas-header {
        padding: 0;

        .btn-close {
            filter: brightness(1) invert(1);
            position: absolute;
            right: 25px;
            top: 27px;
            z-index: 1;

            &:focus {
                box-shadow: none;
            }
        }
    }

    .offcanvas-body {
        padding: 0;

        .Chat_User {
            display: flex;
            flex-wrap: wrap;

            &_List {
                width: 340px;
                border: 1px solid $borderColor3;
                border-radius: 4px 0px 0px 4px;
                background-color: $baseColor;

                &_Searchbar {
                    padding: 12px;
                    min-height: 68px;

                    span {
                        width: 28px;
                        height: 28px;
                        background-color: $greybg;
                        border-radius: 100px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .chat_Searchbar {
                        position: relative;
                        padding-left: 10px;
                        width: calc(100% - 28px);

                        @media (max-width:767px) {
                            margin-left: 0;
                            margin-top: 8px;
                        }

                        .control_searchBar {
                            background: #161924;
                            border-color: $borderColor;
                            padding-left: 50px;
                            width: 100%;
                            border-radius: 100px;
                        }

                        svg {
                            position: absolute;
                            left: 30px;
                            top: 12px;
                            cursor: pointer;

                            path {
                                fill: #63677A;
                            }
                        }
                    }
                }

                &_Boxs {
                    height: 447px;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 5px !important;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: transparent;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: $greybg;
                        border-radius: 10px;
                    }

                    li {
                        padding: 18px;
                        border-top: 1px solid $borderColor3;

                        .not_show {
                            display: none;
                            color: $greenclr;
                            margin-bottom: 0;
                            font-weight: 500;
                            font-size: 11px;
                            line-height: 18px;

                            span {
                                width: 10px;
                                height: 10px;
                                background: $greenbtnclr;
                                border-radius: 50px;
                                display: inline-flex;
                                align-items: center;
                                margin-left: 5px;
                            }
                        }

                        h5 {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 18px;
                            margin-bottom: 0;
                            padding-left: 10px;
                            color: $white;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;

                            img {
                                margin-right: 10px;
                            }
                        }

                        p {
                            font-weight: 500;
                            font-size: 11px;
                            line-height: 18px;
                            color: $white;
                            padding-right: 10px;
                        }

                        .Official_btn {
                            height: 26px;
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 18px;
                            color: $white;
                            padding: 4px;
                            min-width: 70px;
                        }
                    }
                }
            }

            &_Room {
                width: calc(100% - 340px);
                border-width: 1px 1px 1px 0px;
                border-style: solid;
                border-color: #1F2333;
                border-radius: 0px 4px 4px 0px;

                &_Header {
                    background: $darkblackBg;
                    padding: 15px 25px;
                    min-height: 68px;
                    position: relative;
                    justify-content: center;

                    h3 {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 17px;
                        color: $white;
                        text-align: center;
                        margin-bottom: 0;
                    }                   
                }

                &_ChatBox {

                    ul {
                        padding: 40px 25px;

                        height: 365px;
                        overflow-y: auto;

                        &::-webkit-scrollbar {
                            width: 5px !important;
                        }

                        &::-webkit-scrollbar-track {
                            background-color: transparent;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: $greybg;
                            border-radius: 10px;
                        }

                        li {
                            margin-bottom: 46px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            .User_Send_Msg {
                                .User_pic {
                                    width: 30px;
                                    height: 30px;
                                    background-color: $blueclr;
                                    border-radius: 100px;
                                    display: inline-flex;
                                    align-items: center;
                                    justify-content: center;
                                }

                                .Chat_Content {
                                    position: relative;
                                    margin-left: 30px;
                                    width: calc(100% - 60px);

                                    h6 {
                                        background: $borderColor;
                                        border: 1px solid $borderColor6;
                                        border-radius: 10px;
                                        font-weight: 400;
                                        font-size: 12px;
                                        line-height: 20px;
                                        padding: 8px 12px;
                                    }

                                    p {
                                        position: absolute;
                                        bottom: -15px;
                                        left: 0;
                                        font-weight: 400;
                                        font-size: 10px;
                                        line-height: 12px;
                                        color: $greybg3;
                                        width: 100%;

                                        span {
                                            color: $white;
                                            padding-left: 8px;
                                        }
                                    }
                                }

                                &.Receive_Msg {
                                    flex-direction: row-reverse;

                                    .User_pic {
                                        background-color: $bgclr1;

                                        svg {
                                            path {
                                                fill: $blueclr;
                                            }
                                        }
                                    }

                                    .Chat_Content {
                                        margin-left: 0px;
                                        margin-right: 30px;

                                        h6 {
                                            background-color: $bgclr2;
                                            border-color: $borderColor7;
                                        }

                                        p {
                                            text-align: right;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &_ChatInput {
                    padding: 0 13px;

                    .Chat_ChatInput_Box {
                        border: 1px solid $borderColor1;
                        padding: 10px 18px;
                        border-radius: 10px;

                        .send_msg_input {
                            border: 0;
                            padding: 0;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;

                            &::placeholder {
                                opacity: 1;
                                color: $white;
                            }
                        }

                        .send_msg_btn {
                            background-color: $blueclr;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 10px;
                            width: 36px;
                            height: 36px;
                            margin-left: 16px;
                            cursor: pointer;
                        }
                    }
                }
            }

        }
    }

    .Arrows-Expand {
        img {
            position: absolute;
            left: 25px;
            top: 25px;
            cursor: pointer;          
            
            &.Expd2_icon {
                display: none;
            }
        }
    }

    &.Expand_box {
        max-width: 1100px;

        .Arrows-Expand {
            img {
                &.Expd1_icon {
                    display: none;
                }
                &.Expd2_icon {
                    display: block;
                }
            }
        }
    }
}