@import "../../../../assets/theme/_var.scss";

.Naviblue_Card {
    background-color: rgba(23, 69, 245, 0.18);
    padding: 12px 20px;
    margin-bottom: 28px;

    @media (max-width: 767px) {
        margin-bottom: 20px;
        padding: 12px 15px;
    }
}
.mmtSection {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .bar {
        display: flex;
        width: 100%;
        align-items: center;
        column-gap: 6px;
        .type {
            width: 25px;
        }
        .progressBar {
            width: 130px;
            height: 6px;
            background: $lightwhitetext2;
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            .filledBar {
                background: $greenclr;
                position: absolute;
                height: 100%;
            }
        }
        .percentage {
            color: $greenclr;
        }
    }
}

.future_assets_content {
    .Spot_blnc_Card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 20px;

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .Spot_Pl {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 575px) {
            width: 100%;
        }

        h2 {
            img {
                width: 33px;
            }
        }
    }
}
.width-3 {
    display: flex;
    flex-wrap: wrap;
    column-gap: 25px;
    @media screen and (max-width: 767px) {
        column-gap: 0;
        row-gap: 25px;
    }
    .Spot_blnc_Card {
        flex: calc(33.33% - 60px);
        max-width: 33.33%;
        @media screen and (max-width: 767px) {
            width: 100%;
            flex: 0 0 100%;
        }
    }
}
.Futures_Tabs_Container {
    margin-top: 38px;

    @media (max-width: 767px) {
        margin-top: 25px;
    }

    .tab-content {
        margin-top: 36px;

        @media (max-width: 767px) {
            margin-top: 20px;
        }
    }
}
