@import "../../../assets/theme/_var.scss";

.Mobile_view_design {
    padding: 0 10px 10px;

    .Mobile_Chart_Tabs {
        border: 0;
        padding-top: 4px;
        padding-bottom: 4px;

        &.nav-tabs {
            .nav-link {
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                color: $lightwhitetext;
                background-color: transparent;
                border: 0;
                padding: 0.5rem 2.4rem 0.5rem 0.5rem;

                &.active,
                &:hover,
                &:focus {
                    color: $blueclr;
                }
            }
        }
    }

    .buy_Sell_mobile_btns {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: $baseColor;
        padding: 15px 11px;
        z-index: 9;
        left: 0;

        & > div {
            padding: 0 4px;
        }

        .btn-primary {
            border-radius: 3px;
            height: 45px;
            font-size: 14px;
        }
    }
}

.buy-bar {
    position: relative;
    display: inline-block;
    padding: 8px 0px 0px 8px;
    overflow: hidden;
    color: #20b26c;
    height: 29px;
    margin-top: 3px;
    font-size: 11px;
    z-index: 1;
}

.buy-bar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #172b22;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: skew(-10deg);
    -ms-transform: skew(-10deg);
    transform: skew(-10deg);
    z-index: -1;
}

.sell-bar {
    position: relative;
    display: inline-block;
    padding: 8px 11px 0px 0px;
    overflow: hidden;
    color: #ef454a;
    float: right;
    height: 29px;
    margin-top: 3px;
    text-align: right;
    font-size: 11px;
    z-index: 1;
}

.sell-bar:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #34191e;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 0 100%;
    -webkit-transform: skew(-10deg);
    -ms-transform: skew(-10deg);
    transform: skew(-10deg);
    z-index: -1;
}
