@import "../../../../../assets/theme/_var.scss";

.CryptoAssets_Table.Overview_MyAssets {
    margin-top: 0px;

    .table {
        margin-top: 15px;
        border: 1px solid $borderColor3;
        thead {
            tr {
                th {
                    border: 0;
                    background: transparent;
                    font-size: 1rem;
                    color: $greybg3;
                    @media screen and (max-width: 1366px) {
                        font-size: 14px;
                    }
                }
            }
        }

        tbody {
            tr {
                transition: all ease 0.3s;
                border-bottom: 1px solid $borderColor3;
                &:nth-of-type(odd) {
                    background: $darkblackBg;
                    &:hover {
                        background: $borderColor3;
                    }
                }
                td {
                    vertical-align: middle;
                    padding: 18px 18px;
                    color: $white;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    border: 0;
                    background: transparent;
                    @media screen and (max-width: 1366px) {
                        font-size: 14px;
                    }
                    @media (max-width: 767px) {
                        line-height: 17px;
                        padding: 15px 10px;

                        &:first-child {
                            min-width: 180px;
                        }
                    }

                    &:last-child {
                        border-left: 0;
                    }

                    .btc_vlaue {
                        color: $white;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        border: 0;
                        @media screen and (max-width: 1366px) {
                            font-size: 14px;
                        }
                        p {
                            color: $greyclr;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 15px;
                            margin-bottom: 0;
                            margin-top: 4px;
                            @media screen and (max-width: 1366px) {
                                font-size: 14px;
                            }
                        }

                        svg {
                            width: 9px;
                            height: 16px;
                            position: relative;
                            @media screen and (max-width: 1366px) {
                                height: 14px;
                                width: 7px;
                            }
                            path {
                                stroke: #63677a;
                            }
                        }
                    }
                    .coin_data {
                        h6,
                        span {
                            @media screen and (max-width: 1366px) {
                                font-size: 14px;
                            }
                        }
                    }

                    .action_links {
                        display: inline-flex;

                        a {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                            margin-right: 25px;
                            transition: all ease-in-out 0.3s;

                            &:last-child {
                                margin-right: 0;
                            }

                            &:hover,
                            &:focus {
                                color: $white;
                            }
                        }
                    }
                }

                &:hover {
                    background-color: $darkblackBg;

                    td {
                        .btc_vlaue {
                            svg {
                                left: 3px;
                                path {
                                    stroke: $blueclr1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
