@import "../../assets/theme/var.scss";

.staking_banner {
  background-color: $darkblackBg1;
  padding: 20px 0;
  text-align: left;
  background-image: url("../../assets/images/banner_gr_bg.png");
  background-repeat: no-repeat;
  background-position: 96% 100%;
  background-size: cover;
  position: relative;
  z-index: 1;
  min-height: 400px;
  display: flex;
  align-items: center;
  // background: $gradient2;

  @media (max-width: 991px) {
    padding: 40px 0 20px;
  }

  @media (max-width: 767px) {
    padding: 45px 0 0;
  }

  h1 {
    font-size: 50px;
    font-weight: 200;
    margin-bottom: 18px;

    @media (max-width: 767px) {
      font-size: 43px;
    }
  }

  em {
    font-weight: 400;
    font-family: $baseFont2;
  }

  p {
    color: $lightwhitetext4;
    margin-bottom: 40px;
    font-weight: 200;
    font-size: 14px;

    @media (max-width: 767px) {
      max-width: 220px;
      margin: 0 auto 32px;
    }
  }

  .Banner_coin {
    width: 228px;
    height: 232px;
    position: relative;
    margin-left: auto;
    top: 0;

    img {
      position: absolute;
      max-width: 350px;

      @media (max-width: 991px) {
        position: relative;
      }

      @media (max-width: 575px) {
        max-width: 300px;
      }
    }

    .token_card_background {
      width: 100%;
      height: 100%;
      border: 1px solid rgba(238, 238, 238, 0.1);
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.03);
      backdrop-filter: blur(6px);
      position: absolute;
      z-index: 1;
    }

    img:nth-child(2) {
      max-width: 100px;
      top: -55px;
      right: 12px;
      transition: all 0.3s;
    }

    img:nth-child(3) {
      max-width: 292px;
      z-index: 3;
      left: -210px;
      bottom: -72px;
    }

    img:nth-child(4) {
      max-width: 105px;
      z-index: 3;
      bottom: 83px;
      left: 9px;
    }

    img:nth-child(5) {
      max-width: 250px;
      z-index: 3;
      bottom: -131px;
      left: -30px;
      transform-origin: top left;
      transition: all 0.5s;
    }

    &:hover img:nth-child(2) {
      animation: myAnimstakingwhiteehx 1s ease-in-out 0s 1 normal forwards;
    }

    &:hover img:nth-child(3) {
      animation: mystakingbannerseif 1s ease-in-out 0s 1 normal forwards;
    }

    &:hover img:nth-child(4) {
      animation: myAnimstakingblueehx 1s ease-in-out 0s 1 normal forwards;
    }

    &:hover img:nth-child(5) {
      animation: myAnimarroww 1s ease-in-out 0s 1 normal forwards;
    }
  }

  .learn-more {
    min-width: 260px;
    position: relative;
    transition: all ease-in-out 0.3s;

    @media (max-width: 424px) {
      min-width: 100%;
    }

    .Btn_Icon {
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition: all ease-in-out 0.3s;
    }

    &:hover,
    &:focus {
      background-color: #ffffff;
      color: #0935DC;

      .Btn_Icon {
        right: 20px;
        opacity: 1;
      }
    }    
  }


}

.staking-content {
  color: $textGray;
  font-size: 14px;
  padding-left: 68px;
  padding-right: 30px;
  position: relative;
  font-weight: 400;

  @media (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
    font-size: 12px;
    margin-bottom: 24px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:last-child {
      font-size: 12px;
    }

    h2 {
      margin-bottom: 5px;
    }

    strong {
      color: $lightwhitetext;
      font-weight: 700;
      font-size: 14px;
      margin-left: 5px;
    }
  }

  &__icon {
    position: absolute;
    left: 0;
    top: -10px;
    width: 44px;
    height: 44px;
    border-radius: 50%;

    @media (max-width: 575px) {
      position: static;
      top: 0;
      display: inline-block;
      margin-bottom: 24px;
    }
  }
}

.staking-card {
  background-color: $darkblackBg4;
  padding: 20px;
  border-radius: 5px;
  border: 0.5px solid $borderColor5;

  @media (max-width: 575px) {
    padding: 20px 16px;
  }

  &__title {
    margin-bottom: 17px;

    h5 {
      font-weight: 700;
      font-size: 11px;
      color: $lightwhitetext;
      letter-spacing: 0.1px;
      margin-bottom: 0;
    }
  }

  &__btns {
    display: flex;
    gap: 10px;

    @media (max-width: 575px) {
      flex-wrap: wrap;
      gap: 17px;
    }

    .btn-style {
      width: 50%;

      @media (max-width: 575px) {
        width: 100%;
      }
    }

    &--single {
      .btn-style {
        flex: 49.5% 0;

        @media (max-width: 575px) {
          flex: 100%;
        }

        &[disabled] {
          opacity: 0.18;
        }
      }
    }
  }

  .time_Vesting {
    text-align: right;
    margin-bottom: 8px;

    p {
      font-size: 11px;
      font-weight: 300;
      line-height: 21px;
      color: $lightwhitetext;

      span {
        font-weight: 700;
        padding-left: 3px
      }
    }
  }

  .tootip-icon {
    line-height: normal;
    display: inline-block;
    // height: 12px;
    width: 12px;

    // background-image: url('../../assets/icons/info.svg');
    // background-size: 12px;
    img {
      vertical-align: unset;
    }
  }
}

.staking-info {
  &__list {
    padding-top: 22px;

    li {
      color: $textGray;
      font-size: 12px;
      line-height: 22px;
      display: flex;
      justify-content: space-between;

      span {
        color: $lightwhitetext;
        font-weight: 700;
      }
    }

    @media (max-width: 575px) {
      &.mb-30 {
        margin-bottom: 36px;
      }
    }
  }
}

.stake-btns {
  button {
    &:first-child {
      margin-right: 10px;
    }

    &.grey-btn {
      @media (max-width: 575px) {
        font-size: 11px;
      }
    }
  }
}


@keyframes myAnimstakingwhiteehx {
  0% {
    transform: scale(1) translateX(0) translateY(0);
  }

  50% {
    transform: scale(1) translateX(12px) translateY(-12px);
  }

  100% {
    transform: scale(1) translateX(0) translateY(0);
  }
}

@keyframes mystakingbannerseif {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-15px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes myAnimstakingblueehx {
  0% {
    transform: scale(1) translateX(0) translateY(0);
  }

  50% {
    transform: scale(1) translateX(30px) translateY(-40px);
  }

  100% {
    transform: scale(1) translateX(0) translateY(0);
  }
}

@keyframes myAnimarroww {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.staking-section {
  padding: 64px 0;
  background-color: $darkblackBg4;

  @media (max-width: 767px) {
    padding-top: 35px;
    padding-bottom: 24px;

    // .mb-30 {
    //   margin-bottom: 50px;
    // }
  }
}

.wallet-wrap {
  background-color: $darkblackBg4;
  padding: 45px;
  border: 0.5px solid $borderColor5;
  border-radius: 5px;

  @media (max-width: 575px) {
    padding: 35px 16px;
  }

  h3 {
    color: $lightwhitetext;
    font-weight: 700;
    font-size: 18px;
  }

  @media (max-width: 424px) {
    .btn-style {
      width: 100%;
    }
  }
}