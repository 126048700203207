@import "../../../../assets/theme/_var.scss";

.marginModal {
    .seperator {
        width: 100%;
        height: 2px;
        background-color: $borderColor;
    }
    .modal-dialog {
        max-width: 600px !important;
        margin-left: auto;
        margin-right: auto;

        .modal-content {
            .modal-header {
                // &.marginModalHeader {
                //     border-bottom: 2px solid $borderColor;
                // }
                padding: 20px 34px;

                .btn-close {
                    right: 45px;
                }
            }

            .modal-body {
                padding: 20px 34px;
                p {
                    color: $greybg3;
                }
                .switchButtonssection {
                    display: inline-block;
                    // border-bottom: 2px solid $borderColor;
                    padding-bottom: 20px;
                    .buttons {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        column-gap: 15px;
                        margin-bottom: 40px;
                        .switchButtons {
                            width: 50%;
                            background: $borderColor;
                            padding: 12px;
                            border: 2px solid transparent;
                            border-radius: 5px;
                            color: $white;
                            font-weight: 700;
                            transition: all ease-in-out 0.3s;
                            &.selected,
                            &:hover {
                                background-color: $btnbghover;
                                border-color: $blueclr;
                            }
                        }
                    }
                }
                .modalDescriptin {
                    .seperator {
                        margin-bottom: 20px;
                    }
                    p {
                        margin-bottom: 15px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                button.btn-primary {
                    height: 60px;
                    border-radius: 8px;

                    @media (max-width: 767px) {
                        height: 45px;
                    }
                }
            }
        }
    }
}
