@import "../../../../assets/theme/_var.scss";

.Admin_Sidebar {

    .Closebtn_sidebar {
        display: none;

        @media (max-width:991px) {
            padding: 20px 25px;
            margin-bottom: 0px;
            display: block;
            text-align: right;
            position: fixed;
            background-color: $baseColor;
            top: 0;
            width: 280px;
        }
    }

    &_List {
        li {
            a {
                padding: 24px 17px;
                border-left: 4px solid transparent;
                font-weight: 600;
                font-size: 16px;
                line-height: 130%;
                color: $white;
                display: block;
                transition: all ease-in-out 0.3s;

                @media (max-width:1599px) {
                    font-size: 14px;
                    line-height: 110%;
                }

                @media (max-width:991px) {
                    padding: 20px 17px;
                }

                img {
                    width: 20px;
                    margin-right: 19px;
                }

                span {
                    transition: all ease-in-out 0.3s;
                }

                &:hover,&.active {
                    border-color: $blueclr;
                    background-color: $darkblackBg;

                    span {
                        padding-left: 2px;
                    }
                }
            }
        }
    }
}