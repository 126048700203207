@import "../../assets/theme/_var.scss";

.Common_Table_Pagination {
    justify-content: flex-end;

    li {
        .page-link {
            background-color: transparent;
            border: 0;
            width: 37px;
            height: 28px;
            padding: 0;
            text-align: center;
            color: $white;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            margin: 0 2px;
            cursor: pointer;
        }

        &.active, &:hover {
            .page-link {
                background-color: $blueclr;
            }
        }
    }
    .prev_icon {
        span {
            transform: rotate(180deg);
            opacity: 0.3;
        }
    }
}