@import "../../../../../../assets/theme/_var.scss";

.calender_Card {
    margin-bottom: 10px;
    padding: 0 18px;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: auto;

    ul {
        li {
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }

            &.date_btn {
                font-size: 12px;
                font-weight: 300;
                line-height: normal;
                color: $whitelightclr;
                padding: 10px;
                cursor: pointer;
                transition: all ease-in-out 0.3s;

                &.active,
                &:hover {
                    background-color: $greybg;
                }
            }
        }
    }

    .date_calender_picker {
        display: flex;
        align-items: center;

        .date_text {
            font-size: 12px;
            font-weight: 300;
            line-height: normal;
            margin-left: 10px;
            margin-right: 10px;
            color: $greybg3;
        }

        .date_picker {
            font-size: 12px;
            cursor: pointer;
        }

        .react-datepicker-wrapper {
            .react-datepicker__input-container {
                width: 85px;
                input {
                    padding: 0;
                    border: 0;
                    height: auto;
                    background: transparent;
                    width: auto;
                    font-size: 12px;
                    cursor: pointer;

                    &::placeholder {
                        color: $white;
                        opacity: 1;
                    }
                }
            }

        }

        .react-datepicker {
            background-color: $borderColor1;
            box-shadow: 2px 16px 19px 0px rgba(0, 0, 0, 0.09);
            border: 0;
            border-radius: 8px;
            width: 306px;
            padding: 24px 15px;

            &__navigation {
                top: 28px;
            }

            &__navigation--previous {
                left: 25px;
            }

            &__navigation--next {
                right: 25px;
            }

            &__triangle {
                &::after,  &::before {
                    border-bottom-color: $borderColor1 !important;
                    border-top-color: $borderColor1 !important;
                }
            }

            &__tab-loop {
                background-color: $borderColor1;
            }

            &__month-container {
                float: none;
            }

            &__header {
                background-color: transparent;
                border: 0;
                color: $white;
            }

            &__current-month {
                color: $white;
                font-size: 14px;
                font-weight: 500;
                line-height: 14px;
            }

            &__day-names {
                color: $white;
                margin: 25px 0 5px;
            }

            &__day-name {
                color: $white;
                font-size: 10px;
                font-weight: 500;
                line-height: 12px;
                letter-spacing: 1.5px;
                text-transform: uppercase;
            }

            &__month {
                margin: 0;
            }

            &__day {
                color: $white;
                font-size: 14px;
                font-weight: 500;
                line-height: 14px;
                width: 30px;
                height: 30px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;

                &:hover {
                    background-color: $blueclr;
                }
            }

            &__day--selected, &__day--keyboard-selected{
                background-color: $blueclr;
            }
        }

    }
}