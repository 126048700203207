@import "../../../../assets/theme/_var.scss";

.api_content {
    ol {
        max-width: 720px;
        margin: 0;
        padding-left: 15px;

        li {
            font-weight: 400;
            font-size: 14px;
            line-height: 180%;
            color: $white;
        }
    }
}

.Api_Management_Content {
    .Naviblue_Card {
        margin-top: 50px;

        .blue_check_box {
            .form-check-input {
                width: 24px;
                height: 24px;

                &:checked {
                    background-color: $blueclr1;
                    border: 1px solid $blueclr1;
                }
            }
        }

        p {
            a {
                font-weight: 600;
                font-size: 16px;
                line-height: 17px;
                color: $white;
                padding-left: 15px;
                display: inline-block;
                vertical-align: middle;
            }

            svg {
                width: 6px;
                height: 10px;
                margin-left: 5px;
            }
        }

    }
}

.Admin_Modal {
    &.Api_modal {
        .api_modal_Content {
            margin-top: 40px;

            ul {
                li {
                    background-color: $dashbg;
                    border: 1px solid $dashbg;
                    padding: 28px 40px;
                    border-radius: 8px;
                    margin-bottom: 18px;
                    cursor: pointer;

                    @media (max-width:767px) {
                        padding: 20px 15px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:hover {
                        border-color: $blueclr1;
                    }

                    img {
                        width: 50px;
                    }

                    p {
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 29px;
                        padding-left: 28px;

                        @media (max-width:767px) {
                            font-size: 18px;
                            line-height: 23px;   
                            padding-left: 15px;
                        }

                        span {
                            display: block;
                            margin-top: 8px;
                        }
                    }
                }
            }

            .FormGroup {
                label {
                    margin-bottom: 14px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    display: block;
                }

                .custom_input {
                    width: 100%;
                    background-color: $dashbg;
                    border-radius: 8px;
                    height: 55px;
                    border-color: $blueclr1;
                }
            }
        }
    }
}

.Create {
    &_Api {
        &_List {
            border: 1px solid #333540;
            border-radius: 8px;
            padding: 42px 32px;
            margin-bottom: 18px;

            @media (max-width:767px) {
                padding: 25px 15px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .Api_Scancode {
                background: #0F1223;
                border: 1px solid #262A39;
                box-shadow: 0px 4px 15px rgba(11, 16, 18, 0.24);
                border-radius: 20px;
                padding: 28px 18px;
            }

            &_TopApibtn {

                span,
                svg {
                    cursor: pointer;
                }

                svg {
                    &:hover {
                        path {
                            fill: $white;
                        }
                    }
                }

                .textgreen_btn, .textblue_btn {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    padding: 4px 4px;
                }
            }

            &_Apikey {
                p {
                    margin-bottom: 12px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                }

                h5 {
                    word-break: break-all;
                }
            }
        }
    }
}