@import "../../../../../assets/theme/_var.scss";

.Transaction {
    &_History {
        &_selectBox {
            @media (max-width: 1199px) {
                margin: 0 -10px;
            }

            .common_select {
                width: 220px;
                margin-right: 25px;

                &:last-child {
                    margin-right: 0;
                }

                @media (max-width: 1199px) {
                    width: 50%;
                    margin: 10px 0;
                    padding: 0 10px;
                }

                label {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                    color: $textGrey;
                    margin-bottom: 14px;

                    @media (max-width: 1199px) {
                        font-size: 16px;
                    }
                }

                .react-select__control {
                    background: $bgclr4;
                    border: 1px solid $greybg !important;
                    border-radius: 4px;
                    padding: 0 15px;

                    .react-select__single-value {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: $footerlinkclr;
                    }
                }

                .react-select__menu {
                    margin-top: 0;

                    .react-select__option {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: $footerlinkclr;
                        padding: 12px 15px;
                    }
                }
            }
        }

        &_Filter {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media (max-width: 1199px) {
                justify-content: flex-start;
            }

            .common_select {
                width: 120px;
                margin-right: 10px;

                span {
                    img {
                        display: block;
                        width: 20px;
                    }
                }

                .filtericon {
                    img {
                        width: 16px;
                        max-width: 16px;
                        height: 18px;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }

                .react-select__control {
                    background: $bgclr4;
                    border: 0;
                    border-radius: 4px;
                    padding: 0 10px;
                    min-height: auto;

                    .react-select__placeholder,
                    .react-select__single-value {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: $footerlinkclr;
                    }

                    .react-select__value-container {
                        padding: 0 5px;
                        width: 85px;
                    }
                }

                .react-select__menu {
                    margin-top: 0;

                    .react-select__option {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: $footerlinkclr;
                    }
                }

                .react-datepicker-wrapper {
                    .react-datepicker__input-container {
                        input {
                            width: 100%;
                            border: 0;
                            height: auto;
                            background: transparent;
                        }
                    }
                }
            }
        }

        .Bottom_Border_Tabs {
            @media (max-width: 767px) {
                display: flex;
                flex-wrap: nowrap;
                white-space: nowrap;
                overflow-x: auto;

                &::-webkit-scrollbar {
                    width: 0px !important;
                    height: 0px !important;
                }
            }

            .nav-item {
                .nav-link {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;

                    &.active {
                        color: $blueclr1;
                    }
                }
            }
        }
    }
}
