@import "../../../../../assets/theme/_var.scss";

.Orders_Table {
    max-height: 350px;
    overflow-y: auto;
    padding: 0 18px;

    @media (max-width:1023px) {
        padding: 0 10px;
    }

    &::-webkit-scrollbar {
        width: 5px !important;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $blueclr;
        border-radius: 10px;
    }

    .table {
        thead {
            tr {
                th {
                    border: 0;
                    background-color: $darkblackBg;
                    color: $greybg3;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 15px;
                    padding: 11px 10px;
                    min-width: 70px;

                    span {
                        color: $white;
                        font-weight: 500;
                        font-size: 8px;
                        line-height: 18px;
                        padding-left: 16px;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    color: $greybg3;
                    background-color: $baseColor;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 15px;
                    padding: 18px 10px;
                    border-color: rgba(31, 35, 51, 0.5);
                    min-width: 70px;

                    &.Amount_Data {
                        p {
                            color: $white;
                            margin: 3px 0;
                        }
                    }
                }

            }
        }
    }
}