@import "../../../../../assets/theme/_var.scss";

.CryptoAssets_Table {
    margin-top: 24px;

    .heading {
        font-weight: 400;
        font-size: 18px;
        line-height: 17px;

        @media (max-width: 767px) {
            margin-top: 25px;
        }
    }

    .table {
        margin-top: 28px;
        border: 1px solid $borderColor3;

        @media (max-width: 767px) {
            margin-top: 15px;
        }

        thead {
            tr {
                border-bottom: 1px solid $borderColor3;
                th {
                    background-color: $cardbg;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: $greybg3;
                    padding: 18px 18px;
                    border: 0;
                    background: transparent;
                    border-left: 1px solid $borderColor3;
                    @media screen and (max-width: 1366px) {
                        font-size: 14px;
                    }
                    @media (max-width: 767px) {
                        padding: 15px 10px;
                        white-space: nowrap;

                        &:first-child {
                            min-width: 180px;
                        }
                    }

                    &:last-child {
                        border-left: 1px solid $borderColor3;
                    }

                    .upDown_arrow {
                        margin-left: 8px;

                        span {
                            border-bottom: 7px solid $borderColor1;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            display: block;
                            cursor: pointer;
                            transition: all ease-in-out 0.3s;

                            &:last-child {
                                transform: rotate(180deg);
                                margin-top: 4px;
                            }

                            &:hover {
                                border-bottom: 7px solid $white;
                                border-left: 5px solid transparent;
                                border-right: 5px solid transparent;
                            }
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                transition: all ease 0.3s;
                border-bottom: 1px solid $borderColor3;
                &:nth-of-type(odd) {
                    background: $darkblackBg;
                    &:hover {
                        background: $borderColor3;
                    }
                }
                td {
                    vertical-align: middle;
                    padding: 18px 18px;
                    color: $white;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    border-color: $borderColor3;
                    background: transparent;
                    @media screen and (max-width: 1366px) {
                        font-size: 14px;
                    }
                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 17px;
                        padding: 15px 10px;

                        &:first-child {
                            min-width: 180px;
                        }
                    }

                    &:last-child {
                        border-left: 1px solid $borderColor3;
                    }

                    .coin_data {
                        .coin_Icon {
                            width: 30px;
                            margin-right: 25px;
                        }

                        h6 {
                            text-transform: uppercase;
                            margin-bottom: 4px;
                            @media screen and (max-width: 1366px) {
                                font-size: 14px;
                            }
                        }

                        span {
                            color: $greyclr;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            margin-bottom: 0;
                            @media screen and (max-width: 1366px) {
                                font-size: 12px;
                            }
                        }
                    }

                    .btc_vlaue {
                        color: $greyclr;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 15px;
                        margin-bottom: 0;
                        margin-top: 4px;
                    }

                    .action_links {
                        display: inline-flex;

                        a {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                            margin-right: 25px;
                            transition: all ease-in-out 0.3s;
                            color: $bluelightBg;
                            position: relative;
                            &:before {
                                content: "";
                                position: absolute;
                                left: 0;
                                bottom: -5px;
                                width: 0;
                                height: 2px;
                                background: $bluelightBg;
                                transition: 0.3s;
                            }
                            @media screen and (max-width: 1366px) {
                                font-size: 14px;
                            }
                            &:last-child {
                                margin-right: 0;
                            }
                            &:hover {
                                color: $bluelightBg;
                            }
                            &:hover:before {
                                width: 100%;
                            }
                        }
                    }
                }
                &:hover {
                    background-color: $darkblackBg;
                }
            }
        }
    }
}
