@import "../../../../../assets/theme/_var.scss";

.Support {
    &_Tickets {
        &_New {
            padding: 30px 0;
        }

        &_Form {
            max-width: 660px;
            margin: 0 auto;
            padding-top: 60px;
            padding-bottom: 80px;

            .form-control {
                border: 0;
                background-color: $bgclr4;
                height: 68px;
                padding: 10px 30px;

                &:focus {
                    background-color: $bgclr4;
                }
            }

            .common_select {
                .react-select__control {
                    background-color: $bgclr4;
                    height: 68px;
                    padding: 10px 30px;

                    .react-select__placeholder,
                    .react-select__single-value {
                        color: #63677A;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.priority_Dropdown {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    @media (max-width:767px) {
        width: 100%;
        left: 0;
    }

    .dropdown {
        &-toggle {
            display: flex;
            width: 285px;
            padding: 16px 30px;
            justify-content: space-between;
            align-items: center;
            background-color: $darkblackBg;
            color: $white;
            font-weight: 500;
            font-size: 16px;

            @media (max-width:767px) {
                width: 100%;
            }
        }

        .dropdown {
            &-menu {
                width: 100%;
                background-color: $darkblackBg;
                border-radius: 4px;
                margin-top: 4px;
                padding: 8px;

                a {
                    display: flex;
                    align-items: center;
                    padding: 10px 8px;
                    color: $clr1;

                    span {
                        width: 14px;
                        height: 14px;
                        border-radius: 50px;
                        display: inline-block;
                        background-color: $clr1;
                        margin-right: 8px;

                        &.blueclr {
                            background-color: #3B8AFF;
                        }

                        &.greyclr {
                            background-color: #6C76AA;
                        }

                        &.greenclr {
                            background-color: #10B981;
                        }

                        &.redclr {
                            background-color: #DC2626;
                        }
                    }

                    &:hover {
                        background-color: #7F56D80D;
                    }
                }
            }
        }
    }
}

.SupportTicket {
    &_Tabs {
        position: relative;

        @media (max-width:767px) {
            .Bottom_Border_Tabs {
                margin-top: 70px;
            }
        }
    }

    &_Box {
        padding: 28px 38px;
        gap: 14px;
        border-radius: 8px;
        border: 1px solid $borderColor1;
        background-color: #10121D;
        margin-top: 28px;

        @media (max-width:767px) {
            padding: 20px 15px;
            margin-top: 10px;

        }

        h3 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $white;
            font-size: 18px;
            font-weight: 700;
            line-height: 148%;
            margin-bottom: 0;

            @media (max-width:767px) {
                font-size: 15px;
            }

            span {
                font-size: 16px;
                font-weight: 500;
                line-height: 148%;
                color: $textGrey6;

                @media (max-width:767px) {
                    font-size: 13px;
                }
            }
        }

        p {
            padding: 22px 36px;

            @media (max-width:767px) {
                padding: 20px 0px;
            }
        }

        .common_clrbtn {
            display: flex;
            padding: 10px 24px;
            border-radius: 30px;
            color: $white;
            align-items: center;
            justify-content: center;

            &.greybtn {
                background: #6C76AA;
            }

            &.greenbtn {
                background: $greenclr;
            }

            &.redbtn {
                background: $redclr;
            }
        }

        .numberbtn {
            display: flex;
            padding: 10px 24px;
            border-radius: 30px;
            color: $blueclr1;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: 1px solid $blueclr1;
            margin: 0 10px;

            &:hover {
                background-color: $blueclr1;
                border: 1px solid $blueclr1;
                color: $white;
            }
        }

        .comment_boxname {
            img {
                margin-right: 10px;
            }

            span,
            h6 {
                font-size: 16px;
                font-weight: 700;
                line-height: 148%;
                letter-spacing: 0.16px;
            }

            h6 {
                margin-bottom: 0;
                padding-left: 20px;
            }
        }
    }
}