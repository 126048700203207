@import "../../assets/theme/_var.scss";

.SwitchButton {
    .form-switch {
        margin-bottom: 0;
        display: flex;
        align-items: center;

        .form-check-input {
            width: 40px !important;
            height: 19px !important;
            border: 0;
            background-size: 19px;
            background-color: $greybg1;
            border-color: $greybg1;
            background-image: url("../../assets/icons/switch_b.svg");
            cursor: pointer;
            margin-top: 0;
            border-radius: 5rem;

            &:focus {
                box-shadow: none;
            }

            &:checked {
                background-image: url("../../assets/icons/switch_i.svg");
                background-color: $blueclr;
                border-color: $blueclr;
            }
        }

        .form-check-label {
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            color: $white;
            margin-left: 24px;
            margin-bottom: 0px;
        }
    }
}
