@import "../../../assets/theme/_var.scss";

.tooltip-style {
  &.tooltip {
    opacity: 1;
    margin-right: -16px;
    margin-top: 10px;

    .tooltip-arrow {
      &::before {
        left: -18px;
        border-bottom-color: $dropdwonBg1;
        border-width: 8px;
      } 
    }

    .tooltip-inner {
      font-weight: 300;
      font-size: 10px;
      line-height: 13px;
      text-align: left;
      padding: 15px 20px;
      background: $dropdwonBg1;
      color: $white ;
      min-width: 269px;
      border-radius: 3px;
    }

  }
}

.tooltip {
  &.ComingSoon {
    .tootip-icon {
      margin-left: 5px;
    }

    .tooltip-inner {
      min-width: 100px;

      &::before {
        display: none;
      }
    }
  }
}

.tooltip {
  &.Info_Tooltip {
    margin-top: 9px;
  }
}