@import "../../../../assets/theme/_var.scss";

.Convert_Wallet {
    max-width: 780px;
    margin: 70px auto 0;

    &_FromTo {
        &_Label {
            label {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: $white;
            }

            p {
                font-size: 16px;
                line-height: 19px;

                img {
                    margin-right: 5px;
                }
            }
        }

        &_Inputselect {
            background-color: $bgclr3;
            padding: 14px 28px;
            border-radius: 4px;
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .InputBox {
                width: calc(100% - 136px);

                .Maxbtn {
                    cursor: pointer;
                    padding-left: 18px;
                    padding-right: 18px;
                    border-right: 1px solid $borderColor1;
                }
            }

            .common_select {
                width: 136px;
                padding-left: 18px;

                .react-select__control {
                    .react-select__single-value {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        display: inline-flex;
                        align-items: center;

                        img {
                            margin-right: 12px;
                            width: 30px;
                        }
                    }

                    .react-select__input-container {
                        padding: 0;
                        margin: 0;

                        .react-select__input {
                            height: auto;
                        }
                    }
                }
            }
        }
    }

    .ConverBtn {
        width: 30px;
        height: 30px;
        background: $greybg;
        border-radius: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 38px auto;

        img {
            filter: brightness(0) invert(1);
        }
    }
}

.Convert {
    &_Order {
        max-width: 600px;
        margin: 0 auto;

        h5 {
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
            color: $white;
            margin: 46px 0;
        }

        &_Information {
            h6 {
                font-size: 16px;
                line-height: 19px;
                font-weight: 600;

                img {
                    margin-right: 11px;
                }
            }

            ul {
                li {
                    padding: 20px 0;

                    p {
                        font-size: 16px;
                        line-height: 19px;
                        color: $white;
                        font-weight: 400;
                        margin-bottom: 0;
                    }

                    h4 {
                        color: $textGrey6;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        margin-bottom: 0;
                        text-align: right;

                        .Text_Fee {
                            display: none;
                        }
                    }

                    .Text_Fee {
                        display: flex;
                        align-items: center;

                        small {
                            background: $greenbg;
                            color: $greenclr1;
                            padding: 2px 4px;
                            display: inline-flex;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}

.ConfirmCon_Modal.modal {
    .modal-dialog {
        max-width: 730px;
        margin: 0 auto;
        padding: 0 15px;

        .modal-content {
            background: $darkblackBg;
            border: 0;
            border-radius: 0;
            padding: 48px 34px;

            @media (max-width: 767px) {
                padding: 35px 15px;
            }

            .modal-header {
                padding: 0;

                .modal-title {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 130%;

                    @media (max-width: 1559px) {
                        font-size: 20px;
                    }
                }

                .btn-close {
                    background: url(http://localhost:3001/static/media/icon_close.80c7875e756abbee2e1c.svg) center no-repeat;
                    outline: none;
                    width: 16px;
                    height: 16px;
                    background-size: 16px;
                    right: 43px;
                    top: 54px;
                }
            }

            .modal-body {
                padding: 0;

                .Convert_Usdt_data {
                    padding: 82px 0 34px;
                    position: relative;

                    @media (max-width: 1559px) {
                        padding: 40px 0 34px;
                    }

                    ul {
                        li {
                            padding-bottom: 100px;

                            @media (max-width: 1559px) {
                                padding-bottom: 60px;
                            }

                            &:last-child {
                                padding-bottom: 0;
                            }

                            h3 {
                                display: flex;
                                align-items: center;

                                img {
                                    width: 30px;
                                    margin-right: 18px;
                                }
                            }

                            h3,
                            h4 {
                                margin-bottom: 0;
                                font-weight: 600;
                                font-size: 28px;
                                line-height: 34px;
                                color: $white;

                                @media (max-width: 1559px) {
                                    font-size: 22px;
                                    line-height: 28px;
                                }

                                @media (max-width:767px) {
                                    font-size: 18px;
                                    line-height: 24px;
                                }
                            }
                        }
                    }

                    .Arrow_down {
                        position: absolute;
                        left: 50%;
                        top: 58%;
                        transform: translate(-50%, -45%) rotate(90deg);

                        @media (max-width: 1559px) {
                            top: 52%;
                        }

                        img {
                            width: 35px;

                            @media (max-width: 1559px) {
                                width: 25px;
                            }
                        }
                    }
                }

                .Convert {
                    &_Order {
                        max-width: 100%;

                        &_Information {
                            ul {
                                background-color: #1D212D;
                                border-radius: 6px;
                                padding: 28px 44px;

                                @media (max-width: 767px) {
                                    padding: 20px 15px;
                                }

                                li {
                                    padding: 15px 0px;

                                    .tooltipIcon {
                                        display: none;

                                        svg {
                                            width: 16px;
                                            height: 16px;

                                            path {
                                                fill: $blueclr1;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .btn-primary {
                            margin-top: 34px;
                            height: 68px;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 130%;

                            @media (max-width:767px) {
                                margin-top: 20px;
                                height: 40px;
                                font-size: 16px;
                            }
                        }

                        .error_msg {
                            background-color: $redbgclr;
                            padding: 24px 32px;
                            margin-top: 34px;

                            @media (max-width:767px) {
                                padding: 20px 15px;
                                margin-top: 20px;
                            }

                            img {
                                margin-right: 20px;
                            }

                            p {
                                color: $redclr;
                                font-weight: 500;
                                font-size: 18px;
                                line-height: 22px;

                                @media (max-width:767px) {
                                    font-size: 14px;
                                    line-height: 20px;

                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.Conversion_successful_modal {
    .Conversion_successful {
        h3 {
            img {
                width: 42px;
                ;
            }
        }
    }
}