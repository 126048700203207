@import "../../../../assets/theme/_var.scss";

.Show_Blnc {
    background-color: $darkblackBg;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    cursor: pointer;
    img{
        width: 20px;
        margin-left: 5px;
    }
}
    