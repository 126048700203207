@import "../../assets/theme/_var.scss";

.Range_Slider {
    .slider {
        &.range-slider-inline {
            display: flex;
            flex-wrap: wrap;
            .rangeslider__labels {
                display: none !important;
                visibility: hidden;
            }
            .rangeslider {
                display: flex;
                flex: 0 0 calc(100% - 35px);
            }
            .RangeSlider_Value {
                display: flex;
                flex: 0 0 35px;
            }
        }
        position: relative;

        .rangeslider {
            &.rangeslider-horizontal {
                margin: 22px 0;
                position: relative;
                background: $darkblackBg;
                touch-action: none;
                height: 8px;
                border-radius: 10px;
                user-select: none;
                .rangeslider__fill {
                    background: linear-gradient(
                        90deg,
                        #67b6ff 1.3%,
                        #518fed 24.7%,
                        #156ee9 50.65%,
                        #1745f5 78.41%,
                        #0d2893 100%
                    );
                    left: 1px;
                    position: relative;
                }

                .rangeslider__handle {
                    width: 59px;
                    height: 21px;
                    background: $darkblackBg4;
                    border-radius: 4px;
                    border: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:focus {
                        box-shadow: none;
                        outline: 0;
                    }

                    &::after {
                        content: "";
                        background-color: transparent;
                        border-right: 6px solid $white;
                        border-top: 4px solid transparent;
                        border-bottom: 4px solid transparent;
                        border-radius: 0;
                        height: auto;
                        width: auto;
                        transform: none;
                        position: absolute;
                        top: 7px;
                        left: 6px;
                    }

                    &::before {
                        content: "";
                        background-color: transparent;
                        border-left: 6px solid $white;
                        border-top: 4px solid transparent;
                        border-bottom: 4px solid transparent;
                        border-radius: 0;
                        height: auto;
                        width: auto;
                        transform: none;
                        position: absolute;
                        top: 7px;
                        right: 6px;
                    }

                    .rangeslider__handle-label {
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 160%;
                        color: $white;
                    }

                    .rangeslider__handle-tooltip {
                        display: none;
                    }
                }

                .rangeslider__labels {
                    margin-top: 10px;

                    .rangeslider__label-item {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        color: $white;

                        &:last-child {
                            left: 15px !important;
                        }

                        &:first-child {
                            left: auto !important;
                            right: -10px !important;
                        }
                    }
                }
            }
        }

        .RangeSlider_Value {
            position: absolute;
            right: 0;
            top: 18px;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: $white;
            display: none;
        }
    }
}
