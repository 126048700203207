@import '../../../../assets/theme/_var.scss';

.SubHeader {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;

    .card_body_style {
        width: 100%;
    }

    .SubHeader_V2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        @media (max-width:767px) {
            display: inline-block;
            margin-top: 10px;
        }

        .Ntp_Value {
            h5 {
                font-weight: 600;
                font-size: 16px;
                line-height: 1;
                color: $greenclr;
                margin-bottom: 0;
                 margin-top: 3px;
            }
        }

        .Ticker_data {
            display: flex;
            justify-content: space-between;
            width: 93%;
            align-items: center;

            @media (max-width:1279px) {
                width: 83%;
            }

            @media (max-width:767px) {
                width: 100%;
                flex-wrap: wrap;
            }

            ul {
                display: flex;

                @media (min-width:768px) and (max-width:1279px) {
                    display: flex;
                    flex-wrap: nowrap;
                    white-space: nowrap;
                    overflow-y: auto;
                    margin-right: 15px;
                    
                    &::-webkit-scrollbar {
                        width: 0px !important;
                        height: 0px !important;
                    }

                }

                @media (max-width:767px) {
                    flex-wrap: wrap;
                    margin: 10px -4px;
                }

                li {
                    margin: 0;
                    padding: 0 22px;
                    border-right: 1px solid $borderColor3 ;
                    &:last-child{
                        border: none;
                    }
                    @media screen and (max-width: 1480px) {
                       padding: 0 15px; 
                    }
                    @media (max-width:767px) {
                        width: 33.33%;
                        padding: 3px 4px;
                        border: none;
                    }

                    span {
                        font-weight: 400;
                        font-size: 10px;
                        color: $greyclr;
                        line-height: 12px;
                        display: block;
                        margin-bottom: 3px;
                    }

                    p {
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 16px;
                        color: $white;
                    }
                }
            }
        }

        .Ticker_Dropdown {
            @media (max-width:767px) {
                position: absolute;
                bottom: 10px;
                right: 10px;
            }

            .dropdown {
                .dropdown-toggle {
                    background-color: transparent;
                    padding: 0;
                    border: 0;
                    width: 20px;
                    transition: all ease-in-out 0.3s;
                    height: auto;

                    &::after {
                        display: none;
                    }

                    svg {
                        transition: all ease-in-out 0.3s;
                    }

                    &.show {
                        svg {
                            transform: rotate(180deg);

                            path {
                                fill: $blueclr;
                            }
                        }
                    }

                    &:hover {
                        path {
                            fill: $blueclr;
                        }
                    }
                }

                .dropdown-menu {
                    background-color: $modalBgclr;
                    border: 1px solid $borderColor;
                    margin-top: 11px;

                    a {
                        color: $white;
                        padding: 10px 15px;

                        &:hover {
                            background-color: $blueclr;
                        }
                    }
                }
            }
        }
        .Star_Data {
            margin-right: 7px;
            display: block;
            line-height: 0;
            cursor: pointer;
            &.show{
                svg {
                    path {
                        fill: $blueclr;
                        stroke: $blueclr;
                    }                    
                }
            }

            svg {
                width: 20px;
                height: 20px;
                path{
                    stroke: $borderColor;
                }
                &:hover {
                    path {
                        fill: $blueclr;
                        stroke: $blueclr;
                    }
                }                 
            }

            &.fav-star {
                svg {
                    path {
                        fill: $blueclr1;
                    }
                }
            }
        }        
    }
}

.Ticker_data {
    display: flex;
    justify-content: space-between;
    width: 93%;
    align-items: center;
    ul {
        display: flex;
        overflow: auto;
        row-gap: 10px;
        &#subHeaderList{
            &::-webkit-scrollbar {
                height: 4px!important;
                width: 100%!important;
            }
                &::-webkit-scrollbar-track {
                    background-color: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $blueclr;
                    border-radius: 10px;
                }
        }
        li {
            margin-left: 25px;

            &:first-child {
                margin-left: 10px;
            }

            @media (max-width:1499px) {
                margin-left: 15px;
            }

            span {
                font-weight: 400;
                font-size: 10px;
                color: $greyclr;
                line-height: 12px;
                display: block;
                margin-bottom: 3px;
            }

            p {
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
                color: $white;

                @media (max-width:1439px) {
                    font-size: 10px;
                    line-height: 14px;
                }
            }
        }
    }
}