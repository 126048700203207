@import "./assets/theme/_var.scss";

body {
    margin: 0;
    font-family: $baseFont, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    color: $white;
    background: $blackclr;

    @media (max-width: 767px) {
        background: $darkblackBg;
    }
}

.text_blue {
    color: $blueclr !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.card_body_style {
    height: 100%;
    .container-fluid {
        padding: 0 10px;
    }
}

b,
strong {
    font-weight: 700;
}

img {
    max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: $baseFont, sans-serif;
}
.border-top {
    border-color: $borderColor1 !important;
}
.font38 {
    font-weight: 700;
    font-size: 38px !important;
    line-height: 130%;
    color: $white;

    @media (max-width: 1365px) {
        font-size: 32px !important;
    }

    @media (max-width: 767px) {
        font-size: 25px !important;
    }
}

.font28 {
    font-weight: 700;
    font-size: 28px !important;
    line-height: 130%;
    color: $white;

    @media (max-width: 767px) {
        font-size: 22px !important;
    }
}

.font24 {
    font-weight: 700;
    font-size: 24px !important;
    line-height: 130%;
    color: $white;

    @media (max-width: 767px) {
        font-size: 22px !important;
    }
}

.font22 {
    font-weight: 500;
    font-size: 22px !important;
    line-height: 130%;
    color: $white;

    @media (max-width: 767px) {
        font-size: 20px !important;
    }
}

.font20 {
    font-weight: 400;
    font-size: 20px !important;
    line-height: 24px;

    @media (max-width: 767px) {
        font-size: 18px !important;
    }
}

.font18 {
    font-weight: 600;
    font-size: 18px !important;
    line-height: 130%;
    color: $white;

    @media (max-width: 767px) {
        font-size: 16px !important;
    }
}

.h3_heading,
.font16 {
    font-weight: 600;
    font-size: 16px !important;
    line-height: 19px;
    color: $white;
}

.h4_heading,
.font14 {
    font-weight: 500;
    font-size: 14px !important;
    line-height: 17px;
    color: $white;
}

.h5_heading,
.font12 {
    font-weight: 400;
    font-size: 12px !important;
    line-height: 18px;
    color: $white;
}

ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

p {
    margin: 0;
}

.py-60 {
    padding-bottom: 60px;
    padding-top: 60px;

    @media (max-width: 767px) {
        padding-bottom: 40px;
        padding-top: 40px;
    }
}

.container {
    @media (max-width: 1440px) {
        max-width: 1140px;
    }

    @media (max-width: 1199px) {
        max-width: 100%;
    }
}

.Red_text1,
.text_red {
    color: $redclr !important;
}

.green_text1,
.text_green {
    color: $greenclr !important;
}

.textgreen_btn {
    background: $greenbg;
    color: $greenclr1;
    padding: 2px 4px;
    display: inline-flex;
    align-items: center;
    width: auto;
}

.textblue_btn {
    background: $bluelightbg;
    color: $blueclr1;
    padding: 2px 4px;
    display: inline-flex;
    align-items: center;
    width: auto;
}

.textyellow_btn {
    background: $yellowlightbg;
    color: $yellowclr;
    padding: 2px 4px;
    display: inline-flex;
    align-items: center;
    width: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

// perfect scrollbar
.scrollbar-container {
    padding: 0 10px !important;

    .ps__thumb-y {
        background-color: $blueclr !important;
        width: 4px !important;
    }

    .ps__rail-y {
        width: 7px !important;
        opacity: 1 !important;
    }
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background: transparent !important;
    border-radius: 8px;
    width: 6px !important;
}

.ps__thumb-x {
    background-color: $scrollColor !important;
    height: 3px !important;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: $scrollColor !important;
    width: 4px !important;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
    background-color: $scrollColor !important;
    height: 3px !important;
}

::-webkit-scrollbar {
    width: 0px !important;
    background: transparent !important;
}
body {
    overflow-x: hidden;
    // @media screen and (min-width: 999px) and (max-width: 1920px) {
    //     transform: scale(0.75);
    //     transform-origin: top left;
    //     width: 132.2vw;
    //     height: 132.2vh;
    // }

    &::-webkit-scrollbar {
        width: 9px !important;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.06);
    }

    &::-webkit-scrollbar-thumb {
        background-color: $greybg1;
        border-radius: 0px;
    }
}
.modal.comingSoon .modal-dialog .modal-content .modal-header{
    padding: 0;
}
.modal.comingSoon .modal-dialog .modal-content{
   // background: $white;
    text-align: center;
    //color: $baseColor;
    box-shadow: 0px 0px 52px 1px #000;
}
.modal.comingSoon .modal-dialog .modal-content h2{
    margin: 0;
}

.fade.modal-backdrop.show {
    background: rgba(28, 30, 40, 0.6);
    opacity: 1;
}

.fade.modal-backdrop.show {
    z-index: 9992;
}

.modal {
    z-index: 9992;

    // @media (max-width:1044px) {
    //   bottom: 0;
    //   top: auto;
    //   height: auto;

    //   &.show {
    //     bottom: 0;
    //   }
    // }

    .modal-dialog {
        display: flex;
        flex: 1;
        height: auto;
        max-width: 620px;

        @media (max-width: 1044px) {
            max-width: 100%;
            margin: 0;
        }

        .modal-body {
            padding: 20px;
        }

        .modal-content {
            background: $darkblackBg;
            border-radius: 12px;
            padding: 0;
            border: 1px solid $darkblackBg;

            .modal-header {
                border-bottom: 0;
                padding: 20px;
                font-size: 16px;
                font-weight: 700;

                @media (max-width: 767px) {
                    padding: 15px 15px;
                    font-size: 12px;
                }

                .modal-title {
                    font-size: 20px;
                    font-weight: 700;
                    color: $white;

                    @media (max-width: 767px) {
                        font-size: 12px;
                    }

                    span {
                        color: $greybg3;
                        font-weight: 500;
                    }
                }

                .btn-close {
                    background: url("./assets/images/icon_close.svg") center
                        no-repeat;
                    outline: none;
                    width: 20px;
                    height: 20px;
                    background-size: 20px;
                    position: absolute;
                    z-index: 2;
                    right: 27px;
                    top: 34px;
                    padding: 0;
                    opacity: 1;

                    @media (max-width: 767px) {
                        right: 20px;
                        top: 23px;
                        width: 18px;
                        height: 18px;
                        background-size: 18px;
                    }

                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

.dropdown {
    .dropdown-toggle {
        border: 0;

        &::after {
            margin-left: 0;
            background-image: url("./assets/images/down_arr_icon.svg");
            width: 10px;
            height: 6px;
            background-repeat: no-repeat;
            border: none;
            background-position: center;
            position: relative;
            top: 2px;
            transition: all ease-in-out 0.3s;
            display: inline-block;
        }

        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }

    .dropdown-menu {
        background-color: $darkblackBg2;
        border: 0;
        margin-top: 0px;

        a {
            color: $white;
            padding: 10px 15px;

            &:hover {
                background-color: $blueclr;
            }
        }
    }
}

.Tab_dropdown {
    .dropdown-toggle {
        color: $greyclr;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        border: 0;

        &::after {
            display: none;
        }

        svg {
            margin-left: 10px;
        }

        &.show {
            color: $white;
        }

        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }

    .dropdown-menu {
        background-color: $modalBgclr;
        border: 1px solid $borderColor;
        margin-top: 0px;

        a {
            color: $white;
            padding: 10px 15px;

            &:hover {
                background-color: $blueclr;
            }
        }
    }
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.custom_input,
.form-control,
input,
.input-type2 {
    background: $darkblackBg1;
    border: 0.5px solid $borderColor1;
    border-radius: 3px;
    padding: 8px 15px;
    height: 42px;
    color: $white;
    opacity: 1;

    &:focus {
        box-shadow: none;
        background-color: $darkblackBg1;
        outline: 0;
        border-color: $blueclr;
        color: $white;
        opacity: 1;
    }

    &::placeholder {
        color: $inputlabelclr;
        opacity: 1;
    }
}

.without_bgInput {
    background-color: transparent;
    border: 0;
    padding: 0;
    border-radius: 0;
    height: auto;

    &:focus {
        box-shadow: none;
        background-color: transparent;
        outline: 0;
        border-color: transparent;
        color: $white;
    }
}

.input-type2 {
    background-color: $darkblackBg;
    border-color: $darkblackBg;
    width: 100%;
}

.Bottom_Border_Tabs {
    border: 0;
    margin-bottom: 14px;

    .nav-item {
        .nav-link {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: $textGrey1;
            background: transparent;
            border: 0;
            border-bottom: 0;
            padding: 15px 20px;
            border-radius: 0;
            position: relative;

            @media (max-width: 1599px) {
                padding: 12px 9px;
                font-size: 12px;
                line-height: 15px;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 3px;
                background-color: transparent;
                width: 0;
                transition: all ease-in-out 0.3s;
            }

            @media (min-width: 1200px) {
                &:hover {
                    border-color: transparent;
                    color: $textGrey1;
                    background: transparent;
                }
            }

            &.active {
                border-color: $blueclr;
                color: $white;
                background: transparent;

                &::after {
                    background-color: $blueclr;
                    width: 100%;
                }
            }
        }
    }
}

.form-check {
    display: flex;
    align-items: flex-start;
    min-height: auto;
    margin-bottom: 0;

    .form-check-input {
        background-color: $checkboxbgclr;
        border: 0;
        border-radius: 3px;
        margin-top: 0;
        padding: 0;

        &:focus {
            box-shadow: none;
        }

        &:checked {
            background-color: $btnbghover;
        }
    }

    .form-check-label {
        font-weight: 400;
        font-size: 11px;
        line-height: 150%;
        margin-left: 12px;
    }

    &.blue_check_box {
        .form-check-input {
            background-color: transparent;
            border: 1px solid $borderColor4;

            &:checked {
                background-color: $blueclr1;
            }
        }
    }
    &.blueBorder{
         .form-check-input {
            border: 1px solid $blueclr1;
        }       
    }

    &.Radio_btn {
        .form-check-input {
            background-color: transparent;
            border: 1px solid $blueclr;
            border-radius: 100px;
            width: 20px;
            height: 20px;
            position: relative;
            padding: 0;

            &:checked {
                background-color: transparent;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                &::after {
                    content: "";
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    left: 0;
                    top: 0;
                    background-color: $blueclr;
                    border-radius: 100px;
                }
            }
        }

        .form-check-label {
            margin-top: 2px;
        }
    }
}

// react select

.common_select {
    .react-select__control {
        background-color: transparent;
        border-width: 0;
        font-size: 12px;

        .react-select__value-container {
            padding: 0;
        }

        &.react-select__control--is-focused,
        &.react-select__control--menu-is-open {
            box-shadow: unset !important;
            border-color: transparent;
        }

        .react-select__indicator-separator {
            display: none;
        }

        .react-select__indicator {
            padding: 0;
            background-repeat: no-repeat;
            background-position: center;
            transition: all ease-in-out 0.3s;

            svg {
                // opacity: 0;
                opacity: 0.5;
                width: 14px;
                transition: all ease-in-out 0.3s;
            }
        }

        &.react-select__control--menu-is-open {
            .react-select__indicator {
                svg {
                    transform: rotate(180deg);
                }
            }
        }

        .react-select__single-value,
        .react-select__placeholder {
            color: $white;
        }
    }

    .react-select__menu {
        min-width: 125px;
        right: 0;
        border-radius: 0;
        z-index: 3;
        background-color: $dropdwonBg;
        border: 1px solid $dropdwonBg;
        cursor: pointer;
        box-shadow: none;
        padding: 10px;

        .react-select__menu-list {
            padding: 5px 0;
            border: 0;
        }

        .react-select__option {
            color: $white;
            font-size: 12px;
            padding: 10px 14px;
            position: relative;
            cursor: pointer;
        }

        .react-select__option {
            &.react-select__option--is-focused {
                background-color: transparent;
            }

            &.react-select__option--is-selected,
            &:hover {
                background-color: $greybtnbg;
                color: $white;
            }
        }
    }

    &.arrow_options {
        .react-select__menu {
            .react-select__option {
                &.react-select__option--is-selected,
                &:hover {
                    &::after {
                        content: "";
                        display: inline-block;
                        background-image: url("./assets/images/big_arrow.svg");
                        background-size: 11px;
                        width: 11px;
                        height: 7px;
                        background-repeat: no-repeat;
                        transition: all ease-in-out 0.2s;
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}

.Common_button_tabs {
    .nav-item {
        margin-right: 18px;

        &:last-child {
            margin-right: 0;
        }

        .nav-link {
            padding: 17px 28px;
            font-weight: 500;
            font-size: 16px;
            line-height: 14px;
            background-color: $borderColor3;
            color: $white;
            border-radius: 4px;

            &.active {
                background-color: $blueclr1;
            }
        }
    }
}

.common_pagination {
    li {
        margin-right: 5px;

        .page-link {
            background-color: transparent;
            border: 0;
            color: $greyclr;
            width: 30px;
            display: inline-flex;
            height: 30px;
            padding: 0;
            justify-content: center;
            align-items: center;

            &:focus {
                box-shadow: none;
                outline: 0;
            }

            span {
                font-size: 25px;
                width: 30px;
                display: inline-flex;
                height: 30px;
                padding: 0;
                justify-content: center;
                align-items: center;
                border: 1px solid transparent;
                transition: all ease-in-out 0.3s;
                border-radius: 4px;
            }
        }

        &.active,
        &:hover {
            .page-link {
                color: $greybg;

                span {
                    border-color: $blueclr1;
                    color: $white;
                }
            }
        }

        &.ellipsis_text {
            &.active,
            &:hover {
                span {
                    border-color: transparent;
                    color: $greyclr;
                }
            }
        }
    }
}

.Window_Loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    text-align: center;

    .animLogo {
        width: 65px;
        height: 65px;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0) scale(1);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }
}

.loader--des_inner {
    position: absolute;
    z-index: 2;
    left: 40%;
    top: 0;

    .dbl-spinner {
        width: 40px;
        height: 40px;
        width: 40px;
        height: 40px;
    }
}

.Common {
    &_search {
        position: relative;

        &_Input {
            border: 1px solid $borderColor;
            border-radius: 50px;
            background-color: $modalBgclr;
            height: 48px;
            padding: 5px 20px;
            font-weight: 300;
            font-size: 12px;
            line-height: 28px;
            color: $white;
            width: 100%;

            &::placeholder {
                color: $white;
            }

            &:focus {
                outline: 0;
                box-shadow: none;
                background-color: transparent;
                box-shadow: none;
                color: $white;
            }
        }

        svg {
            position: absolute;
            top: 15px;
            right: 20px;
            cursor: pointer;
        }
    }
}

.quantity_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $darkblackBg;
    padding: 10px 35px;
    height: 60px;

    .quantity_input {
        height: auto;
        border: 0;
        background-color: transparent;
        color: $white;
        text-align: center;
        padding: 0 10px;
        width: calc(100% - 40px);

        &::placeholder {
            color: $white;
        }
    }

    span {
        width: 20px;
        height: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg {
            cursor: pointer;
            width: 16px;
            height: 16px;

            path {
                fill: #6c76aa;
            }
        }
    }
}
