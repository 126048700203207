@import "../../assets/theme/_var.scss";

.cardStyle {

  ul.card_adjustment_btn {
    text-align: right;
    margin: 0;
    position: absolute;
    right: 0;
    width: 20px;
    top: 0;

    li {
      display: inline-block;
      margin-left: 5px;

      button {
        border: 0px;
        background: transparent;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: center !important;
        padding: 0;
        display: none;

        &.zoom_view {
          .fa-compress-alt {
            cursor: zoom-out;
          }

          .fa-expand-alt {
            cursor: zoom-in;
          }
        }

        i {
          color: #7f7f7f;
          font-size: 15px;
          width: 21px;
          height: 21px;
        }
      }

      .card_close {
        position: absolute;
        right: 4px;
        top: -3px;
        display: none !important;

        img {
          width: 14px;
        }
      }
    }

  }

  // .card_hdr_title {
  //   color: $white;
  //   font-size: 13px;
  //   font-weight: 700;
  //   margin-bottom: 0;
  //   position: absolute;
  //   top: 10px;
  //   left: 10px;

  //   @media (max-width:1044px) {
  //     position: static;
  //   }
  // }
}

.react-grid-item {
  &:hover {
    ul.card_adjustment_btn {
      li {
        button {
          display: block;
        }
      }
    }
  }
}