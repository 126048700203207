@import "../../../../../../assets/theme/_var.scss";

.Transaction_History_selectBox {
    row-gap: 25px;
}
.History_Table {
    margin-top: 0;

    .table {
        margin-top: 0;

        thead {
            tr {
                th {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    min-width: 125px;
                }
            }
        }

        tbody {
            tr {
                td {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    min-width: 125px;

                    .coin_data {
                        .coin_Icon {
                            margin-right: 14px;
                        }

                        span {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: $white;
                            padding-right: 18px;
                        }

                        h6 {
                            margin-bottom: 0;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            color: $blueclr1;
                        }
                        img{
                            width: 30px;
                            margin-right: 15px;
                        }
                    }

                    &:last-child {
                        border-left: 0;
                    }

                    .Type_Data {
                        .type_Icon {
                            margin-right: 18px;
                            width: 30px;
                            height: 30px;
                            border-radius: 5px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &.redbg {
                                background-color: $coralred;
                            }

                            &.greenbg {
                                background-color: $greenclr;
                            }

                            &.bluebg {
                                background-color: $blueclr1;
                            }

                            &.greybg {
                                background-color: $greybg;
                            }

                            img {
                                filter: brightness(0) invert(1);
                                width: 16px;
                            }
                        }

                        h6 {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 22px;
                            margin-bottom: 0;
                        }

                        span {
                            font-weight: 400;
                            font-size: 10px;
                            line-height: 14px;
                            color: $greyclr;
                        }
                    }
                }
            }
        }
    }
}

.transactions_Pagination {
    margin-top: 20px;

    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 17px;
        color: $greyclr;
    }
}
