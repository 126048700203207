@import "../../../../../assets/theme/_var.scss";

.RecentDeposit {
    &_Table {
        margin-top: 70px;
        max-width: 1170px;

        &_Heading {
            h3 {
                font-weight: 600;
                font-size: 24px;
                line-height: 130%;
                color: $white;
            }
        }

        &_data {
            ul {
                li {
                    border-bottom: 1px solid $borderColor1;
                    padding: 29px 0;

                    h3 {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 22px;
                        margin-bottom: 0;
                        padding-right: 18px;

                        img {
                            width: 30px;
                            height: 30px;
                            object-fit: cover;
                            margin-right: 18px;
                        }
                    }

                    .RecentDeposit_Network {
                        p {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            margin-right: 30px;

                            &:last-child {
                                margin-right: 0;
                            }

                            strong {
                                padding-left: 10px;
                            }
                        }
                    }

                    .Table_Arrow {
                        padding-left: 15px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}