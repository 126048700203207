@import "../../../../assets/theme/_var.scss";

.AllMarket {
    z-index: 1;
    display: flex;
    align-items: center;
    cursor: pointer;

    .card_body_style {
        width: 100%;
    }

    .AllMarket_V2 {
        .AllMarket_TradingPairs {
            background-color: transparent;
            padding: 6px;
            border: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: auto;
            cursor: pointer;

            &::after {
                display: none;
            }

            .Token_Name_Icon {
                display: flex;
                align-items: center;

                .Token_name {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 28px;
                    color: $white;
                    margin-left: 16px;
                }
            }

            .All_Market_title {
                font-weight: 400;
                font-size: 14px;
                line-height: 28px;
                color: $greyclr;
                cursor: pointer;

                svg {
                    margin-left: 10px;
                }
            }
        }

        &.openMarket {
            .TradingPairs_dropdown {
                opacity: 1;
                visibility: visible;
                margin-top: 5px;
            }
        }
    }
}
.AllMarket_V2 {
    float: left;
    background: $borderColor3;
    padding: 10px;
    border-radius: 12px;
    margin-right: 20px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1380px) {
        margin-right: 15px;
    }
    .AllMarket_TradingPairs {
        background-color: transparent;
        padding: 6px;
        border: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: auto;
        align-items: center;
        cursor: pointer;
        @media screen and (max-width: 1380px) {
            padding-left: 0;
            padding-right: 0;
        }
        &::after {
            display: none;
        }
        .Token_Name_Icon {
            display: flex;
            align-items: center;
            img{
                width: 20px;
                height: 20px;
            }

            .Token_name {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $white;
                margin-left: 16px;
                @media screen and (max-width: 1380px) {
                    font-size: 12px;
                    margin-left: 10px;
                }
                svg{
                    margin-left: 16px;
                    position: relative;
                    top: -2px;
                     @media screen and (max-width: 1380px) {
                        margin-left: 10px;
                     }
                }
            }
        }

        .All_Market_title {
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            color: $greyclr;
            cursor: pointer;

            svg {
                margin-left: 10px;
            }
        }
    }

    &.openMarket {
        .TradingPairs_dropdown {
            opacity: 1;
            visibility: visible;
            margin-top: 5px;
        }
    }
}
.TradingPairs {
    &_Modal.modal {
        .modal-dialog {
            max-width: 758px;

            .modal-content {
                .modal-header {
                    .modal-title {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 17px;
                    }

                    .btn-close {
                        background-image: url("../../../../assets/images/blueCross.svg");
                    }
                }

                .modal-body {
                    padding-top: 5px;

                    @media (max-width: 767px) {
                        padding: 20px 15px;
                        padding-top: 5px;
                    }
                }
            }
        }
    }

    &_dropdown {
        z-index: 99;
        box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.28);
        border-radius: 5px;
        transition: all ease-in-out 0.3s;

        h2 {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
        }

        .Cross_btn {
            cursor: pointer;
        }
    }

    &_Tabs {
        .nav {
            border: 0;
            align-items: center;

            .nav-item {
                .nav-link {
                    background-color: transparent;
                    border-bottom: 1px solid transparent;
                    font-weight: 400;
                    font-size: 14px;
                    color: $greyclr;
                    border-radius: 0px;
                    height: auto;
                    padding: 8px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        path {
                            fill: $greyclr;
                        }
                    }

                    &.active {
                        color: $white;
                        border-color: $blueclr;
                    }

                    .new_btn {
                        border-radius: 4px;
                        background: linear-gradient(
                            90deg,
                            rgba(103, 182, 255, 0.28) 0%,
                            rgba(81, 143, 237, 0.28) 23.71%,
                            rgba(21, 110, 233, 0.28) 50%,
                            rgba(23, 69, 245, 0.28) 78.13%,
                            rgba(13, 40, 147, 0.28) 100%
                        );
                        border: 1px solid #67b6ff;
                        display: flex;
                        height: 20px;
                        padding: 0px 10px;
                        justify-content: center;
                        align-items: center;
                        font-size: 9px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 22px;
                        color: $white;
                        margin-left: 8px;
                    }
                }

                &:first-child {
                    .nav-link {
                        &.active {
                            background-color: transparent;
                            border-color: transparent;

                            svg {
                                path {
                                    fill: $blueclr;
                                }
                            }
                        }
                    }
                }
            }
        }

        .Table_Pairs {
            &_Head {
                ul {
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                    cursor: pointer;

                    li {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 12px;
                        color: $greyclr;
                        padding: 0 2px;

                        &.Table_th {
                            width: calc(100% - 60px);

                            @media (max-width: 767px) {
                                width: calc(100% - 30px);
                            }
                        }

                        &.Table_star {
                            width: 60px;
                            text-align: right;

                            @media (max-width: 767px) {
                                width: 30px;
                            }
                        }

                        ul {
                            width: 100%;

                            li {
                                &:first-child {
                                    justify-content: flex-start;
                                    width: 34%;
                                    text-align: left;
                                }

                                width: 22%;
                                text-align: right;
                            }
                        }

                        .Up_down_arrow {
                            svg {
                                display: block;
                                margin: 4px 0;
                                margin-left: 5px;
                                width: 7px;
                                height: 4px;
                                cursor: pointer;

                                &:first-child {
                                    transform: rotate(-180deg);
                                    margin-left: 4px;
                                }
                            }
                        }
                    }
                }
            }

            &_Data {
                margin: 0 -9px;
                max-height: 380px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 10px !important;
                }

                &::-webkit-scrollbar-track {
                    background-color: transparent;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $blueclr;
                    border-radius: 10px;
                }

                .Star_Data {
                    margin-right: 7px;
                    display: block;
                    line-height: 0;
                    cursor: pointer;

                    svg {
                        path {
                            fill: $greybg1;
                        }
                    }

                    &.fav-star {
                        svg {
                            path {
                                fill: $blueclr1;
                            }
                        }
                    }
                }

                ul {
                    display: flex;
                    align-items: center;
                    padding: 13px 9px;

                    li {
                        &.table_td {
                            width: calc(100% - 60px);
                            padding: 0 2px;

                            @media (max-width: 767px) {
                                width: calc(100% - 30px);
                            }
                        }

                        &.table_star {
                            width: 60px;
                            text-align: right;

                            @media (max-width: 767px) {
                                width: 30px;
                            }
                        }

                        ul {
                            padding: 0;
                            width: 100%;

                            li {
                                width: 22%;
                                text-align: right;

                                &:first-child {
                                    width: 34%;
                                    text-align: left;
                                }
                            }
                        }

                        .Pairs_Token {
                            .Coin_Icon {
                                width: 20px;
                                height: 20px;
                                border-radius: 50px;
                                object-fit: cover;

                                @media (max-width: 767px) {
                                    width: 15px;
                                    height: 15px;
                                }
                            }

                            h5 {
                                font-weight: 600;
                                font-size: 10px;
                                line-height: 12px;
                                color: $white;
                                margin-bottom: 0;
                                margin-left: 8px;

                                @media (max-width: 767px) {
                                    margin-left: 4px;
                                }

                                small {
                                    font-weight: 400;
                                    font-size: 8px;
                                    line-height: 9px;
                                    color: $greyclr;
                                    display: block;
                                    margin-top: 2px;
                                }
                            }
                        }

                        p {
                            font-weight: 600;
                            font-size: 10px;
                            line-height: 12px;
                            color: $white;
                        }
                    }

                    &:hover {
                        background-color: $greybg;
                    }
                }
            }
        }

        .Currency {
            display: flex;
            align-items: flex-start;
            margin: 20px 0;

            &_SelectBox {
                width: 100px;

                .dropdown {
                    width: 100%;

                    &-toggle {
                        font-size: 12px;
                        font-weight: 600;
                        color: $greyclr;
                        background: $darkblackBg;
                        height: 30px;
                        border-radius: 4px;
                        padding: 5px 10px;
                        width: 100%;

                        &::after {
                            margin-left: 10px;
                        }
                    }

                    &-menu {
                        min-width: 100%;
                        padding: 0;
                        max-height: 400px;
                        overflow-y: auto;
                        z-index: 3;
                        background-color: $baseColor;
                        border: 1px solid $borderColor;

                        a {
                            font-size: 12px;
                            font-weight: 600;
                            color: $greyclr;
                            display: block;

                            &:hover {
                                background-color: $borderColor;
                                color: $white;
                            }
                        }
                    }
                }
            }

            &_TabBtns {
                width: calc(100% - 100px);
                display: flex;
                flex-wrap: nowrap;
                white-space: nowrap;
                overflow-x: auto;
                margin-left: 15px;

                &::-webkit-scrollbar {
                    width: 0px !important;
                    height: 5px !important;
                }

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.06);
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $greybg;
                    border-radius: 10px;
                }

                &_Btns {
                    border-radius: 4px;
                    background: $darkblackBg;
                    border: 1px solid $darkblackBg;
                    display: flex;
                    height: 30px;
                    padding: 5px 16px;
                    justify-content: center;
                    align-items: center;
                    color: $greyclr;
                    font-size: 12px;
                    font-weight: 600;

                    &:hover {
                        border-radius: 4px;
                        background: linear-gradient(
                            90deg,
                            rgba(103, 182, 255, 0.28) 0%,
                            rgba(81, 143, 237, 0.28) 23.71%,
                            rgba(21, 110, 233, 0.28) 50%,
                            rgba(23, 69, 245, 0.28) 78.13%,
                            rgba(13, 40, 147, 0.28) 100%
                        );
                        color: $white;
                        border: 1px solid #67b6ff;
                    }
                }
            }
        }

        .tab-content {
            margin-top: 25px;

            &.list_pairs_content {
                min-height: 380px;
            }
        }

        .Bottom_Border_Tabs {
            &.nav {
                .nav-item {
                    .nav-link {
                        padding: 10px 10px;

                        @media (max-width: 767px) {
                            padding: 10px 7px;
                            font-size: 12px;
                        }

                        &::after {
                            height: 1px;
                        }

                        &.active {
                            &::after {
                                height: 1px;
                            }
                        }
                    }
                }
            }
        }

        .Top_Traded {
            display: flex;
            flex-wrap: nowrap;
            white-space: nowrap;
            overflow-x: auto;

            &::-webkit-scrollbar {
                width: 0px !important;
                height: 5px !important;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.06);
            }

            &::-webkit-scrollbar-thumb {
                background-color: $greybg;
                border-radius: 10px;
            }

            &_Box {
                display: flex;
                align-items: center;
                padding-right: 20px;
                padding-bottom: 5px;
                cursor: pointer;

                &:last-child {
                    padding-right: 0;
                }

                figure {
                    width: 30px;
                    margin-bottom: 0;
                }

                h6 {
                    margin-bottom: 0;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: normal;

                    span {
                        font-weight: 500;
                        color: $textGrey6;
                    }
                }

                p {
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }
    }

    &_search {
        position: relative;

        .Custom_Search {
            border: 1px solid $blueclr1;
            border-radius: 4px;
            background-color: $darkblackBg;
            height: 40px;
            padding: 5px 12px;
            font-weight: 300;
            font-size: 12px;
            line-height: 28px;
            color: $white;
            width: 100%;
            padding-left: 35px;

            &::placeholder {
                color: $white;
            }

            &:focus {
                outline: 1px solid $blueclr;
                box-shadow: none;
            }
        }

        svg {
            position: absolute;
            top: 12px;
            left: 12px;
        }
    }
}

.Table_Pairs_Data {
    margin-left: 0 !important;
    margin-right: 0 !important;
    div {
        cursor: pointer;
    }
    .infinite-scroll-component {
        &::-webkit-scrollbar {
            width: 12px !important;
            height: 12px !important;
        }

        &::-webkit-scrollbar-track {
            background: rgba(255, 255, 255, 0.06);
            border-radius: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $blueclr;
            border-radius: 10px;
            // border: 1px solid rgba(255, 255, 255, 0.06);
            user-select: none;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: $blueclr;
        }
    }
}
