@import "../../assets/theme/_var.scss";
@import "../../assets/theme/_mixins.scss";

.container-fluid.mobile_nav {
  position: fixed;
  bottom: 0px;
  left: 0px;
  bottom: 0px;
  background: $baseColor;
  padding: 0px;
  border-top: solid 1px #2f333e;
  z-index: 9;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    list-style: none;

    li {
      flex: 1;
      &:not(:last-child) {
        border-right: 1px solid #393e4d;
      }
      a {
        font-family: $baseFont;
        font-size: 15px;
        font-weight: 500;
        text-decoration: none;
        color: $white; 
        background: $baseColor;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 45px;
        text-transform: capitalize;
        cursor: pointer;
        @include breakpoint(x-small) {
          font-size: 12px;
          word-break: break-all;
          padding: 0 3px;
        }
        &.active {
          background: $blueclr;
        }
      }
    }
  }
}
