@import "../../../../assets/theme/_var.scss";
@import "../../../../assets/theme/_mixins.scss";

.OrderBook {
    &.cardStyle {
        padding: 7px 0;

        .orderBook_index_V2 {
            .Bottom_Border_Tabs {
                border-bottom: 3px solid $borderColor2;
                margin-bottom: 14px;
                display: flex;

                .nav-item {
                    width: 33.33%;

                    .nav-link {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 17px;
                        color: $textGrey1;
                        background-color: transparent;
                        border: 0;
                        padding: 15px 0px;
                        width: 100%;
                        text-align: center;

                        @media (max-width: 1599px) {
                            font-size: 12px;
                            line-height: 15px;
                            padding: 10px 5px;
                        }
                    }
                }
            }

            .tab-content {
                .orderbookHeader {
                    .nav-tabs {
                        border: 0;
                        padding: 0 10px;
                        margin-bottom: 18px;

                        li {
                            .nav-link {
                                background-color: transparent;
                                border: 0;
                                border-radius: 4px;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 22px;
                                color: $greyclr;
                                padding: 5px 20px;
                                height: 30px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                @media (max-width: 1199px) {
                                    padding: 5px 15px;
                                }

                                &.active {
                                    background-color: $greybg2;
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }

        .orderBookData_V2 {
            .orderBook_Heading {
                display: flex;
                padding: 0 22px;
                margin-bottom: 10px;

                @media (max-width: 1599px) {
                    padding: 0 3px;
                }

                li {
                    width: 33.33%;
                    padding: 0 5px;

                    &:last-child {
                        text-align: right;
                    }

                    span {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: $greyclr;
                        position: relative;

                        @media (max-width: 1365px) {
                            font-size: 10px;
                            line-height: 16px;
                        }

                        &::after {
                            content: "";
                            border-bottom: 6px solid $greybg3;
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;
                            display: inline-block;
                            margin-left: 4px;
                            position: relative;
                            top: -1px;
                        }
                    }
                }
            }

            .orderBook_data {
                max-height: 1020px;
                overflow-y: auto;
                // &::-webkit-scrollbar {
                //     width: 5px !important;
                // }

                // &::-webkit-scrollbar-track {
                //     background-color: transparent;
                // }

                // &::-webkit-scrollbar-thumb {
                //     background-color: $blueclr;
                //     border-radius: 10px;
                // }

                ul {
                    display: flex;
                    align-items: center;
                    padding: 0 20px;
                    margin-bottom: 4px;
                    position: relative;
                    min-height: 29px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    @media (max-width: 1599px) {
                        padding: 0 3px;
                    }

                    &.Buyprice_data {
                        border-left: 2px solid $greenclr1;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    &.Sellprice_data {
                        border-left: 2px solid $redclr;
                    }

                    li {
                        width: 33.33%;
                        padding: 4px 5px;

                        &:last-child {
                            text-align: right;
                        }

                        span {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            color: $white;

                            @media (max-width: 1365px) {
                                font-size: 12px;
                                line-height: 15px;
                            }

                            @media (max-width: 1199px) {
                                font-size: 11px;
                                line-height: 14px;
                            }
                        }
                    }
                }
            }

            .Order_Spread {
                border-top: 1px solid $borderColor3;
                border-bottom: 1px solid $borderColor3;
                padding: 8px 20px;
                margin: 8px 0;

                @media (max-width: 1199px) {
                    margin: 5px 0;
                }

                li {
                    width: 33.33%;
                    text-align: center;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                    color: $greybg1;
                }
            }

            .Order_Minus_Plus {
                border-top: 1px solid $borderColor3;
                padding: 14px 20px;
                margin-top: 12px;

                // span {
                //     img {}
                // }
            }
        }
    }
}
.orderBookData_V2{
    @media screen and (min-width: 990px) {
        height: 100%;
    }
}
.depth-container {
    height: 36px;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    @media screen and (min-width: 990px) {
        position: absolute;
        width: 100%;
        bottom: -4px;       
    }
}

.buy-bar {
    position: relative;
    display: inline-block;
    padding: 8px 0px 0px 8px;
    overflow: hidden;
    color: #20b26c;
    height: 29px;
    margin-top: 3px;
    font-size: 11px;
    z-index: 1;
}

.buy-bar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #172b22;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: skew(-10deg);
    -ms-transform: skew(-10deg);
    transform: skew(-10deg);
    z-index: -1;
}

.sell-bar {
    position: relative;
    display: inline-block;
    padding: 8px 11px 0px 0px;
    overflow: hidden;
    color: #ef454a;
    float: right;
    height: 29px;
    margin-top: 3px;
    text-align: right;
    font-size: 11px;
    z-index: 1;
}

.sell-bar:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #34191e;
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    transform-origin: 0 100%;
    -webkit-transform: skew(-10deg);
    -ms-transform: skew(-10deg);
    transform: skew(-10deg);
    z-index: -1;
}

.buy-bar span,
.sell-bar span {
    width: 40px;
}