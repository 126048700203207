@import "../../../../../assets/theme/_var.scss";

.History_Table {
    margin-top: 0;

    .coinDetailTable {
        margin-top: 0;
        border: 0;
            width: 100%;
            border-spacing: 0 15px;
            border-collapse: separate;
        thead {
            tr {
                th {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    min-width: 125px;
                    border: 0;
                    &:last-child{
                        border: none;
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: 0;
                td {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    min-width: 125px;
                    border-block: 1px solid rgba(255, 255, 255, 0.1);
                    .coin_data {
                        .coin_Icon {
                            margin-right: 14px;
                        }

                        span.coinName {
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 24px;
                            color: $white;
                            padding-right: 18px;
                            min-width: 180px;
                        }
                        .coinIcon{
                            min-width: 70px;
                        }

                        h6 {
                            margin-bottom: 0;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            color: $blueclr1;
                        }
                        span.coinType{
                            color: $greybg3;
                            font-size: 12px;
                        }
                    }

                    &:last-child {
                        border-right: 1px solid rgba(255, 255, 255, 0.1);
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                        padding-right: 15px;
                        width: 115px;
                    }
                    &:first-child{
                        border-left: 1px solid rgba(255, 255, 255, 0.1);
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }
                       .priceWhite{
                            color: $white;
                        }
                        .priceRed{
                            color: $redclr;
                        }
                        .priceGreen{
                            color: $greenclr;
                        }
                }
            }
        }
    }
}

.transactions_Pagination {
    margin-top: 20px;

    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 17px;
        color: $greyclr;
    }
}